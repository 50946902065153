import React, { useState } from "react";
import { Container, Row, Col, Carousel, Button, Modal, Form} from 'react-bootstrap';
import { Helmet } from "react-helmet";
import "./bunchfulBall.css";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import SponsorshipDeck from "../../Components/SponsorshipDeck/sponsorship";
import Banner from "../../Components/Banner/banner";
import bannerImage from "../../images/homepage/events-page/bunchful-ball/bottle-bg.webp";
import slide1 from "../../images/homepage/events-page/bunchful-ball/slide-1.webp";
import slide2 from "../../images/homepage/events-page/bunchful-ball/slide-2.webp";
import slide3 from "../../images/homepage/events-page/bunchful-ball/slide-3.webp";
import slide4 from "../../images/homepage/events-page/bunchful-ball/slide-4.webp";
import slide5 from "../../images/homepage/events-page/bunchful-ball/slide-5.webp";
import slide6 from "../../images/homepage/events-page/bunchful-ball/slide-6.webp";
import slide7 from "../../images/homepage/events-page/bunchful-ball/slide-7.webp";
import slide8 from "../../images/homepage/events-page/bunchful-ball/slide-8.webp";
import slide9 from "../../images/homepage/events-page/bunchful-ball/slide-9.webp";
import slide10 from "../../images/homepage/events-page/bunchful-ball/slide-10.webp";
import slide11 from "../../images/homepage/events-page/bunchful-ball/slide-11.webp";
import slide12 from "../../images/homepage/events-page/bunchful-ball/slide-12.webp";
import slide13 from "../../images/homepage/events-page/bunchful-ball/slide-13.webp";
import slide14 from "../../images/homepage/events-page/bunchful-ball/slide-14.webp";
import slide15 from "../../images/homepage/events-page/bunchful-ball/slide-15.webp";
import slide16 from "../../images/homepage/events-page/bunchful-ball/slide-16.webp";
import slide17 from "../../images/homepage/events-page/bunchful-ball/slide-17.webp";
import slide18 from "../../images/homepage/events-page/bunchful-ball/slide-18.webp";
import exampleImage from "../../images/homepage/events-page/bunchful-ball/page-end.webp";
import bunchfulBallTopImage from "../../images/homepage/events-page/bunchful-ball/image-1.webp";
import firstEvent from "../../images/homepage/events-page/bunchful-ball/life-on-earth.webp";
import secondEvent from "../../images/homepage/events-page/bunchful-ball/lifes-a-gift.webp";
import thirdEvent from "../../images/homepage/events-page/bunchful-ball/the-celebrate-you.webp";
import fourthEvent from "../../images/homepage/events-page/bunchful-ball/the-reconnect-ball.webp";
import { db } from '../../config/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods

const BunchfulBall = () => {

    const [showForm, setShowForm] = useState(false);
    const [firstName, setFirstName] = useState(''); // State for first name
    const [lastName, setLastName] = useState(''); // State for last name
    const [email, setEmail] = useState(''); // State for email

    const handleShowForm = () => setShowForm(true);
    const handleCloseForm = () => setShowForm(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await addDoc(collection(db, 'eventsTicketsWaitlist'), {
            firstName,
            lastName,
            email,
          });
          alert('Application submitted successfully!');
          setFirstName(''); // Clear form fields
          setLastName('');
          setEmail('');
          handleCloseForm(); // Close the modal after submission
        } catch (error) {
          console.error('Error submitting application:', error);
        }
    };

    return (
        <div className="bunchful-ball">

            <Helmet>
                <title>Bunchful Ball - Bunchful | The Generosity Business | Official Site</title>
            </Helmet>

            <div>
                <Header/>
            </div>

            <div>
                <Banner
                    homeLink="Home"
                    breadcrumb="Bunchful Ball"
                    title="Bunchful Ball"
                    backgroundImage={bannerImage}
                    textColor="white"
                />
            </div>

            <div className="section-1">
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <img src={bunchfulBallTopImage} className="img-fluid" />
                        </Col>

                        <Col xs={12} md={6} >
                            <div className="content-wrapper">
                                <h2 className="heading">The Bunchful Ball</h2>
                                <h3 className="location">Coming to Abu Dhabi, 2026</h3>
                                <p className="text">
                                    The Bunchful Ball is a vibrant, life-affirming celebration, providing a unique opportunity
                                     to rejoice in our individual achievements, express our unique qualities
                                      and collectively acknowledge our shared humanity.
                                    <br /> <br />
                                    This will be a unique opportunity for the giving community to mingle and celebrate collective efforts 
                                    towards a better world for one special evening. Further details to be confirmed in due course.
                                </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-2">
                <h1 className="heading text-center text-white">The Bunchful Ball</h1>

                <Col className="sectionss">
                    <Row className="mb-4 align-items-center"> {/* Added align-items-center */}
                        <Col xs={12} md={6}>
                            <img src={firstEvent} className="img-fluid" />
                        </Col>

                        <Col xs={12} md={6} className="justify-content-center"> {/* Added justify-content-center */}
                            <div>
                                <h1 className="heading text-white">
                                    The Life On Earth Ball
                                </h1>
                                <h3 className="location-time text-white">
                                    Abu Dhabi, January 2026
                                </h3>
                                <Button variant="light" className="get-tickets mt-3" onClick={handleShowForm}>
                                    Get Tickets
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={6}>
                            <img src={thirdEvent} className="img-fluid" />
                        </Col>

                        <Col xs={12} md={6} className="justify-content-center">
                            <div>
                                <h1 className="heading text-white">
                                    The Celebrate You Ball
                                </h1>
                                <h3 className="location-time text-white">
                                    Abu Dhabi, January 2026
                                </h3>
                                <Button variant="light" className="get-tickets mt-3" onClick={handleShowForm}>
                                    Get Tickets
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={6}>
                            <img src={fourthEvent} className="img-fluid" />
                        </Col>

                        <Col xs={12} md={6} className="justify-content-center">
                            <div>
                                <h1 className="heading text-white">
                                    The Reconnect Ball
                                </h1>
                                <h3 className="location-time text-white">
                                    Abu Dhabi, January 2026
                                </h3>
                                <Button variant="light" className="get-tickets mt-3" onClick={handleShowForm}>
                                    Get Tickets
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={6}>
                            <img src={secondEvent} className="img-fluid" />
                        </Col>

                        <Col xs={12} md={6} className="justify-content-center">
                            <div>
                                <h1 className="heading text-white">
                                    The Life's A Gift Ball
                                </h1>
                                <h3 className="location-time text-white">
                                    Abu Dhabi, January 2026
                                </h3>
                                <Button variant="light" className="get-tickets mt-3" onClick={handleShowForm}>
                                    Get Tickets
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>



            <div className="section-3">
                <Carousel style={{boxShadow:"none", borderRadius: "0px"}}>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide1}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide2}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide3}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide4}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide5}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide6}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide7}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide8}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide9}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide10}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide11}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide12}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide13}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide14}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide15}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide16}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide17}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide18}
                        />
                    </Carousel.Item>
                </Carousel>

            </div>


            <div>
                <SponsorshipDeck/>
            </div>

            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                            The Bunchful Ball. <br/>
                            You celebrate you.
                        </p>
                        <div className='button-container'>
                        <Button className='apply-button' onClick={handleShowForm} >
                            Get Tickets
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>


            
            <div>
                <Footer/>
            </div>

            <Modal
                show={showForm}
                onHide={handleCloseForm}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)'}}  // for the backdrop transparency
            >
                <Modal.Header closeButton style={{ backgroundColor: '#130038', border: 'none', borderRadius: "0px", justifyContent: 'center'}} closeVariant="white">
                    <Modal.Title style={{color: "white", textAlign:"center"}}>Join the Waitlist</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#130038', border: 'none'}}>
                    <Form style={{ backgroundColor: '#130038', border: 'none', boxShadow: "none"}} onSubmit={handleSubmit}>
                        <Row>
                        <Col md={6}>
                            <Form.Group controlId="firstName">
                            <Form.Label style={{ color: "white"}}>First Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter your first name" 
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="lastName">
                            <Form.Label style={{ color: "white"}}>Last Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter your last name" 
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        </Row>

                        <Form.Group controlId="email" className="mt-3">
                        <Form.Label style={{ color: "white"}}>Email</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="Enter your email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} // Set email
                        />
                        </Form.Group>

                        <Button style={{backgroundColor: "#6147f2", border: "0"}} variant="primary" type="submit" className="mt-3">
                        Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>

    );
}
export default BunchfulBall;