import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './banner.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Banner = ({ title, homeLink, breadcrumb, longText, date, backgroundImage, textColor }) => {
  // Initial state for low-resolution banner image
  const lowResBannerImage = 'path/to/low-res-image.webp'; // Replace with actual low-res image path
  const [bannerSrc, setBannerSrc] = React.useState(lowResBannerImage);

  // Load high-res image after initial render
  React.useEffect(() => {
    const img = new Image();
    img.src = backgroundImage;
    img.onload = () => setBannerSrc(backgroundImage);
  }, [backgroundImage]);

  // Inline style for the banner background using bannerSrc
  const bannerStyle = {
    backgroundImage: `url(${bannerSrc})`,
  };

  // Inline style for text color
  const textStyle = {
    color: textColor || 'black',
  };

  const dateStyle = {
    color: textColor || 'black',
    fontSize: "1rem",
    fontStyle: "italic"
  };

  return (
    <div className="banner-section" style={bannerStyle}>
      <Container fluid>
        <Row className="justify-content-center align-items-center text-center banner-content">
          <Col>
            <p className="banner-link" style={textStyle}>
              <a href="/" className="banner-link-text" style={textStyle}>
                {homeLink}
              </a>
              {' » '}
              <span style={textStyle}>{breadcrumb}</span>
            </p>

            <h1 className="banner-title" style={textStyle}>{title}</h1>

            <p className="banner-long-text" style={textStyle}>{longText}</p>

            <p className="date" style={dateStyle}>{date}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
