import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import QuoteCarousel from "../../Components/QuoteCarousel/QuoteCarousel";
import SponsorshipDeck from "../../Components/SponsorshipDeck/sponsorship";
import exampleImage from "../../images/homepage/events-page/awards/awardees/page-end.webp";
import "./awardees.css"
import first_img from "../../images/homepage/events-page/awards/awardees/lady-anita.webp";
import second_img from "../../images/homepage/events-page/awards/awardees/liza-andrews.webp";
import third_img from "../../images/homepage/events-page/awards/awardees/life-worc.webp";
import fourth_img from "../../images/homepage/events-page/awards/awardees/gianna-palleschi.webp";
import fifth_img from "../../images/homepage/events-page/awards/awardees/jessica-wong.webp";
import sixth_img from "../../images/homepage/events-page/awards/awardees/bread-of-life.webp";
import { db } from '../../config/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods

const Awardees = () => {
    
    const [showDescriptionModal, setShowDescriptionModal] = useState(false); // Modal visibility state for detailed descriptions
    const [selectedDescription, setSelectedDescription] = useState(''); // Selected description state

    const [showApplicationModal, setShowApplicationModal] = useState(false); // Modal visibility state for the application
    const [firstName, setFirstName] = useState(''); // State for first name
    const [lastName, setLastName] = useState(''); // State for last name
    const [email, setEmail] = useState(''); // State for email

    // Handle showing the description modal
    const handleShowDescriptionModal = (description) => {
        setSelectedDescription(description);
        setShowDescriptionModal(true);
    };

    const handleCloseDescriptionModal = () => {
        setShowDescriptionModal(false);
    };

    // Handle showing and closing the application modal
    const handleShowApplicationModal = () => setShowApplicationModal(true);
    const handleCloseApplicationModal = () => setShowApplicationModal(false);

    // Handle the form submission for applications
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'bunchfulAwardsApplications'), {
                firstName,
                lastName,
                email,
            });
            alert('Application submitted successfully!');
            setFirstName(''); // Clear form fields
            setLastName('');
            setEmail('');
            handleCloseApplicationModal(); // Close the modal after submission
        } catch (error) {
            console.error('Error submitting application:', error);
        }
    };

    const cardData = [
        {
            imgSrc: first_img,
            detailedDescription: "Lady Anita Duckworth Bradshaw is a London resident and one of the most inspiring speakers the world has come to know. She has more than 14 years of experience supporting global leaders and has developed a system to empower them in various sectors. She and her team provide platforms such as Powerhouse Global Magazine, global conferences, retreats, and leadership awards events. She is passionate about increasing the global visibility of leaders and inspiring them on their journey to do great things.She is inspired by visionaries like Dr. Wayne Dyer, Jim Rohn, and Napoleon Hill, as well as timeless teachings such as the Bible. She has written over seven books, 300+ personal quotes, and is committed to making a positive difference. She is also working towards the achievement of the United Nations Sustainable Development Goals (SDGs)."
        },
        {
            imgSrc: second_img,
            detailedDescription: "Liza Andrews is a reinvention specialist, and the creator of the PictureCure Methods. For twenty five years, she recruited and trained executives; and for almost fifteen, she has been doing volunteer work with breast cancer survivors. Her audiovisual method is an alternative therapy to help women recover/improve self-esteem and confidence after a mastectomy. Andrews writes for the Brazilian Times, and hosts a TV show at the Manhattan Neighborhood Network about personal reinvention."
        },
        {
            imgSrc: third_img,
            detailedDescription: "Life's WORC, a prominent organization with over 50 years of service, was recently awarded the 2023 Bunchful Wisdom Award in recognition of its exceptional commitment to supporting individuals with intellectual disabilities and autism. Serving more than 2,000 people through a comprehensive array of residential, day, and community programs, Life’s WORC also provides at-home support, skills development, behavioral interventions, and vocational training designed to foster independence and improve quality of life. Their Trust and Financial Support Services further enhance financial security for their clients through specialized trusts that protect government entitlement benefits. The Bunchful award acknowledges Life’s WORC’s significant contributions to community service and their impact and efforts to achieve the Sustainable Development Goals (SDGs), particularly in promoting good health and well-being and reducing inequalities. Upholding core values of responsibility, high-quality support, teamwork, and effective communication, Life’s WORC continues to be a leader in disability support services, embodying a holistic approach that impacts lives positively and broadly. Ayo Adewunmi, a dedicated Residential Director with 10 years of experience in the field, and LaShaun Brown were present to accept the 2023 Bunchful Wisdom Award for Life’s WORC, recognizing the organization's extensive support for individuals with intellectual disabilities and autism and their impactful efforts towards the Sustainable Development Goals."
        },
        {
            imgSrc: fourth_img,
            detailedDescription: "Gianna Palleschi, at fifteen, initiated Pink Pom Pom, inspired by her mother's tireless dedication as a breast surgeon. Witnessing the challenges patients face during treatments ignited her desire to support them. Her organization crafts Pink Pom Pom boxes, filled with uplifting items, aiding breast cancer patients. This venture, launched during Breast Cancer Awareness Month, aims to spread love, hope, and care, reaching beyond her local community. Gianna’s initiative earned praise from her mother, Dr. Susan Palleschi, both amazed by her daughter's dedication and innovative approach."
        },
        {
            imgSrc: fifth_img,
            detailedDescription: "Jessica Sophia Wong, the dynamic founder of Yorkseed and a passionate philanthropist, was recently celebrated with the 2023 Bunchful Award for Innovation, which recognizes individuals making substantial contributions towards achieving the Sustainable Development Goals (SDGs). Born and raised in Malaysia, Jessica’s journey in entrepreneurship and technology began early and flourished after her move to New York. Here, she initiated an Earth Day fundraiser in 2016 that has since evolved into a major annual event, including art auctions and galas raising substantial funds for environmental causes, aligning closely with SDG 13 (Climate Action). As a mental health advocate and avid traveler, Jessica also leverages her projects to promote SDG 3 (Good Health and Well-being) through her mental health support platforms and SDG 11 (Sustainable Cities and Communities) through her blockchain-based rental platform to address New York's housing affordability. Her initiatives extend to sustainable living and tech innovation, actively fostering a global community aimed at holistic social and economic betterment, illustrating the powerful impact of combining innovation with a commitment to the global goals."
        },
    ];


    return (
        <div className='awardees'>

            <Helmet>
                <title>Awardees - Bunchful | The Generosity Business | Official Site</title>
            </Helmet>

            <Header />

            <div className='section-1' style={{ backgroundColor: "#332b77" }}>
                <Container style={{ paddingBottom: "5%", paddingTop: "5%", paddingLeft: "5%", paddingRight: "5%" }}>
                    <Row className="justify-content-center">
                        <Col md={7} className="mb-4 d-flex justify-content-center">
                            <Card style={{ backgroundColor: "#f3506f", borderRadius: "0px", border: "0", marginBottom: "5%" }}>
                                <Card.Body className="text-center" style={{ color: "white", fontSize: "1.2rem" }}>
                                    We have revised and standardized our awards into seven main categories, each corresponding to one of the seven elements of our heart logo...
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <h2 className='text-center' style={{ fontSize: "2rem", color: "white" }}>
                        2023 Bunchful Awardees
                    </h2>

                    {/* First Row: 1 Card */}
                    <Row className="justify-content-center">
                        <Col md={6} className="mb-4 d-flex justify-content-center">
                            <Card style={{ border: "0", backgroundColor: "#332b77" }}>
                                <Card.Img variant="top" src={cardData[0].imgSrc} />
                                <Card.Body className="text-center">
                                    <Button className='custom-btn'
                                        variant="primary"
                                        onClick={() => handleShowDescriptionModal(cardData[0].detailedDescription)}
                                    >
                                        More Info
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Second Row: 2 Cards */}
                    <Row className="justify-content-center">
                        <Col md={6} className="mb-4 d-flex justify-content-center">
                            <Card style={{ border: "0", backgroundColor: "#332b77" }}>
                                <Card.Img variant="top" src={cardData[1].imgSrc} />
                                <Card.Body className="text-center">
                                    <Button className='custom-btn'
                                        variant="primary"
                                        onClick={() => handleShowDescriptionModal(cardData[1].detailedDescription)}
                                    >
                                        More Info
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} className="mb-4 d-flex justify-content-center">
                            <Card style={{ border: "0", backgroundColor: "#332b77" }}>
                                <Card.Img variant="top" src={cardData[2].imgSrc} />
                                <Card.Body className="text-center">
                                    <Button className='custom-btn'
                                        variant="primary"
                                        onClick={() => handleShowDescriptionModal(cardData[2].detailedDescription)}
                                    >
                                        More Info
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Third Row: 2 Cards */}
                    <Row className="justify-content-center">
                        <Col md={6} className="mb-4 d-flex justify-content-center">
                            <Card style={{ border: "0", backgroundColor: "#332b77" }}>
                                <Card.Img variant="top" src={cardData[3].imgSrc} />
                                <Card.Body className="text-center">
                                    <Button className='custom-btn'
                                        variant="primary"
                                        onClick={() => handleShowDescriptionModal(cardData[3].detailedDescription)}
                                    >
                                        More Info
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} className="mb-4 d-flex justify-content-center">
                            <Card style={{ border: "0", backgroundColor: "#332b77" }}>
                                <Card.Img variant="top" src={cardData[4].imgSrc} />
                                <Card.Body className="text-center">
                                    <Button className='custom-btn'
                                        variant="primary"
                                        onClick={() => handleShowDescriptionModal(cardData[4].detailedDescription)}
                                    >
                                        More Info
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Fourth Row: 1 Card (Sixth Image) */}
                    <Row className="justify-content-center">
                        <Col md={7} className="mb-4 d-flex justify-content-center">
                            <Card style={{ border: "0", backgroundColor: "#332b77" }}>
                                <Card.Img variant="top" src={sixth_img} />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Modal for showing detailed description */}
            <Modal show={showDescriptionModal} onHide={handleCloseDescriptionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>More Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>{selectedDescription}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ backgroundColor: "#332b78", borderRadius: "0px", border: "0" }} variant="primary" onClick={handleCloseDescriptionModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <div>
                <QuoteCarousel />
            </div>

            <div>
                <SponsorshipDeck />
            </div>

            {/* Apply for Award Section */}
            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                        <Col xs={12} md={6}>
                            <p className='heading'>Apply or Nominate someone for an award</p>
                            <div className='button-container'>
                                <Button onClick={handleShowApplicationModal} className='apply-button'>
                                    Apply Here
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="position-relative">
                            <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Modal for application submission */}
            <Modal show={showApplicationModal} onHide={handleCloseApplicationModal} dialogClassName="popup-modal">
                <Modal.Header style={{ backgroundColor: "#5050f3", color: "white", textAlign: "center" }} closeButton>
                    <Modal.Title>Bunchful Awards Application</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#5050f3" }}>
                    <p style={{ color: "white" }}>Applications will open 12PM Eastern Standard Time on May 30th, 2024. Join the mailing list for important notifications.</p>
                    <Form style={{ boxShadow: "none", backgroundColor: "#5050f3" }} onSubmit={handleSubmit}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label style={{ color: "white" }}>First Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter your first name" 
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)} // Set first name
                            />
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                            <Form.Label style={{ color: "white" }}>Last Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter your last name" 
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)} // Set last name
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label style={{ color: "white" }}>Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter your email" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} // Set email
                            />
                        </Form.Group>
                        <Button style={{ borderRadius: "0px", backgroundColor: "#332b78", border: "0" }} variant="primary" type="submit" className="submit-button">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Footer/>

        </div>
    );
};

export default Awardees;