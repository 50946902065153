import img1 from '../../images/homepage/testimonial-section/johane-m.webp'
import img2 from '../../images/homepage/testimonial-section/scott-s.webp'
import img3 from '../../images/homepage/testimonial-section/barbi-s.webp'
import img4 from '../../images/homepage/testimonial-section/jagrit-b.webp'
import img5 from '../../images/homepage/testimonial-section/david-j.webp'


export const reviews = [
    {
      id: 1,
      image: img1,
      title: 'Tocara Sales Executive | Montreal, Canada',
      name: 'Johane M',
      text: "As a jewelry entrepreneur selling directly to consumers, I've found the Bunchful Me card to be an invaluable tool significantly enhancing my brand's visibility and social impact. By showcasing my support for charities directly on the card, I'm able to connect on a deeper level with my clients who value social responsibility. The Bunchful Me card truly complements my business ethos, helping me share the beauty of giving back",
    },
    {
      id: 2,
      image: img2,
      name: 'Scott S',
      title: 'President, Handball Sporting Goods | New York, New York',
      text: "Working with Bunchful Enterprises has been an enlightening and transformative experience. They have created a vibrant platform that not only recognizes but also inspires acts of generosity and social responsibility. As a partner, we have witnessed firsthand the power of collective action and the impact it can have on communities worldwide. Bunchful's innovative approach, including the Bunchful Atlas, has been instrumental in spreading awareness and encouraging more individuals and organizations to step forward and share their abundance.",
    },
    {
      id: 3,
      image: img3,
      name: 'Barbi S',
      title: 'Executive Admin, Amazon, AWS | Seattle, Washington',
      text: "The Bunchful team worked diligently throughout (our) engagement, and even provided a press release and other marketing materials to distribute. I was fortunate to work directly with the CEO and Founder, who was attentive and delightful. Ultimately, we had at least 80% engagement from our team of 350 participants.. and recommend this to any team, especially those that are global, introverted teams, or are just looking for a way to reconnect. When it was all said and done, I got big kudos from my leadership, and I told them it ended up being everything I had hoped it to be.",
    },
    {
      id: 4,
      image: img4,
      name: 'Jagrit B',
      title: 'Ambassador, UNESCO Center for Peace | Greater Edmonton, Canada',
      text: "Bunchful (is) a social enterprise multi media platform which shines a spotlight on giving to help advance philanthropy towards achievement of the Sustainable Development Goals",
    },
    {
      id: 5,
      image: img5,
      name: 'David J',
      title: 'Property Manager, Akam | New York, New York',
      text: "I have been going to several of Bunchful events, including the World Summit & Awards for a couple years now and it always amazes me! These events are a must. They really highlight those who are giving back and the talks are inspiring and really energizing, plus, there is always a lot of time to network and meet people from all over the world. I recently started to subscribe to the Bunchful MeCard, which I use as my digital business card, so I never run out of business cards and I use for a lot when I meet new people. Bunchful is really next big thing that is beginning to get recognized all over the world. Watch this company and don't miss out.",
    },
  ];