import React, { useState, useEffect, useRef } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import "./Chatbot.css"; // Your CSS styles
import botLogo from "../../images/general/bunchful-icon.png";
import { v4 as uuidv4 } from "uuid"; // Install this library with `npm install uuid`

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false); // Toggle chat window
  const [messages, setMessages] = useState([]); // Chat messages
  const [userInput, setUserInput] = useState(""); // User's input
  const [sessionId, setSessionId] = useState(""); // Unique session ID
  const messagesEndRef = useRef(null); // Ref to scroll to the bottom

  useEffect(() => {
    // Generate a new session ID when the component mounts or the chat is opened
    setSessionId(uuidv4());
  }, [isOpen]);

  // Helper function to add a newline after each sentence
  const formatResponse = (response) => {
    return response.replace(/\. /g, ".\n");
  };

  const sendMessage = async () => {
    if (!userInput.trim()) return;

    // Add user's message to the chat
    const newMessages = [...messages, { sender: "user", text: userInput }];
    setMessages(newMessages);
    setUserInput(""); // Clear input field

    try {
      // Send user's message to the backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ask`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sessionId, question: userInput }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      } 

      const data = await response.json();
      let botResponse = data.answer || "Sorry, I couldn't understand that.";

      // Format the bot's response
      botResponse = formatResponse(botResponse);

      // Add bot's response to the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: botResponse },
      ]);
    } catch (error) {
      console.error("Error fetching bot response:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Oops, something went wrong!" },
      ]);
    }
  };

  // Scroll to the bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div>
      {/* Chat Button */}
      <div className="chat-button" onClick={() => setIsOpen(!isOpen)}>
        <AiOutlineMessage size={24} />
      </div>

      {/* Chat Window */}
      {isOpen && (
        <div className={`chat-window ${isOpen ? "open" : ""}`}>
          <div className="chat-header">
            <p className="chat-header-text">Chat with Us</p>
            <button className="close-button" onClick={() => setIsOpen(false)}>
              X
            </button>
          </div>
          <div className="chat-body">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${
                  msg.sender === "user" ? "user" : "bot"
                }`}
              >
                {msg.text}
              </div>
            ))}

            {/* Only show logo if last message is from bot */}
            {messages.length > 0 &&
              messages[messages.length - 1].sender === "bot" && (
                <div className="bot-logo-container">
                  <img src={botLogo} alt="Bot Logo" className="bot-logo" />
                </div>
              )}

            {/* Scroll reference element */}
            <div ref={messagesEndRef} />
          </div>

          <div className="chat-footer">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Type your message..."
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;