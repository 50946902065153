import React from 'react';
import "./privacyPolicy.css";
import { Helmet } from 'react-helmet';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";

const PrivacyPolicy = () => {

    return(
        <div className='privacy-policy'>
            <div>
                <Helmet>
                    <title>Privacy Policy - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div>
                <Header/>
            </div>

            <div className='section-1'>
                <h1 className='heading'>
                    Privacy Policy
                </h1>

                <p className='text'>
                    Bunchful, Inc.,a New York Corporation   This Privacy Policy was last updated on February 13, 2020. 
                    <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Our Policy</span>
                    Welcome to the website and the mobile application (collectively, the <span style={{fontWeight: 'bold' }}>"Website"</span>) of Bunchful, Inc. 
                    (<span style={{fontWeight: 'bold' }}>"Bunchful", "we", "us"</span> and/or <span style={{fontWeight: 'bold' }}>"our"</span>).  
                    This Website is operated by Bunchful and has been created to provide information about our company and its services (together with the Website, the <span style={{fontWeight: 'bold' }}>“Services”</span>) by our visitors (<span style={{fontWeight: 'bold' }}>“you”, “your”</span>). 
                    This Privacy Policy sets forth Bunchful's policy with respect to information including personally identifiable data (<span style={{fontWeight: 'bold' }}>“Personal Data”</span>) 
                    and other information that is collected from visitors to the Services.  Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in the Terms & Conditions.  
                    This Privacy Policy is subject to the Terms & Conditions, including any limitations of liability.<span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Information We Collect</span>
                    When you interact with us through the Services, we may collect Personal Data and other information from you, as further described below. 
                    Note that the information we collect from you may be shared amongst our various social media accounts, including: Google, Facebook, Twitter, YouTube, Instagram, Pinterest, Medium and LinkedIn, as well as our telephone customer services.
                    <span style={{fontWeight: 'bold' }}>Personal Data That You Provide Through the Services:</span> 
                    We collect Personal Data from you when you voluntarily provide such information, such as when you contact us with inquiries, respond to one of our surveys, register for access to the Services or use certain Services through our platform. 
                    This Personal Data may include your name, address, email address, age and more. 
                    Wherever Bunchful collects Personal Data we make an effort to provide a link to this Privacy Policy.
                    By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. 
                    If you provide Personal Data to the Services, you acknowledge and agree that such Personal Data may be transferred from your current location to the offices and servers
                    of Bunchful and the authorized third party services referred to herein (whose services we have contracted for use of our business) located in the United States. <span style={{fontWeight: 'bold' }}>Other Information:</span> <br/>
                    <ul>
                        <li>
                            <strong><em>Aggregated Personal Data:</em></strong> In an ongoing effort to better understand and serve the users of the Services, Bunchful, working with third parties engaged by Bunchful, often conducts research on its customer demographics, interests, and behavior based on the Personal Data and other information provided to us. This research may be compiled and analyzed on an aggregate basis, and Bunchful may share this aggregate data with its affiliates, agents, and business partners. This aggregate information does not identify you personally. Bunchful may also disclose aggregated user statistics in order to describe our services to current and prospective business partners and to other third parties for other lawful purposes.
                        </li>
                        <li>
                            <strong><em>Browsing and Social Media Data:</em></strong> When you interact with Bunchful through the Services, we receive and store certain information. Such information is collected passively using various technologies. Bunchful may store such information itself or such information may be included in databases owned and maintained by Bunchful affiliates, agents, or service providers. The Services may use such information and pool it with other information to track, for example, the total number of visitors to our Website, the number of visitors to each page of our Website, and the domain names of our visitors’ Internet service providers. In operating the Services, we may use a technology called “cookies.” A cookie is a piece of information that the computer that hosts our Services gives to your browser when you access the Services. Our cookies help provide additional functionality to the Services and help us analyze Services usage more accurately. For instance, our Services may set a cookie on your browser that allows you to access the Services without needing to remember and then enter a password more than once during a visit to the Services. On most web browsers, you will find a “help” section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. We recommend that you leave cookies turned on because they allow you to take advantage of some of the Service features.
                        </li>
                        <li>
                            <strong><em>California Do Not Track Disclosure:</em></strong> Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed above (e.g., by disabling cookies), but such disabling will impair the use of this Website and our Services.
                        </li>
                        <li>
                            <strong><em>Location Information:</em></strong> Our Service may collect and use your location information (for example, by using the GPS on your mobile device, provided you have opted-in to allowing collection of such information) to provide certain functionality of our Service. We may also use your location information in an aggregate way, as described above in the “Aggregated Personal Data” section.
                        </li>
                    </ul> 
                        <strong><u>Our Use of Your Personal Data and Other Information</u></strong>
                        Bunchful uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. 
                        If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. 
                        For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem. 
                        Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such Services and to monitor your use of such Services.  
                        Bunchful and its subsidiaries (the “<strong>Bunchful Related Companies</strong>”), as well as any affiliates the conduct business with the Bunchful Related Companies, 
                        may also use your Personal Data and other non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our Users and to improve the Services.  The Bunchful Related Companies and our affiliates may use this information to contact you in the future to tell you about services we believe will be of interest to you.  If we do so, each marketing communication we send you will contain instructions permitting you to “opt out” of receiving future marketing communications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.
                        <strong><u>Our Disclosure of Your Personal Data and Other Information</u></strong><strong>Bunchful is not in the business of selling your information.</strong> We consider this information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below:

                    <ul>
                        <li>
                            <strong>Merchants:</strong> We may disclose your Personal Data to Merchants for the purposes of fulfilling your orders and other requests, tailoring our site to help you, and sending appropriate marketing communications.
                        </li>
                        <li>
                            <strong>Business Transfers:</strong> As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution, or similar event, Personal Data may be part of the transferred assets.
                        </li>
                        <li>
                            <strong>Related Companies:</strong> We may also share your Personal Data with our Bunchful Related Companies for purposes consistent with this Privacy Policy.
                        </li>
                        <li>
                            <strong>Agents, Consultants and Related Third Parties:</strong> Bunchful, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases, and processing payments. When we employ another entity to perform a function of this nature, we only provide them with the information that they need to perform their specific function.
                        </li>
                        <li>
                            <strong>Legal Requirements:</strong> Bunchful may disclose your Personal Data if required to do so by Law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, subpoena or other legal process, (ii) protect and defend the rights or property of Bunchful, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability.
                        </li>
                    </ul>
                    <strong><u>Your Choices</u></strong>You can visit the Website without providing any Personal Data. 
                    If you choose not to provide any Personal Data, you may not be able to use certain Services. 
                    <strong><u>California Resident</u></strong> California residents who have provided Personal Information 
                    to Bunchful may obtain information regarding Bunchful’s disclosures, if any, of Personal Information to third
                    parties for third-party direct marketing purposes. Requests must be submitted to the following address:  
                    230 Park Ave, 3rd Floor, New York, NY 10169. Within 30 days of receiving such a request, Bunchful will 
                    provide a California Privacy Disclosure, which will include a list of certain categories of Personal Information 
                    disclosed during the preceding calendar year to third parties for their direct marketing purposes, along with the 
                    names and addresses of the third parties. This request may be made no more than two times per calendar year. 
                    We reserve our right not to respond to requests if they are not submitted to the address specified in this paragraph.
                    <strong><u>European Residents</u></strong>Bunchful is directed to users located in the United States.  
                    If you are located outside of the United States and you choose to use the Website or provide your information to us 
                    in connection with our Services or otherwise, your information may be transferred to, processed and maintained 
                    on servers or databases located in the United States. Your agreement to this Privacy Policy followed by your 
                    submission of information through the Website represents your consent and agreement to these practices.  
                    If you do not want your information transferred to or processed or maintained in the United States, you should not use the Website or our Services. 
                    <strong><u>Exclusions</u></strong>This Privacy Policy does not apply to any Personal Data collected by Bunchful other than Personal Data collected through the Services. 
                    This Privacy Policy shall not apply to any unsolicited information you provide to Bunchful through the Services or 
                    through any other means. This includes, but is not limited to, information posted to any public areas of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited submissions 
                    (collectively, “<strong>Unsolicited Information</strong>”). All Unsolicited Information shall be deemed to be non-confidential
                    and any and all rights in the Unsolicited Information shall be deemed as waived. 
                    Bunchful shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
                    <strong><u>Children</u></strong> Bunchful does not knowingly collect Personal Data from children under the age of 13. If you are under the age of 13, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on the Services without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to Bunchful through the Services, please contact us, and we will delete that information from our databases.
                    <strong><u>Links to Other Web Websites</u></strong> This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by Bunchful 
                    (the “<strong>Third Party Websites</strong>”). The policies and procedures we described here do not apply to the Third Party Websites. The links from the Services do not imply that Bunchful endorses or has reviewed the Third Party Websites. 
                    We suggest contacting those Websites directly for information on their privacy policies. 
                    <strong><u>Integrating Social Networking Services</u></strong>One of the special features of the Service is that it allows you to enable or log in to the Services via various social networking services like Facebook, Twitter, Instagram or other 
                    (“<strong>Social Networking Service(s)</strong>”). By directly integrating these services, we make your online experiences richer and more personalized. 
                    To take advantage of this feature, we will ask you to log into or grant us permission via the relevant Social Networking Service. When you add a Social Networking Services account to the Service or log into the Service using your Social Networking Services account, we will collect relevant information necessary to enable the Service to access that Social Networking Service and your data contained within that Social Networking Service.  As part of such integration, the Social Networking Service will provide us with access to certain information that you have provided to the Social Networking Service, and we will use, store and disclose such information in accordance with this Privacy Policy. However, please remember that the manner in which Social Networking Services use, store and disclose your information is governed by the policies of such third parties, and Company shall have no liability or responsibility for the privacy practices or other actions of any Social Networking Services that may be enabled within the Service.You may also have the option of posting your Services activities to Social Networking Services when you access content through the Services (for example, you may post to Facebook that you performed an activity on the Service); you acknowledge that if you choose to use this feature, your friends, followers and subscribers on any Social Networking Services you have enabled will be able to view such activity.
                    <strong><u>Security</u></strong>Bunchful takes reasonable steps to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via email. Please keep this in mind when disclosing any Personal Data to Bunchful via the Internet.Payment information: If you make purchases or subscribe to services through or for our Website, we might collect your credit card information to process the payment. If you are paying for a product or service by credit card directly on our Website, we collect that information, but it does not remain on our servers; it is handled directly by our payment service provider–Stripe. For each purchase made, we may also store country code, currency, and the type of device in which the purchase was made. 
                    <strong><u>Other Terms and Conditions</u></strong> Your access to and use of the Services is subject to the Terms of Service at bunchful.com. 
                    <strong><u>Changes to Bunchful’s Privacy Policy</u></strong> The Services and our business may change from time to time. As a result, at times it may be necessary for Bunchful to make changes to this Privacy Policy. Bunchful reserves the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before you provide any Personal Data. This Privacy Policy was last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
                    <strong><u>Access to Information; Contacting Bunchful</u></strong>To keep your Personal Data accurate, current, and complete, please contact us as specified below. 
                    We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Services.
                    Please also feel free to contact us if you have any questions about Bunchful’s Privacy Policy or the information practices of the Services.
                    You may contact us as follows: You may contact us as follows: <a href="mailto:info@bunchful.com" style={{ textDecoration: "none", color: "#cf4070" }}> info@bunchful.com </a>

                    
                </p>

            </div>

            <div>
                <Footer/>
            </div>

        </div>

    );

}
export default PrivacyPolicy;
