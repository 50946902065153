import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button, } from 'react-bootstrap'
import Footer from "../../Components/Footer/footer";
import Media from "../../Components/Media/mediaCard"; // Import the MediaCard component
import Header from "../../Components/Header/header";
import Banner from "../../Components/Banner/banner";
import bannerImage from "./backgroundMedia.svg";
import exampleImage from "../../images/homepage/media-page/page-end.webp";
import "./media.css";


const MediaPage = () => {
    return(
        <div className="media">
            <div>
                <Helmet>
                    <title>Media - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div>
                <Header/>
            </div>

            <div>
                <Banner
                    homeLink="Home"
                    breadcrumb="Media"
                    title="Media"
                    backgroundImage={bannerImage}
                />
            </div>

            <div className="media-section">
              <Media/>
            </div>

            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                            Let Bunchful amplify and spotlight your philanthropy.
                        </p>
                        <div className='button-container'>
                        <Button className='apply-button' href="https://bunchfulatlas.com/">
                            Apply Here
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    )
};

export default MediaPage;