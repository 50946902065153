import React, { useState } from "react";
import { Container, Row, Col, Carousel, Button, Modal, Form } from 'react-bootstrap';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import SponsorshipDeck from "../../Components/SponsorshipDeck/sponsorship";
import "./worldConcert.css";
import backgroundVideo from './backgroundVideo.mp4';
import worldConcertTopImage from "../../images/homepage/events-page/world-concert/image-1.webp";
import exampleImage from "../../images/homepage/events-page/world-concert/image-end.webp";
import slide1 from "../../images/homepage/events-page/world-concert/slide-1.webp";
import slide2 from "../../images/homepage/events-page/world-concert/slide-2.webp";
import slide3 from "../../images/homepage/events-page/world-concert/slide-3.webp";
import slide4 from "../../images/homepage/events-page/world-concert/slide-4.webp";
import slide5 from "../../images/homepage/events-page/world-concert/slide-5.webp";
import slide6 from "../../images/homepage/events-page/world-concert/slide-6.webp";
import slide7 from "../../images/homepage/events-page/world-concert/slide-7.webp";
import slide8 from "../../images/homepage/events-page/world-concert/slide-8.webp";
import slide9 from "../../images/homepage/events-page/world-concert/slide-9.webp";
import slide10 from "../../images/homepage/events-page/world-concert/slide-10.webp";
import slide11 from "../../images/homepage/events-page/world-concert/slide-11.webp";
import slide12 from "../../images/homepage/events-page/world-concert/slide-12.webp";
import bwc1 from "../../images/homepage/events-page/world-concert/bwc-1.webp";
import bwc2 from "../../images/homepage/events-page/world-concert/bwc-2.webp";
import bwc3 from "../../images/homepage/events-page/world-concert/bwc-3.webp";
import bwc4 from "../../images/homepage/events-page/world-concert/bwc-4.webp";
import { Helmet } from "react-helmet";
import { db } from '../../config/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods

const WorldConcert = () => {

    const [showForm, setShowForm] = useState(false);
    const [firstName, setFirstName] = useState(''); // State for first name
    const [lastName, setLastName] = useState(''); // State for last name
    const [email, setEmail] = useState(''); // State for email

    const handleShowForm = () => setShowForm(true);
    const handleCloseForm = () => setShowForm(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await addDoc(collection(db, 'eventsTicketsWaitlist'), {
            firstName,
            lastName,
            email,
          });
          alert('Application submitted successfully!');
          setFirstName(''); // Clear form fields
          setLastName('');
          setEmail('');
          handleCloseForm(); // Close the modal after submission
        } catch (error) {
          console.error('Error submitting application:', error);
        }
    };

    return (
        <div className="world-concert">
            <div>
                <Helmet>
                    <title>World Concert - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div className="header">
                <Header/>
            </div>

            <div className="banner">
                <video className="banner-video" autoPlay loop muted>
                    <source src={backgroundVideo} type="video/mp4" />
                </video>

                <div className="banner-overlay">
                    <div className="banner-content text-center">
                        <p className="banner-link">
                            <a href="/" className="banner-link-text">Home</a>
                            {' » '}
                            <span>World Concert</span>
                        </p>
                        <h1 className="banner-title">Bunchful World Concert</h1>
                    </div>
                </div>
            </div>

            <div className="section-1">
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <img src={worldConcertTopImage} className="img-fluid" />
                        </Col>

                        <Col xs={12} md={6} >
                            <div className="content-wrapper">
                                <h2 className="heading">It's a Bunchful World Concert</h2>
                                <p className="text">
                                    Subject to positive developments with the pandemic, we hope to host the BE BUNCHFUL Ball as a life-affirming celebration event. 
                                    This will be a unique opportunity for the giving community to mingle and celebrate collective efforts towards a better world for one special evening. 
                                    Further details to be confirmed in due course.
                                    <br /> <br />
                                    This will be a unique opportunity for the giving community to mingle and celebrate collective efforts 
                                    towards a better world for one special evening. Further details to be confirmed in due course.
                                </p>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </div>

            <div className="section-2">

                <h1 className="heading text-center">
                    Concert Events
                </h1>

                <Container fluid>
                    <Row>
                        <Col  xs={12} md={8}>
                            <div className="image-container">
                                <img src={bwc1} alt="Concert 1" className="img-fluid" />
                                <div className="overlay">
                                    <h2>Bunchful World Concert</h2>
                                    <p>Date to be announced</p>
                                    <Button variant="primary" onClick={handleShowForm}>Get Tickets</Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="image-container">
                                <img src={bwc2} alt="Concert 2" className="img-fluid" />
                                <div className="overlay">
                                    <h2>Bunchful World Concert</h2>
                                    <p>Date to be announced</p>
                                    <Button variant="primary" onClick={handleShowForm}>Get Tickets</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <div className="image-container">
                                <img src={bwc3} alt="Concert 3" className="img-fluid" />
                                <div className="overlay">
                                    <h2>Bunchful World Concert</h2>
                                    <p>Date to be announced</p>
                                    <Button variant="primary" onClick={handleShowForm}>Get Tickets</Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={8}>
                            <div className="image-container">
                                <img src={bwc4} alt="Concert 4" className="img-fluid" />
                                <div className="overlay">
                                    <h2>Bunchful World Concert</h2>
                                    <p>Date to be announced</p>
                                    <Button variant="primary" onClick={handleShowForm}>Get Tickets</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-3">
                <Carousel>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide1}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide2}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide3}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide4}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide5}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide6}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide7}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide8}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide9}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide10}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide11}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide12}
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

            <div className="sponsorship-section">
                <SponsorshipDeck/>
            </div>

            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                            Transform yourself. Transform the world.
                        </p>
                        <div className='button-container'>
                        <Button  className='apply-button' onClick={handleShowForm}>
                            Get Tickets
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>

            <div className="footer">
                <Footer/>
            </div>


            <Modal
                show={showForm}
                onHide={handleCloseForm}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)'}}  // for the backdrop transparency
            >
                <Modal.Header closeButton style={{ backgroundColor: '#130038', border: 'none', borderRadius: "0px", justifyContent: 'center'}} closeVariant="white">
                    <Modal.Title style={{color: "white", textAlign:"center"}}>Join the Waitlist</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#130038', border: 'none'}}>
                    <Form style={{ backgroundColor: '#130038', border: 'none', boxShadow: "none"}} onSubmit={handleSubmit}>
                        <Row>
                        <Col md={6}>
                            <Form.Group controlId="firstName">
                            <Form.Label style={{ color: "white"}}>First Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter your first name" 
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="lastName">
                            <Form.Label style={{ color: "white"}}>Last Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter your last name" 
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            </Form.Group>
                        </Col>
                        </Row>

                        <Form.Group controlId="email" className="mt-3">
                        <Form.Label style={{ color: "white"}}>Email</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="Enter your email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} // Set email
                        />
                        </Form.Group>

                        <Button style={{backgroundColor: "#6147f2", border: "0"}} variant="primary" type="submit" className="mt-3">
                        Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>

    );
}
export default WorldConcert;