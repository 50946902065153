// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.py-5 .heading {
  font-size: 1rem;
}

.py-5 .desc {
  font-size: 2.1rem;
  padding-bottom: 3%;
}

.custom-btn {
  background-color: #5050f3;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  padding: 10px 10px;
  width: 150px;
  margin-top: 10px;
  transition: background-color 0.3s; /* Smooth transition */
}

.custom-btn:hover {
  background-color: #31317b; /* Darker shade */
}

/* Ensure the image and text are centered in smaller viewports */
@media (max-width: 768px) {
  .card {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
  }

  .card-img-top {
    margin: 0 auto; /* Center image horizontally */
    max-width: 80%; /* Adjust as needed */
    height: auto;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content within the card body */
    text-align: center; /* Center text within the card body */
  }

  .card-title, .card-text, .btn {
    text-align: center; /* Center text within elements */
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Tech/technologyCard.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,iCAAiC,EAAE,sBAAsB;AAC3D;;AAEA;EACE,yBAAyB,EAAE,iBAAiB;AAC9C;;AAEA,gEAAgE;AAChE;EACE;IACE,aAAa;IACb,sBAAsB,EAAE,2BAA2B;IACnD,mBAAmB,EAAE,8BAA8B;IACnD,kBAAkB,EAAE,gBAAgB;EACtC;;EAEA;IACE,cAAc,EAAE,8BAA8B;IAC9C,cAAc,EAAE,qBAAqB;IACrC,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAE,wCAAwC;IAC7D,kBAAkB,EAAE,qCAAqC;EAC3D;;EAEA;IACE,kBAAkB,EAAE,gCAAgC;EACtD;AACF","sourcesContent":[".py-5 .heading {\n  font-size: 1rem;\n}\n\n.py-5 .desc {\n  font-size: 2.1rem;\n  padding-bottom: 3%;\n}\n\n.custom-btn {\n  background-color: #5050f3;\n  border-radius: 5px;\n  color: #fff;\n  font-size: 1rem;\n  padding: 10px 10px;\n  width: 150px;\n  margin-top: 10px;\n  transition: background-color 0.3s; /* Smooth transition */\n}\n\n.custom-btn:hover {\n  background-color: #31317b; /* Darker shade */\n}\n\n/* Ensure the image and text are centered in smaller viewports */\n@media (max-width: 768px) {\n  .card {\n    display: flex;\n    flex-direction: column; /* Stack items vertically */\n    align-items: center; /* Center items horizontally */\n    text-align: center; /* Center text */\n  }\n\n  .card-img-top {\n    margin: 0 auto; /* Center image horizontally */\n    max-width: 80%; /* Adjust as needed */\n    height: auto;\n  }\n\n  .card-body {\n    display: flex;\n    flex-direction: column;\n    align-items: center; /* Center content within the card body */\n    text-align: center; /* Center text within the card body */\n  }\n\n  .card-title, .card-text, .btn {\n    text-align: center; /* Center text within elements */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
