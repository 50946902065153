import React from 'react';
import { Container, Row, Col, Card, Button} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import "./performances.css";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Banner from "../../Components/Banner/banner";
import bannerImage from "../About/backgroundAbout.svg";

import topImg from "../../images/homepage/events-page/awards/performance/world-concert.webp";
import exampleImage from "../../images/homepage/events-page/awards/performance/page-end.webp";

const Performances = () => {
    
    return (
        <div className='performances'>
            <div>
                <Helmet>
                    <title>Performances - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div>
                <Header/>
            </div>

            <div>
                <Banner
                    homeLink="Home"
                    breadcrumb="Performances"
                    title="Performances"
                    longText="The Bunchful Awards annually recognize individuals and organizations making significant philanthropic impacts. These accolades highlight efforts aligning with the Sustainable Development Goals (SDGs), inspiring further commitment to positive community and global change through innovative and impactful philanthropy."
                    backgroundImage={bannerImage}
                />
            </div>

            <div className='section-1'>

                <Container>
                    <h2 className='text-center' style={{fontSize: "2rem", paddingTop: "5%", paddingBottom: "5%"}}>
                        2023 Performance
                    </h2>

                    <Row className="justify-content-center">
                        <Col md={6} className="mb-4 d-flex justify-content-center">
                            <Card style={{border: "0", paddingBottom: "5%"}}>
                                <Card.Img style= {{borderRadius: "0px"}}variant="top" src={topImg} />
                            </Card>
                        </Col>
                    </Row>

                </Container>

            </div>

            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                            Help drive achievement of the SDGs.
                        </p>
                        <div className='button-container'>
                        <Button  className='apply-button' href="/contact">
                            Contact Us
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    );
}
export default Performances;