import React, { useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component"; 
import "react-vertical-timeline-component/style.min.css"; 
import { Container, Row, Col, Navbar, Card, Nav, Button, Modal, Form } from 'react-bootstrap';
import "./awards.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faCalendar } from '@fortawesome/free-solid-svg-icons'; // Import faCalendar
import { Helmet } from 'react-helmet';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import SponsorshipDeck from "../../Components/SponsorshipDeck/sponsorship";
import Banner from "../../Components/Banner/banner";
import bannerImage from "../Sponsor/backgroundSponsor.svg";
import top_image from "../../images/homepage/events-page/awards/image-1.webp"
import second_image from "../../images/homepage/events-page/awards/image-2.webp"
import third_image from "../../images/homepage/events-page/awards/image-3.webp"
import sdg17 from "../../images/homepage/events-page/awards/sdg-17.webp"
import logo_1 from "./logo_1.webp";
import logo_2 from "./logo_2.webp";
import logo_3 from "./logo_3.webp";
import logo_4 from "./logo_4.webp";
import statue from "../../images/homepage/events-page/awards/statue.webp";
import logo_5 from "./logo_5.webp";
import logo_6 from "./logo_6.webp";
import logo_7 from "./logo_7.webp";
import logo_8 from "./logo_8.webp";
import logo_9 from "./logo_9.webp";
import logo_10 from "./logo_10.webp";
import { db } from '../../config/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods

const Awards = () => {

    const [showModal, setShowModal] = useState(false);
    const [firstName, setFirstName] = useState(''); // State for first name
    const [lastName, setLastName] = useState(''); // State for last name
    const [email, setEmail] = useState(''); // State for email
  
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await addDoc(collection(db, 'bunchfulAwardsApplications'), {
            firstName,
            lastName,
            email,
          });
          alert('Application submitted successfully!');
          setFirstName(''); // Clear form fields
          setLastName('');
          setEmail('');
          handleCloseModal(); // Close the modal after submission
        } catch (error) {
          console.error('Error submitting application:', error);
        }
    };

    return (
        <div className='awards'>

            <Helmet>
                <title>Awards - Bunchful | The Generosity Business | Official Site</title>
            </Helmet>

            <div className='top_header'>
                <Header />
            </div>
            <Navbar collapseOnSelect expand="lg" className="custom_navbar">
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto justify-content-center w-100">
                            <Nav.Link className='links' href="#awards">Awards</Nav.Link>
                            <Nav.Link className='links' href="#awards-description">Awards Description</Nav.Link>
                            <Nav.Link className='links' href="#timeline">Timeline</Nav.Link>
                            <Nav.Link className='links' href="#requirements">Requirements</Nav.Link>
                            <Nav.Link className='links' href="/awardees">Awardees</Nav.Link>
                            <Nav.Link className='links' href="/performances">Performances</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className="banner">
                <Banner
                    homeLink="Home"
                    breadcrumb="Awards"
                    title="Awards"
                    longText="The Bunchful Awards annually recognize individuals and organizations making significant philanthropic impacts. These accolades highlight efforts aligning with the Sustainable Development Goals (SDGs), inspiring further commitment to positive community and global change through innovative and impactful philanthropy."
                    backgroundImage={bannerImage}
                    date="Date: September 18, 2025"
                />
            </div>

            <div className='section-1 text-center' id="awards">
                <h1 className='heading'>
                    Nominate Someone You Know
                </h1>
                <Button className="nominate-button" onClick={handleShowModal}>Nominate</Button>
            </div>

            <div className='section-2'>
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <img src={top_image} className="img-fluid" />

                        </Col>

                        <Col xs={12} md={6}>
                            <h2 className='heading'>
                                The why behind it all
                            </h2>

                            <p className='text'>
                                When Bunchful Founder, Raquel Miller was growing up in the Caribbean, 
                                she gained profound life lessons, first through her grandmother with whom she 
                                spent the first ten years of life in their small farming town, and later through 
                                her father. Her experiences translated into a desire to ignite global inspiration through 
                                giving, which lead her to found Bunchful Enterprises, a platform to honor the achievements 
                                of individuals and organizations who are making a positive impact in their communities and the world. 
                                This then lead to the adoption of the United Nations Sustainable Development Goals (SDGs), which were 
                                created to establish a more compassionate world, as an operating standard for the organization. 
                                Accordingly, the Bunchful Awards and the Bunchful Future of Philanthropy Summit aim to raise awareness about 
                                the SDGs and the crucial role of philanthropy in driving sustainable development.
                            </p>
                        
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className='section-3'>
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <p className='text'>
                                Additionally, and on a more basic level, we wanted to create a space for sharing success stories,
                                highlighting exemplary practices, and inspiring the replication of successful initiatives. We 
                                believe that by fostering collaboration and bringing together diverse stakeholders, we can 
                                encourage partnerships, the exchange of ideas and to accelerate our own progress. Furthermore, 
                                we hope to motivate philanthropic actions and showcase the tangible impact of such efforts and 
                                recognize diverse contributions.
                                <br/>
                                <br/>
                                However, our efforts extend beyond mere recognition. We strive to promote the global goals at a
                                local level, and aim to acknowledge philanthropic leadership, placing great emphasis on encouraging
                                youth engagement and mobilizing resources to attract funding and support for charitable endeavors. 
                                We strongly believe in responsible investment and the promotion of accountability. Through highlighting 
                                the role of businesses in corporate social responsibility, we aim to showcase their potential to make a 
                                positive impact, influencing policy and fostering a culture of volunteerism.

                            </p>
                        </Col>

                        <Col xs={12} md={6}>
                            <img src={second_image} className="img-fluid" />
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className='section-4'>
                <Container>
                    <Row>
                        <Col md={3}>
                            <Card style={{ backgroundColor: '#fff4f6' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_1}
                                        alt="Why We Do It Logo"
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">Why We Do It</Card.Title>
                                    <Card.Text className="card-text-sm">
                                        Our continued hope for the Bunchful Awards and Future of Philanthropy Summit is to bring change-makers and givers together in community, with individuals, non-profits, and businesses to share ideas and collaborate toward a common goal.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3}>
                            <Card style={{ backgroundColor: '#ebfffc' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_2}
                                        alt="For Whom We Do It Logo"
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">For Whom We Do It</Card.Title>
                                    <Card.Text className="card-text-sm">
                                        November 2021 was our launch event held virtually, in light of the continued uncertainty around the Covid-19 pandemic. It brought international participants together for remarkable conversations around giving, and we continue attracting a global audience to these important discussions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3}>
                            <Card style={{ backgroundColor: '#fefcf3' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_3}
                                        alt="How We Do It Logo"
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">How We Do It</Card.Title>
                                    <Card.Text className="card-text-sm">
                                        We reach out to individuals who are making a difference in their own communities to provide a spotlight, along with notable persons who are also demonstrating their own generosity to a range of causes. We envisage a star-studded affair and a mix of everyday individuals.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3}>
                            <Card style={{ backgroundColor: '#ecfaff' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_4}
                                        alt="Help Us Do It Logo"
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">Help Us Do It</Card.Title>
                                    <Card.Text className="card-text-sm">
                                        The Bunchful Awards and Summit will continue to highlight the many contributions and initiatives made to benefit those in great need, along with highlighting those doing the work of giving. Join us: hello@bunchful.com
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Button href="/un-sdgs" className='unsdgs-button'>17 UN SDG Goals</Button>
                </Container>
            </div>

            <div className='section-5'>
                <Container>
                    <img src={sdg17} className="img-fluid" />
                </Container>
            </div>

            <div className='section-6' id="awards-description">
                <Container fluid>
                    <h2 className='heading'>
                        The Bunchful Awards are inspired by a desire to create a more “bunchful” world.
                    </h2>

                    <p className='text'>
                        This very much connects to wider efforts towards the delivery of the UN Sustainable Development Goals (SDGs), 
                        the UN's interlinked global goals designed to be a “blueprint to achieve a better and more sustainable future 
                        for all” by 2030 As we seek to honor those who are dedicating their lives for the betterment of others, we hope 
                        you will join our efforts to recognize them.
                    </p>

                    <Row className='card-container'>
                        <Col md={6}>
                            <Card className="p-3">
                                <Card className="p-3" style={{ backgroundColor: '#ffeeb2', borderColor: '#ffeeb2' }}>
                                    <Row className="text-left">
                                        <Col>
                                            <div className="number-circle">01</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4>The Bunchful "ZHAG" Award (Zayeed Hakim Award for Generosity)</h4>
                                            <p>
                                                The Bunchful ZHAG Award, short for Zayeed Hakim Award for Generosity, is a high honor inspired by the incredible generosity of Zayeed Hakim, a beloved former Bunchful Ambassador. Zayeed's unwavering commitment to uplifting others serves as the cornerstone of this award, and in tribute to his enduring legacy, the ZHAG Award acknowledges individuals, groups, or organizations that exemplify exceptional generosity. Unlike conventional awards, the ZHAG Award is not bound by specific criteria from the independent body of the Bunchful Nominating Committee. Instead, it is given at Bunchful's discretion, to honor Zayeed's spirit of selflessness and compassion, inspiring others to follow in his footsteps, and acknowledging those who've gone the extra mile for others, helping to make the world a better place for us, all. This award is given with a subjective evaluation by Bunchful Enterprise.
                                            </p>
                                            <Button className="apply-button" variant="no_border" onClick={handleShowModal}>Apply Here ➞</Button>
                                        </Col>
                                    </Row>
                                </Card>

                                <Row className="text-left">
                                    <Col>
                                        <div className="number-circle">02</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h4>The Bunchful "Rising Star" Award</h4>
                                        <p>
                                            Introducing the Bunchful “Rising Star” Award, a distinguished recognition celebrating exceptional leadership and collaboration in advancing Sustainable Development Goal 17: Partnerships for the Goals. This prestigious honor acknowledges individuals, businesses, organizations, or groups that have demonstrated outstanding commitment to fostering partnerships and alliances for sustainable development.
                                            <br/><br/>
                                            The recipients of the Bunchful “Rising Star” Award exemplify remarkable dedication, innovative thinking, and a collaborative spirit in addressing three or more United Nations Sustainable Development Goals. For example, businesses are partnering with other businesses to implement sustainable supply chain practices, like sourcing eco-friendly materials or reducing carbon emissions. Similarly, individuals or groups have collaborated with organizations to launch community-based initiatives, such as educational programs on sustainable practices.
                                            <br/><br/>
                                            Their journey reflects significant growth, creativity, and leadership, leaving a lasting impact on their communities and beyond. Whether fostering collaborations between businesses and non-profit organizations to improve access to education and healthcare, partnering with local communities to revitalize urban spaces and promote inclusive economic development, or teaming up with cultural institutions to raise awareness about social justice issues and promote diversity and inclusion, these Rising Stars demonstrate the power of collective action in driving positive change.
                                            <br/><br/>
                                            This esteemed recognition acknowledges the extraordinary achievements, potential, and promise displayed by these Rising Stars, positioning them as the vanguards of sustainable development. Their passion, determination, and willingness to challenge conventions redefine the boundaries of their fields, leaving an enduring legacy for future generations.
                                            <br/><br/>
                                            Criteria: Contributions align with UN SDG 17.       
                                        </p>
                                        <Button className="apply-button" variant="no_border" onClick={handleShowModal}>Apply Here ➞</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <div className="text-center">
                                <img 
                                src={statue}
                                alt="Statue" 
                                className="img-fluid"
                                />
                            </div>
                            <Card className="p-3 text-white" style={{ backgroundColor: '#f3506f' }}>
                                <p style={{fontSize: "1.3rem", fontWeight: "600", paddingTop: "10%"}}>The Bunchful Awards statue is a tribute to the enduring legacy of the founder's grandmother, a remarkable philanthropist whose life's work inspired the creation of these awards. Designed to symbolize the generosity and enduring impact of her charitable endeavors, the statue represents the essence of giving back and community support that she embodied. Each award is not just a recognition of achievement but also a homage to her spirit of benevolence, aiming to encourage and honor those who strive to make a significant difference in the world, just as she did. Through this award, her legacy continues to inspire new generations to carry forward the torch of philanthropy and communal uplift.
                                </p>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='card-container'>
                        <Col md={6}>
                            <Card className="p-3">
                                <Row className="text-left">
                                    <Col>
                                        <div className="number-circle">03</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h4>
                                            The Bunchful “Vitality” Award
                                        </h4>
                                        <p>
                                            Introducing the Bunchful “Vitality” Award, illuminating extraordinary efforts to overcome the most daunting obstacles to human well-being. This esteemed honor celebrates innovation and dedication in the pursuit of a healthier, more prosperous world.
                                            <br/> <br/>
                                            Across the world, a sobering reality persists: many, including women, girls, and those entrenched in poverty, grapple with limited access to life's essentials such as nutritious food, quality healthcare, and basic sanitation (SDG 1: No Poverty, SDG 2: Zero Hunger, SDG 3: Good Health and Well-being). These disparities not only stifle personal development but also perpetuate cycles of poverty and inequality.
                                            <br/> <br/>
                                            Yet amid these challenges, there emerge champions of change—individuals and organizations dedicated to making a difference. The Vitality Award seeks to commend their exceptional endeavors, recognizing projects that demonstrate tangible progress in addressing food poverty, enhancing physical and mental well-being, advancing sustainable agriculture, and fostering global partnerships (SDG 5: Gender Equality, SDG 6: Clean Water and Sanitation, SDG 17: Partnerships for the Goals).
                                            <br/> <br/>
                                            Applicants for this award are visionaries shaping a more equitable, resilient future. The Vitality Award amplifies the voices and experiences of those striving for positive change, aligning with the United Nations Sustainable Development goals.
                                            <br/> <br/>
                                            Criteria: Contributions align with any of the following UN SDGs 1, 2, 3, 5, 6, and may also include 17.
                                        </p>
                                        <Button className="apply-button" variant="no_border" onClick={handleShowModal}>Apply Here ➞</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card className="p-3">
                                <Row className="text-left">
                                    <Col>
                                        <div className="number-circle">04</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h4>
                                            The Bunchful “Shared Abundance” Award
                                        </h4>
                                        <p>
                                            The Shared Abundance Award celebrates champions dedicated to tackling critical issues like economic vulnerability, systemic inequities, and limited healthcare access, among others. It spotlights leaders, individuals, groups, and businesses pioneering concrete solutions, fostering inclusive growth, and uplifting long-marginalized communities and individuals.
                                            <br/> <br/>
                                            This prestigious honor recognizes projects making significant strides and leaving a lasting impact in eradicating poverty (SDG 1: No Poverty), promoting universal health and well-being (SDG 3: Good Health and Well-being), advancing gender equality (SDG 5: Gender Equality), enhancing infrastructure (SDG 9: Industry, Innovation, and Infrastructure), fostering peaceful societies (SDG 16: Peace, Justice, and Strong Institutions), and facilitating access to education. For instance, impactful initiatives may encompass empowering financial literacy programs for marginalized communities or ensuring equitable access to healthcare services.
                                            <br/> <br/>
                                            Recipients of this award are the driving force behind substantive change, reshaping the path of progress. They are bold visionaries who reject the status quo and tackle challenges head-on. This recognition sends a powerful message: progress transcends mere economic metrics; it demands that all segments of society reap the benefits. It's about empowering marginalized populations, amplifying unheard voices, and forging a future where no individual or community is left behind.
                                            <br/> <br/>
                                            Criteria: Contributions align with any of the following UN SDGs 1, 3, 5, 9, 16, and may also include 17.
                                            <br/> <br/>
                                        </p>
                                        <Button className="apply-button" variant="no_border" onClick={handleShowModal}>Apply Here ➞</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='card-container'>
                        <Col md={6}>
                            <Card className="p-3">
                                <Row className="text-left">
                                    <Col>
                                        <div className="number-circle">05</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h4>
                                            The Bunchful “Wisdom” Award
                                        </h4>
                                        <p>
                                            The Bunchful “Wisdom” Award casts a spotlight on the educators, philanthropists, and initiatives driving accessibility to quality education and technological empowerment. From programs bridging the digital divide to transformative curricula addressing entrenched inequities and community-driven projects expanding knowledge access, this award recognizes those reshaping the learning landscape.
                                            <br/> <br/>
                                            Aligned with the UN Sustainable Development Goals (SDGs), the Wisdom Award emphasizes projects advocating for inclusive education (SDG 4: Quality Education), robust infrastructure (SDG 9: Industry, Innovation, and Infrastructure), inclusive industries (SDG 11: Sustainable Cities and Communities), and peaceful, inclusive societies through global partnerships (SDG 16: Peace, Justice, and Strong Institutions). These initiatives serve as guiding lights, leading us towards a future where everyone, regardless of background, has the tools and opportunities to reach their full potential.
                                            <br/> <br/>
                                            The Wisdom Award champions a vital principle: education is not a privilege but a fundamental right for all. It envisions a world where technology serves as a bridge, dismantling barriers that have hindered too many from learning, growing, and thriving. This award celebrates those who bravely confront challenges, empowering individuals and communities to unlock their full potential.
                                            <br/> <br/>
                                            Criteria: Contributions align with any of the following UN SDGs 4, 9, 11, 16, and may also include 17.
                                        </p>
                                        <Button className="apply-button" variant="no_border" onClick={handleShowModal}>Apply Here ➞</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card className="p-3">
                                <Row className="text-left">
                                    <Col>
                                        <div className="number-circle">06</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h4>
                                            The Bunchful “Innovation” Award
                                        </h4>
                                        <p>
                                            The Bunchful “Innovation” Award spotlights cutting-edge innovations that are driving progress towards a sustainable, fair future. These groundbreaking initiatives, led by visionary individuals and organizations, are pushing boundaries and reshaping industries to align with the UN Sustainable Development Goals (SDGs).
                                            <br/> <br/>
                                            Aligned with UNSDGs 6 (Clean Water and Sanitation), 7 (Affordable and Clean Energy), 9 (Industry, Innovation, and Infrastructure), 11 (Sustainable Cities and Communities), 12 (Responsible Consumption and Production), and 17 (Partnerships for the Goals), the Innovation Award recognizes projects making tangible strides towards sustainable cities, responsible consumption, clean energy, clean water and sanitation, fostering global partnerships, and enhancing innovation and infrastructure.
                                            <br/> <br/>
                                            Award winners are the trailblazers challenging the status quo, diving headfirst into complex challenges, and empowering communities for a brighter, more sustainable future. This award isn't just about flashy technology; it's about ensuring that everyone benefits from innovation. It envisions a future where economic growth and environmental stewardship go hand in hand, where communities flourish inclusively, leaving no one behind. For instance, an individual could qualify for this award by spearheading a project that develops a low-cost, renewable energy solution for underserved communities (SDG 7), while also fostering partnerships with local governments and NGOs to implement sustainable infrastructure (SDG 9) and/or promoting responsible consumption practices within the community (SDG 12).
                                            <br/> <br/>
                                            By celebrating these innovations, the Bunchful Innovation Award sends a resounding message: progress extends beyond the development of new gadgets—it's about leveraging innovation to build a better world for everyone. It's about creating resilient, inclusive communities where progress uplifts us all.
                                            <br/> <br/>
                                            Criteria: Contributions align with any of the following UN SDGs 1, 3, 5, 9, 16, and may also include 17.
                                            <br/> <br/>
                                        </p>
                                        <Button className="apply-button" variant="no_border" onClick={handleShowModal}>Apply Here ➞</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Col md={12}>
                            <Card className="p-3">
                                <Row className="text-left">
                                    <Col>
                                        <div className="number-circle">07</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h4>
                                            The Bunchful “It's Our World” Award
                                        </h4>
                                        <p>
                                            The Bunchful “It's Our World” Award celebrates eco-champions driving tangible impacts to preserve our environment. Aligned with UN Sustainable Development Goals (SDGs), including SDG 7 (Affordable and Clean Energy), SDG 12 (Responsible Consumption and Production), SDG 13 (Climate Action), SDG 14 (Life Below Water), SDG 16 (Peace, Justice, and Strong Institutions), and SDG 17 (Partnerships for the Goals), this esteemed award highlights diverse eco-friendly projects. From safeguarding ecosystems to promoting renewable energy, responsible consumption, and sustainable governance, these visionaries tackle environmental challenges, leading the way toward a resilient future.
                                            <br/> <br/>
                                            The Bunchful It's Our World Award celebrates those who challenge norms and inspire us to reconsider our relationship with nature. It empowers individuals, businesses, and institutions to become stewards of our resources, fostering a world where environmental responsibility drives progress, equity, and collaboration. By recognizing and honoring these efforts, the award underscores our collective responsibility as a society in shaping a sustainable future, underscoring our collective responsibility as a society in shaping a sustainable future. It highlights the crucial role of environmental stewardship in propelling progress, equity, and collaboration, and empowers individuals and organizations to champion eco-friendly initiatives.
                                            <br/> <br/>
                                            Criteria: Contributions align with any of the following UN SDGs 7, 12, 13, 14, 16, and  may also include 17.
                                            <br/> <br/>
                                        </p>
                                        <Button className="apply-button" variant="no_border" onClick={handleShowModal}>Apply Here ➞</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                </Container>
            </div>

            <div className="section-7" id="timeline">
                <h2 className='heading' style={{paddingBottom: "5%"}}>
                    Timeline For The Bunchful Awards
                </h2>

                    <VerticalTimeline>
                        <VerticalTimelineElement
                            contentStyle={{boxShadow: "none", border: "5px solid #332b78"}}
                            contentArrowStyle={{ display: "none" }}  // Remove the arrow
                            date="April"
                            iconStyle={{boxShadow: "none", background: '#332b78', color: '#fff' }}
                            icon={<FontAwesomeIcon icon={faCalendar} />}
                        >
                            <div>
                                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                    <img src={logo_5} style={{ width: '30px', height: 'auto' }} alt="SDG Logo" />
                                </div>
                                <h3 className='heading' style={{ color: "black", fontSize: "1.2rem", textAlign: "left" }}>SDG</h3>
                                <p className="some-text text-muted">for Annual Award Announced</p>
                            </div>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                            contentStyle={{boxShadow: "none", border: "5px solid #332b78"}}
                            contentArrowStyle={{ display: "none" }}  // Remove the arrow
                            date="May"
                            iconStyle={{boxShadow: "none", background: '#332b78', color: '#fff' }}
                            icon={<FontAwesomeIcon icon={faCalendar} />}
                        >
                            <div>
                                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                    <img src={logo_6} style={{ width: '30px', height: 'auto' }} alt="Nominations Logo" />
                                </div>
                                <h3 className='heading' style={{ color: "black", fontSize: "1.2rem", textAlign: "left" }}>Nominations</h3>
                                <p className="some-text text-muted">Open Call</p>
                            </div>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                            contentStyle={{boxShadow: "none", border: "5px solid #332b78"}}
                            contentArrowStyle={{ display: "none" }}  // Remove the arrow
                            date="June"
                            iconStyle={{boxShadow: "none", background: '#332b78', color: '#fff' }}
                            icon={<FontAwesomeIcon icon={faCalendar} />}
                        >
                            <div>
                                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                    <img src={logo_7} style={{ width: '30px', height: 'auto' }} alt="Nominations Close Logo" />
                                </div>
                                <h3 className='heading' style={{ color: "black", fontSize: "1.2rem", textAlign: "left" }}>Nominations Close</h3>
                                <p className="some-text text-muted">Judges panel seated</p>
                            </div>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                            contentStyle={{boxShadow: "none", border: "5px solid #332b78"}}
                            contentArrowStyle={{ display: "none" }}  // Remove the arrow
                            date="July"
                            iconStyle={{boxShadow: "none", background: '#332b78', color: '#fff' }}
                            icon={<FontAwesomeIcon icon={faCalendar} />}
                        >
                            <div>
                                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                    <img src={logo_8} style={{ width: '30px', height: 'auto' }} alt="Media Logo" />
                                </div>
                                <h3 className='heading' style={{ color: "black", fontSize: "1.2rem", textAlign: "left" }}>Media</h3>
                                <p className="some-text text-muted">Spotlights for nominees</p>
                            </div>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                            contentStyle={{boxShadow: "none", border: "5px solid #332b78"}}
                            contentArrowStyle={{ display: "none" }}  // Remove the arrow
                            date="August"
                            iconStyle={{boxShadow: "none", background: '#332b78', color: '#fff' }}
                            icon={<FontAwesomeIcon icon={faCalendar} />}
                        >
                            <div>
                                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                    <img src={logo_9} style={{ width: '30px', height: 'auto' }} alt="Judges Logo" />
                                </div>
                                <h3 className='heading' style={{ color: "black", fontSize: "1.2rem", textAlign: "left" }}>Judges's</h3>
                                <p className="some-text text-muted">Panel Seated</p>
                            </div>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                            contentStyle={{boxShadow: "none", border: "5px solid #332b78"}}
                            contentArrowStyle={{ display: "none" }}  // Remove the arrow
                            date="September"
                            iconStyle={{boxShadow: "none", background: '#332b78', color: '#fff' }}
                            icon={<FontAwesomeIcon icon={faCalendar} />}
                        >
                            <div>
                                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                    <img src={logo_10} style={{ width: '30px', height: 'auto' }} alt="Bunchful Logo" />
                                </div>
                                <h3 className='heading' style={{ color: "black", fontSize: "1.2rem", textAlign: "left" }}>Bunchful</h3>
                                <p className="some-text text-muted">Awards & Summit Goes Live</p>
                            </div>
                        </VerticalTimelineElement>
                    </VerticalTimeline>
            </div>


            <div className='section-8' id = "requirements">
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <h1 className='heading'>
                                Nominee Requirements
                            </h1>

                            <p className='text'>
                                The Bunchful Awards seeks to recognize individuals, groups, organizations, and businesses in nine award categories for their efforts in contributing to the UN Sustainable Development Goals (SDGs). Nominations are open worldwide, and the application process is conducted via our online form. Nominees have the chance to receive recognition, be featured, and gain visibility on websites, digital properties and elsewhere, including across social media platforms.
                                <br/> <br/>
                                Nominators are encouraged to provide a concise explanation for their choice of nominee and include any relevant links or supporting evidence, if applicable. Nominees may not be a sponsor of Bunchful events during the time of their nomination consideration.
                            </p>

                            <Button className='nominate-someone-button' onClick={handleShowModal}>
                                Nominate Someone
                            </Button>
                        
                        </Col>

                        <Col xs={12} md={6}>
                            <img src={third_image} className="img-fluid" />
                        </Col>

                    </Row>

                </Container>
            </div>

            <div className='section-9'>
                <Container>
                    <h1 className='heading' style={{fontSize: "2.3rem", paddingLeft: "10%", paddingRight: "10%"}}>
                        Nominate yourself, an individual, a business, organization or group
                    </h1>

                    <div className="button-container">
                        <Button  className='past-winners-button' href='/awardees'>
                            View Our Past Winners
                        </Button>
                        <Button className='nominate-yourself-or-someone'onClick={handleShowModal}>
                            Nominate Yourself or Someone
                        </Button>
                    </div>
                </Container>
            </div>


            <div>
                <SponsorshipDeck/>
            </div>

            <div>
                <Footer/>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} dialogClassName="popup-modal">
                <Modal.Header style={{backgroundColor: "#5050f3", color: "white", textAlign: "center"}} closeButton>
                    <Modal.Title>Bunchful Awards Application</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: "#5050f3"}}>
                    <p style={{color: "white"}}>Applications will open 12PM Eastern Standard Time on May 30th, 2025. Join the mailing list for important notifications.</p>
                    <Form style={{boxShadow: "none", backgroundColor: "#5050f3"}} onSubmit={handleSubmit}>
                        <Form.Group controlId="formFirstName">
                        <Form.Label style={{color: "white"}}>First Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter your first name" 
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)} // Set first name
                        />
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                        <Form.Label style={{color: "white"}}>Last Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter your last name" 
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)} // Set last name
                        />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                        <Form.Label style={{color: "white"}}>Email</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="Enter your email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} // Set email
                        />
                        </Form.Group>
                        <Button style={{borderRadius: "0px", backgroundColor: "#332b78", border: "0"}}variant="primary" type="submit" className="submit-button">
                        Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>

    );
}

export default Awards;
