import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Helmet } from "react-helmet"; // Import react-helmet
import "./homePage.css"; // Ensure the CSS file name matches
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Testimonials from "../../Components/Testimonials/testimonials";
import TechnologyCard from "../../Components/Tech/technologyCard"; // Import the TechnologyCard component
import Media from "../../Components/Media/mediaCard"; // Import the MediaCard component
import Events from "../../Components/Events/eventCard"; // Event Cards

// Image imports
import homepageTopImage from '../../images/homepage/homepage-top.webp'; 
import partnershipImage from '../../images/general/17-partnership.webp';
import sdgImage from "../../images/homepage/sdgs.webp";
import ExampleImage4 from "../../images/homepage/testimonial-section/woman.webp"; 
import ExampleImage5 from "../../images/homepage/homepage-end.webp"; 

import videoFile from "../../images/video/bunchfulop.mp4";

const Homepage = () => {
  return (
    <div className="homepage">
      {/* Helmet for meta tag */}
      <Helmet>
        <meta name="fo-verify" content="4114b933-82cb-42d6-9fae-7303e01019a3" />
      </Helmet>
      {/* Header */}
      <Header /> 
      {/* Section 1 */}
      <div className="section-1 bg-light py-5">
        <Container fluid>
          <Row className="align-items-center text-center text-md-start">
            <Col md={6} className="d-flex flex-column">
              <div className="bunchful-social">BUNCHFUL SOCIAL ENTERPRISE</div>
              <div className="heading mb-4">
                <div className="maximize-impact">
                  Maximize Impact.
                  <br />
                  Drive Growth.
                </div>
              </div>
              <p className="bunchful-magnifies mb-4">
                Bunchful AI shifts the conversation to focus the Bunchful Spotlight on all the ways you give back, elevating your business, increasing your reach and gaining new clients.
                <br/> <br/> 
                *Not all businesses may qualify for the Bunchful Spotlight.
              </p>
              <div className="button-container d-flex gap-3">
                <Button variant="outline-primary" href="/contact" className="link-2">
                  Contact Us
                </Button>
                <Button variant="primary" href="/sponsor" className="link-3">
                  Become a Sponsor
                </Button>
              </div>
            </Col>
            <Col md={6} className="order-md-1 d-flex justify-content-center">
              <img 
                src={homepageTopImage} 
                className="img-fluid" 
                alt="people working" 
                style={{ width: '100%', height: 'auto', maxHeight: '400px', maxWidth: '600px' }} 
              />
              
            </Col>
          </Row>
        </Container>
      </div>

      {/* Section 2 */}
      <div className="section-2">
        <div className="background d-flex flex-column align-items-center justify-content-center text-center">
          <div className="heading">
            <p className="would-you-like-to">
              Would you like to help drive achievements of the United Nations Sustainable Development Goals (SDGs)?
            </p>
          </div>
          <div className="content-container">
            <p className="do-you-know-how-your">
              Do you know how your acts of giving are contributing to the achievement of the SDG? Would you like to step forward and let your charitable actions inspire others to join in creating a better world? Bunchful Enterprise platform is driving achievement of the global goals, which were created as a blueprint for a better, more sustainable world. Join us in our mission.
            </p>
          </div>
          <div className="group-png">
            <img src={sdgImage} alt="UN SDGs" className="img-fluid" />
          </div>
          <div className="text-container">
            <p className="text text-wrapper">
              Bunchful supports the achievement of all SDGs, with our main focus on SDG 17, for advancing global partnerships.
            </p>
          </div>
        </div>
      </div>

      <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", width: "100%"}}></div> {/* Separator Line */}


      {/* Section 3 */}
      <div className="section-3 py-5" style={{marginLeft: "2%", marginRight: "2%"}}>
        <Container fluid>
          <Row>
            <Col md={3}>
              <h1 className="global-initiative">Bunchful Global Initiative</h1>
              <div className="heading-button-container">
                <Button variant="custom" href="/gambia-school-project" className="btn-custom">
                  Drive Impact
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <p className="text-dark mb-4 larger-text">
                To <span style={{fontStyle: "italic"}}>"be bunchful"</span> is to share your gifts and abundance with others.
              </p>
              <p className="text-secondary">
                As a Socially Responsible Organization, Bunchful Enterprises aims to enhance the work of the giving community via our services, events, and innovative technologies. We help to maximize the work of givers and the transformative impact of their gifts to the community, inspiring the growth of philanthropy as a global way of living, while reinforcing the UN Sustainable Development Goals (UN SDGs), a shared blueprint for a better and more sustainable future for all.
              </p>
            </Col>
            <Col md={3} className="d-flex justify-content-center align-items-center">
              <img src={partnershipImage} alt="SDG 17" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Section 4 (YouTube Video) */}
      <div className="section-4">
        <Container>
          <Row>
            <Col md={12}>
              <div className="embed-responsive embed-responsive-1by1 video-container w-100">
                <video className="frame embed-responsive-item w-100" controls autoPlay muted>
                  <source src={videoFile} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Technology Cards Section */}
      <div className="technology-section">
        <TechnologyCard />
      </div>

      <div className="separator" style={{height: "1px", backgroundColor: "#c2c2c2", margin:"20px 0px", width: "100%"}}></div> {/* Separator Line */}

      {/* Event Card Section */}
      <div className="events-section">
        <Events />
      </div>

      {/* Media Cards Section */}
      <div className="media-section">
        <Media />
      </div>

      {/* Testimonials Section */}
      <div className="testimonial-section">
        <Container>
          <Row>
            <Col className="woman-pic" xs={12} s={12} md={6} lg={6}>
              <img src={ExampleImage4} alt="Woman Testimonial" />
            </Col>
            <Col xs={12} s={12} md={6} lg={6}>
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Donation Section */}
      <div className="donation-section bg-light py-5">
        <Container>
          <Row className="align-items-center">
            <Col md={5} className="d-flex flex-column align-items-center">
              <h2 className="highlight-text text-center" style={{fontSize: "2.5rem", paddingBottom: "5%"}}>
                Join us in supporting our{" "}
                <span className="highlight">charitable</span> beneficiaries
              </h2>
              <a href="https://www.gofundme.com/f/Gambia-Education-Fund-By-Bunchful" target="_blank" rel="noopener noreferrer">
                <Button className="donate-button" variant="primary">
                  Donate
                </Button>
              </a>
            </Col>
            <Col md={6} className="d-flex justify-content-center">
              <img src={ExampleImage5} alt="Volunteers with Aid Boxes" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Homepage;
