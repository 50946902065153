import React, { useState } from 'react';
import Header from "../../Components/Header/header";
import { Helmet } from "react-helmet"; // Import react-helmet
import "./aboutPage.css";
import Footer from "../../Components/Footer/footer";
import QuoteCarousel from "../../Components/QuoteCarousel/QuoteCarousel";
import SponsorshipDeck from "../../Components/SponsorshipDeck/sponsorship";
import Banner from "../../Components/Banner/banner";
import bannerImage from "./backgroundAbout.svg";
import exampleImage from "../../images/homepage/about-page/about-end.webp";
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import aboutTopImage from "../../images/homepage/about-page/about-top.webp";
import rM from "../../images/homepage/about-page/raquel-miller.webp";
import tL from "../../images/homepage/about-page/team-members/thomas-laub.webp";
import jW from "../../images/homepage/about-page/team-members/jordan-whitewick.webp";
import cK from "../../images/homepage/about-page/team-members/ambassador-cheick-keita.webp";
import zK from "../../images/homepage/about-page/team-members/zeenat-khanche.webp";
import lV from "../../images/homepage/about-page/team-members/levar-jackson.webp";
import jI from "../../images/homepage/about-page/team-members/dr.joseph-ikhalia.webp";
import jS from "../../images/homepage/about-page/team-members/jessica-sophiawong.webp";
import dR from "../../images/homepage/about-page/team-members/dylan-rachtomo.webp";
import iH from "../../images/homepage/about-page/team-members/ih.webp";
import aK from "../../images/homepage/about-page/team-members/abhinay-kambha.webp";
import dD from "../../images/homepage/about-page/team-members/diva-diaz.webp";
import mM from "../../images/homepage/about-page/team-members/mm.webp";

const AboutPage = () => {

  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showSection2Modal, setShowSection2Modal] = useState(false);

  const handleShowModal = (card) => {
    setSelectedCard(card);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCard(null);
  };

  const handleShowSection2Modal = () => {
    setShowSection2Modal(true);
  };

  const handleCloseSection2Modal = () => {
    setShowSection2Modal(false);
  };

  const cardData = [
    {
      name: "THOMAS LAUB",
      title: "President | Chief Strategy & Finance Officer",
      description: "With two decades of financial services expertise, including a remarkable 17-year tenure at Goldman Sachs.",
      detailedDescription: "Thomas Laub is a distinguished leader and financial strategist at Bunchful Enterprise, renowned for seamlessly integrating cutting-edge technology with robust financial strategies. His expertise covers complex budgeting, precise forecasting, and the management of significant financial transactions. Thomas’s strategic prowess has consistently propelled organizations towards sustained growth and long-term prosperity. Under his leadership, Thomas has transformed financial and operational frameworks to enhance performance metrics and streamline processes. His approach extends beyond meeting targets to redefining them, embarking on projects that ensure transformative achievements and continuous advancement. Thomas’s legacy is built on a foundation of innovation, strategic problem-solving, and a steadfast commitment to excellence. His influence within any organization acts as a catalyst for growth, steering ventures towards unparalleled prosperity. With Thomas at the helm, success is not merely a destination, but a journey of extraordinary achievements. His financial expertise and strategic insights keep Bunchful Enterprise at the forefront of the industry, marking him as a visionary in financial services.",
      image: tL, 
    },
    {
      name: "JORDAN WHITEWICK",
      title: "Director | IT",
      location: "Montreal",
      description: "Jordan is a driven IT professional with over 14 years experience and knowledge of complex infrastructure and systems",
      detailedDescription: "Jordan is a driven IT professional with over 11 years experience and knowledge of complex infrastructure and systems in high tech within different industries, such as Finance, Manufacturing Distribution, Legal and SaaS. Providing leadership for the continued development of innovative, robust, and secure information technology environment throughout the organization. Jordan delivers a robust IT infrastructure and security system adhering to compliance standards within North America and Europe.",
      image: jW,
    },
    {
      name: "AMBASSADOR CHEICK KEITA",
      title: "Global Collaborative Ventures",
      location: "Paris",
      description: "After 7 years of service spent at the international IHRC Human Rights Commission, Ambassador Cheick Keita is now Ambassador of WUSME,",
      detailedDescription: "Ambassador Cheick Keita, is a Parisian resident and esteemed guest speaker, showcasing a profound expertise in public law, enterprise, and African markets. His tenure includes esteemed roles at Crédit Agricole, BBVA Bank, and the OECD, coupled with multifaceted involvement in Africa's economic growth. As the founder and president of CIDIGA, he leads a think tank dedicated to propelling investment research for Africa, nurturing its development and future prospects.",
      image: cK,
    },
    {
      name: "ZEENAT KHANCHE",
      title: "Comms Strategy Adviser",
      location: "London",
      description: "Zeenat is a diplomat, international strategist and campaigns expert, who has worked in a number of countries, notably for the British Embassy in Moscow.",
      detailedDescription: "Zeenat is an international strategy & campaigns expert, who has worked in a number of countries over the past two decades. Most recently, as Head of Communications at the British Embassy in Moscow, she led on developing people-to-people connections. She has previously served (as a diplomat) in Iraq, New York, India and Hong Kong. She has also been seconded as a communications advisor to the governments of Turkey and Kosovo. As a consultant, she has worked on EU and UNDP projects. She is currently London-based, working on human rights and diversity issues.",
      image: zK,
    },
    {
      name: "LEVAR JACKSON",
      title: "Events Director",
      location: "New York",
      description: "Levar Jackson is a Brooklyn native and tenured hospitality industry expert.",
      detailedDescription: "Levar Jackson is a Brooklyn native and tenured hospitality industry expert with a specialty in sophisticated hotel brands, producing over 50 industry events over the years.",
      image: lV,
    },
    {
      name: "DR. JOSEPH IKHALIA",
      title: "Cybersecurity Architectural Advisor",
      location: "Isle of Man",
      description: "Dr. Joseph E. Ikhalia is an Information Security Scientist and Strategic Leader specializing in cybersecurity, risk management, and secure software design, with experience in high-profile financial institutions and pioneering security initiatives.",
      detailedDescription: "Dr. Joseph E. Ikhalia is an accomplished Information Security Scientist and Strategic Leader with extensive experience in cybersecurity, risk management, and secure software design. Currently working with a high-profile financial institution in the Isle of Man, he has a proven track record of enhancing cybersecurity resilience across various industries. Previously, Joseph served as a Senior Cyber Security Engineer at Riela Cybersecurity Centre Limited, where he played a key role in achieving ISO 27001 accreditation for The Riela Group, reinforcing their commitment to information security. His expertise spans malware analysis, penetration testing, risk and threat management, and security architecture. Before that, he was IT Security Manager at Quanta Technology Limited, leading cybersecurity initiatives for the world’s first fully licensed Blockchain lottery. Recognized for his proficiency in smart contract security, cloud security, and incident response, Joseph has significantly contributed to the advancement of secure digital solutions. He holds a Ph.D. in Information Systems and Computing, a Master's in Information Technology, and a degree in Electrical & Electronics Engineering. A recognized innovator, Joseph developed the world’s first social network security awareness application and was honored by the UK government through the prestigious HutZero cybersecurity program.",
      image: jI,
    },
    {
      name: "IVAN HUANG",
      title: "Project Coordinator",
      location: "New York",
      description: "Ivan Huang is a Mechanical Engineering student and seasoned Project Coordinator at Bunchful Enterprise, known for his strong technical credentials and proven track record in driving innovative, AI-driven projects to successful outcomes.",
      detailedDescription: "Ivan Huang is currently a student studying Mechanical Engineering, passionate about problem-solving and innovation. Inspired by Walt Disney’s words— “We keep moving forward, opening new doors, and doing new things because we’re curious, and curiosity keeps leading us down new paths”—Ivan embraces curiosity as a driving force in his career. Currently, Ivan serves as a Project Coordinator at Bunchful Enterprise, where he has been contributing for over two years. Based remotely in New York, NY, he plays a key role in coordinating cross-functional teams and managing AI-driven projects. His work involves ensuring smooth project execution, tracking progress, and providing senior management with regular updates and detailed reports. With a strong foundation in mechanical engineering and a growing expertise in AI project management, Ivan is dedicated to leveraging technology to drive innovation and meaningful impact.",
      image: iH,
    },
    {
      name: "JESSICA SOPHIA WONG",
      title: "Strategic Partnerships",
      location: "New York",
      description: "Jessica is an Executive Member at the United Nations Association of the United States of America and has an extensive background working with startups and Web3.",
      detailedDescription: "Jessica was one of the presenters at the 2022 Bunchful Future of Philanthropy Summit, a live event and introduction of the Bunchful Awards and will be recognized among her cohort at the 2023 Awards event. As a digital nomad, minority female founder and woman in tech, Jessica actively advocates for equality and women’s empowerment. She serves on the Marketing Committee for The Mayor’s Residence, New York City, Gracie Mansion Conservancy, and has previously served on planning committees for Forbes Council and the LLS Men of the Year Running Campaign. She uses her platform for positive change, combining her career in interior design and marketing consulting with her passion for environmental impact.Jessica is the founder and CEO of Yorkseed, a global venture capital community for founders and investors. She also co-founded an Earth Day fundraiser campaign in New York City, hosts successful charity events, and has been recognized for her philanthropic efforts. She is an emerging leader and a fund raiser for the National Resources Defense Council and The Conservation Fund.Jessica is also involved in startups and Web3, enjoys hosting events and solo traveling, and is currently working on projects related to mental health support and affordable housing in New York City. She is dedicated to making a positive impact on society and the world through her work in startups, marketing strategy, investor relations, entrepreneurship, event management, nonprofit partnerships, business development, relationship building, corporate branding, and strategic planning.",
      image: jS,
    },
    {
      name: "DIVA DIAZ",
      title: "Executive Assistant",
      description: "Diva comes with 12 years of experience as an executive assistant with many different fortune 500 companies.",
      detailedDescription: "Diva comes with 12 years experience as an executive assistant to CEO,s CFO, and General Counsels. Diva has worked for companies like Amazon, CVS Health, and Cigna in many different levels as an executive assistant. Aside from being a mom of 3 and a wife, Diva enjoys working out and eating healthy. ",
      image: dD,
    },
    {
      name: "ABHINAY KAMBHAMMETTU",
      title: "Cybersecurity Lead",
      description: "Abhinay Kambhammettu, a cybersecurity enthusiast and graduating senior at Rutgers New Brunswick (May 2025), is passionate about securing digital systems, leading risk management efforts at Bunchful, and planning to pursue a master's in cybersecurity to deepen his expertise in threat intelligence and security architecture.",
      detailedDescription: "Abhinay Kambhammettu is a cybersecurity enthusiast and a graduating senior at Rutgers New Brunswick, set to earn his degree in May 2025. He's always been fascinated by how systems work and more importantly, how they can be secured. With a sharp eye for vulnerabilities and a passion for problem-solving, he's dedicated to mitigating risks and strengthening digital defenses. As a Cybersecurity Lead here, Abhinay is diving deep into risk management and fortifying our defenses, gaining hands-on experience that challenges him to think critically and stay ahead of emerging threats. He’s just as interested in the strategic side of cybersecurity as he is in the technical aspects, always looking for ways to help Bunchful safeguard its assets effectively and efficiently. Next up? A master’s in cybersecurity, where he’ll sharpen his expertise in threat intelligence and security architecture. His goal is to build more resilient security systems and play a role in protecting Bunchful from evolving cyber threats. Exciting things ahead, stay tuned!",
      image: aK,
    },
    {
      name: "DYLAN RACHTOMO",
      title: "Strategy & Business Development Lead",
      location: "Singapore",
      description: "Having lived in 3 different countries before, I eventually graduated from an American international school in Singapore, where I pursued the International Baccalaureate with a focus on economics-related subjects.",
      detailedDescription: "Having lived in 3 different countries before, I eventually graduated from an American international school in Singapore, where I pursued the International Baccalaureate with a focus on economics-related subjects. Currently, I am a business studies student at Pace University, specializing in finance as I weigh my longer-term aspirations in mergers and acquisitions - debating whether to pursue a career path in investment banking or corporate law. Alongside my academics and work for Bunchful, I am also involved in fundraising for an educational nonprofit, honing my leadership abilities for impactful causes. My career at Bunchful Enterprise began with a 3-month finance internship, where I gained invaluable hands-on experience before eventually being promoted to my current leadership role. During the internship, I demonstrated a keen ability to think strategically and a tenacious work ethic, qualities that put me on the leadership track overseeing the company's strategy and business development efforts. When I'm not developing directives to drive Bunchful's growth or exploring potential revenue opportunities, you'll likely find me indulging my love of sports and culinary adventures. An avid athlete, I make fitness a priority through daily gym sessions and martial arts training in kickboxing and Muay Thai. In my free time, I also satisfy my epicurean curiosities by savoring fine dining and wine-tasting experiences at Michelin-starred restaurants around the world!",
      image: dR,
    },
    {
      name: "MUKTIKA MANOHAR",
      title: "Data Science Lead",
      description: "Muktika Manohar is a Data Science Lead specializing in Generative AI and Large Language Models, focusing on multi-agent AI architectures, RAG, and adaptive AI memory to enhance automation and user interactions.",
      detailedDescription: "Muktika Manohar is deeply fascinated by the ever-evolving world of AI and its potential to reshape technology. She believes that technology should enhance human creativity and decision-making, and she enjoys exploring new ways to make AI more adaptive, efficient, and impactful. As a Data Science Lead at Bunchful Enterprise, Muktika focuses on Generative AI and Large Language Models (LLMs), building intelligent systems that automate workflows and enhance user interactions. She works on multi-agent AI architectures, retrieval-augmented generation (RAG), and adaptive AI memory, ensuring seamless and scalable solutions. Muktika is committed to pushing the boundaries of AI innovation, leveraging her expertise in machine learning and AI-driven automation. She thrives in dynamic environments where creativity and technology come together to solve real-world challenges.",
      image: mM,
    }
  ];

  return (
    <div className="aboutPage">

      <div>
            <Helmet>
                <title>About - Bunchful | The Generosity Business | Official Site</title>
            </Helmet>
      </div>

      <Header/>

      <div>
        <Banner
          homeLink="Home"
          breadcrumb="About"
          title="About"
          backgroundImage={bannerImage}
          />
      </div>

      <div className="section-1">
        <Container fluid>
          <Row className="align-items-center justify-content-center text-center text-md-start">
            <Col xs={12} md={6}>
              <img src={aboutTopImage} className="img-fluid mb-4" alt="About Bunchful" />
            </Col>
            <Col xs={12} md={6}>
              <div className="about-bunchful mb-4">ABOUT BUNCHFUL</div>
              <div className="heading mb-4">
                <div className="empowering-communities">
                  Empowering Communities, Changing Tomorrow
                </div>
              </div>
              <p className="let-bunchful mb-4">
                <strong>Let Bunchful shine a spotlight on your giving</strong> to help create a more compassionate world. 
                Showcase your community contributions with the Bunchful Atlas, 
                celebrate exceptional individuals and businesses through our Awards, 
                and network and explore the Future of Philanthropy at our inspiring World Summit. 
                Make meaningful gift choices with the help of the Bunchful Gifting Salon 
                and gain insights from renowned experts on the Bunchful Podcast.
                <br/>
                <br/>
                Stay informed with Bunchful News, and together, let's build a sustainable and inclusive 
                future aligned with the United Nations Sustainable Development Goals. 
                Join our mission to foster a culture of giving and make a difference in creating a more compassionate world.
              </p>
              <div className="d-flex gap-5 justify-content-center justify-content-md-start">
                <Button variant="primary" href="/contact" className="link-2">
                  Contact Us
                </Button>
                <Button variant="outline-primary" href="/sponsor" className="link-1">
                  Become a Sponsor
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section-2">
        <Container fluid>
          <Row className="align-items-center justify-content-center text-center text-md-start">
            <Col xs={12} md={7} className="mb-4">
              <div className="from mb-4">FROM</div>
              <div className="heading mb-4">
                <div className="founder">
                  Bunchful Founder & CEO
                </div>
              </div>
              <p className="i-believe mb-4">
                “I believe that one of the central purposes of life is to give, to give with great abundance and
                love, to give knowing that what we give comes back to us, manifold, to give because our lives
                depend on it, to give to ourselves and to give to others, realizing the impact we can make in
                creating thriving communities that underpin our collective growth.”
                <br/>
                <br/>
                Growing up in the Caribbean, I experienced profound lessons in generosity that have shaped the
                path of my life. I grew up with my grandparents in a small farming town in Jamaica, and every
                day, my grandmother prepared meals for my elderly Uncle Enjin, who lived alone across the
                street. Uncle Enjin's house was small, but sat on a large plot of land surrounded by vegetation.
                He was disabled and his movements limited to pulling his body along the small footprint of his
                house, a house that stood high on a platform above the ground, so that at 4 or 5 years old, I
                could not reach to touch his outstretched hand, when I would grudgingly accompany my
                grandmother with breakfasts in the early mornings.
              </p>
              <div className="button-container d-flex justify-content-center justify-content-md-start">
                <Button style={{ border: 0 }} variant="outline-primary" className="small-purple-button" onClick={handleShowSection2Modal}>
                  More Info
                </Button>
              </div>
            </Col>
            <Col xs={12} md={5} className="text-center">
              <img src={rM} className="img-fluid mb-3" alt="Raquel Miller" />
              <h5 className="person-name">RAQUEL MILLER</h5>
              <p className="person-title">Founder & CEO | Bunchful Enterprise</p>
            </Col>
          </Row>
        </Container>
      </div>


      {/* Section 3: Team Cards */}
      <div className="section-3" id='team'>
        <div className="text-wrapper">THE EXECUTIVE TEAM</div>
        <div className="heading-meet-our">Meet Our Team Members</div>
        <Container>
          <Row>
            {cardData.map((card, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <Card className="card" style={{ width: '100%' }}>
                  <Card.Img className="card-img-top" src={card.image} alt={card.name} />
                  <Card.Body className="card-body text-center">
                    <Card.Title>{card.name}</Card.Title>
                    <Card.Text className="card-text">
                      <strong>{card.title}</strong>
                      <br />
                      {card.location && <em>{card.location}</em>}
                      <br />
                      {card.description}
                    </Card.Text>
                    <Button className="btn" variant="primary" onClick={() => handleShowModal(card)}>
                      More Info
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>


      {/* Modal for Section 2 */}
      <Modal show={showSection2Modal} onHide={handleCloseSection2Modal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Raquel Miller</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Raquel Miller is the visionary founder of Bunchful, a platform that honors and celebrates giving. Her journey began in a Jamaican farming town, where she learned the values of generosity and community from her grandmother and later through her father. Their acts of kindness and compassion inspired her to share abundance with others and create a sense of purpose and belonging.
          Raquel moved to New York and launched Bunchful, a platform that connects givers and receivers across the world. She coined the term “bunchful” to describe the spirit of generosity that drives her mission. She believes that by honoring and supporting givers, we can make a positive impact and improve lives worldwide.
        </Modal.Body>
        <Modal.Footer>
          <Button style= {{backgroundColor: "#5050f3", width: "auto"}} variant="primary" onClick={handleCloseSection2Modal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Team Cards */}
      {selectedCard && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedCard.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Title:</strong> {selectedCard.title}</p>
            {selectedCard.location && <p><strong>Location:</strong> {selectedCard.location}</p>}
            <p><strong>Description:</strong> {selectedCard.detailedDescription || selectedCard.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button style= {{backgroundColor: "#5050f3", width: "auto"}}  variant="primary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <div>
        <QuoteCarousel/>

      </div>

      <div style={{paddingBottom: "5%"}}>
        <SponsorshipDeck/>
      </div>

      <div className='banner-2-section'>
          <Container>
              <Row className="row-1 position-relative">
                <Col xs={12} md={6}>  
                  <p className='heading'>
                    Become a Bunchful Ambassador
                  </p>
                  <div className='button-container'>
                    <Button className='apply-button' href="/careers">
                      Join Here
                    </Button>
                  </div>
                  </Col>

                  <Col xs={12} md={6} className="position-relative">
                    <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                  </Col>
              </Row>
            </Container>
          </div>

    <Footer/>
  </div>

  );
};

export default AboutPage;