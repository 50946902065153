import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Helmet } from "react-helmet"; // Import react-helmet
import Footer from "../../Components/Footer/footer";
import Header from "../../Components/Header/header";
import mscoolie from "../../images/homepage/about-page/grandmother-mscoolie/grandma-coolie.webp";
import statue1 from "../../images/homepage/about-page/grandmother-mscoolie/statue-2.webp";
import statue2 from "../../images/homepage/about-page/grandmother-mscoolie/statue-1.webp";
import "./grandmaCoolie.css";
import { db } from '../../config/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods

const GrandmaCoolie = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [firstName, setFirstName] = useState(''); // State for first name
  const [lastName, setLastName] = useState(''); // State for last name
  const [email, setEmail] = useState(''); // State for email

  const handleShow = () => setShowPopup(true);
  const handleClose = () => setShowPopup(false);

  // Handle form submission for Bunchful Awards application
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'bunchfulAwardsApplications'), {
        firstName,
        lastName,
        email,
      });
      alert('Application submitted successfully!');
      setFirstName(''); // Clear form fields
      setLastName('');
      setEmail('');
      handleClose(); // Close the modal after submission
    } catch (error) {
      console.error('Error submitting application:', error);
    }
  };

  return (
    <div className='grandma-coolie'>

      <div>
        <Helmet>
          <title>Grandmother Ms. Coolie - Bunchful | The Generosity Business | Official Site</title>
        </Helmet>
      </div>

      <Header />

      <div className='section-1'>
        <Container fluid>
          <Row>
            <Col className='col-1' xs={12} sm={12} md={6}>
              <img 
                src={mscoolie}
                className="mscoolie_img img-fluid"
                alt="Grandma Coolie portrait"
                width="80%"
              />
            </Col>
            <Col>
              <h1 className='title'>
                Grandma Coolie
              </h1>
              <p className='text'>
                Grandma Coolie, a remarkable woman known for her immense kindness and generosity, 
                deeply influenced her granddaughter, Raquel Miller. Inspired by her grandmother's 
                spirit of giving and strong belief in the power of community, Raquel founded Bunchful Enterprise. 
                This initiative serves as a platform to honor her grandmother's legacy of benevolence, encouraging 
                and facilitating philanthropy to make a meaningful difference in the world.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='section-2'>
        <Row className="justify-content-center">
          <Col className='col-1 d-flex flex-column align-items-center' xs={12} sm={12} md={4}>
            <img 
              src={statue1}
              className="statue1_img img-fluid"
              alt="Statue honoring Grandma Coolie"
              width="60%"
            />
          </Col>
          <Col className='col-2 d-flex flex-column align-items-center text-center' xs={12} sm={12} md={4}>
            <h1 className='title'>
              <span className="highlight-blue">Continuing a Legacy of Generosity:</span> Nominate for the Bunchful Awards
            </h1>
            <p className='text'>
              Moved by her grandmother's boundless generosity, Raquel Miller founded Bunchful. 
              In tribute, she established the Bunchful Awards, complete with a statue honoring her grandmother's 
              legacy. Nominate a changemaker or apply today to continue this legacy of giving.
            </p>
            <Button type="submit" className="btn-custom" onClick={handleShow}>Nominate</Button>
          </Col>
          <Col className='col-3 d-flex flex-column align-items-center' xs={12} sm={12} md={4}>
            <img 
              src={statue2}
              className="statue1_img img-fluid"
              alt="Another statue honoring Grandma Coolie"
              width="60%"
            />
          </Col>
        </Row>
      </div>

      <Footer />

      <Modal show={showPopup} onHide={handleClose} dialogClassName="popup-modal">
        <Modal.Header style={{backgroundColor: "#5050f3", color: "white", textAlign: "center"}} closeButton>
          <Modal.Title>Bunchful Awards Application</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: "#5050f3"}}>
          <p style={{color: "white"}}>Applications will open 12PM Eastern Standard Time on May 30th, 2025. Join the mailing list for important notifications.</p>
          <Form style={{boxShadow: "none", backgroundColor: "#5050f3"}} onSubmit={handleSubmit}>
            <Form.Group controlId="formFirstName">
              <Form.Label style={{color: "white"}}>First Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your first name" 
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)} // Set first name
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label style={{color: "white"}}>Last Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your last name" 
                value={lastName}
                onChange={(e) => setLastName(e.target.value)} // Set last name
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label style={{color: "white"}}>Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Set email
              />
            </Form.Group>
            <Button style={{borderRadius: "0px", backgroundColor: "#332b78", border: "0"}}variant="primary" type="submit" className="submit-button">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GrandmaCoolie;
