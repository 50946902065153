import React, { useState } from 'react';
import './contact.css';
import { Helmet } from 'react-helmet';
import Footer from "../../Components/Footer/footer";
import Header from "../../Components/Header/header";
import Banner from "../../Components/Banner/banner";
import bannerImage from "./backgroundContact.svg";
import SponsorshipDeck from '../../Components/SponsorshipDeck/sponsorship';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { db } from '../../config/firebase';
import { collection, addDoc } from 'firebase/firestore';

const Contact = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contactMessages"), { name, email, message });
      setStatus('Thank you for your message! We will get back to you soon.');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error("Error submitting message: ", error);
      setStatus('There was an error submitting your message. Please try again.');
    }
  };

  
  return (
    <div className="contact">

      <Helmet>
        <title>Contact - Bunchful | The Generosity Business | Official Site</title>
      </Helmet>

      {/* Header Section */}
      <Header />

      {/* Banner Section */}
      <div>
        <Banner
          homeLink="Home"
          breadcrumb="Contact"
          title="Contact"
          longText="Unlock endless possibilities and power your brand through social good. We'll shine the spotlight on you."
          backgroundImage={bannerImage}
        />
      </div>

      <div className='section-1'>
        <div className="background d-flex flex-column align-items-center justify-content-center text-center">
          <div className="content-container">
            <p className='heading'>Bunchful Enterprise has a global presence and New York headquarters</p>
            <p className="stuff">
            Our global presence allows us to collaborate with local and international partners effectively, 
            ensuring that our impact on social change is both profound and far-reaching, 
            allowing us to engage diverse communities in our mission and amplify efforts to meet global challenges and support sustainable development goals.
            </p>
          </div>
          <div className="global"></div>
        </div>  
      </div>

      <div className='contact-form-1'>
        <Container>
          <Row>
            {/* Left Column for Contact Info */}
            <Col md={6} style={{paddingTop: "5%"}}>
              <div className="contact-container">
                <div className="contact-info">
                  <div className="info-box">
                    <div className="icon">
                      <FaPhone/>
                    </div>
                    <div className="text">
                      <h3>Phone</h3>
                      <p>+1 718 750 5676</p>
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="icon">
                      <FaEnvelope/>
                    </div>
                    <div className="text">
                      <h3>Email</h3>
                      <p>hello@bunchful.com</p>
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="icon">
                      <FaMapMarkerAlt/>
                    </div>
                    <div className="text">
                      <h3>Address</h3>
                      <p>New York, NY</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
              <Col md={6}>
                <div className="contact-form">
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formMessage">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button variant="success" type="submit">Submit</Button>
                  {status && <p className="status-message">{status}</p>}
                </Form>
                </div>
              </Col>
          </Row>
        </Container>
      </div>

      {/* Sponsorship Deck Section */}
      <div className="sponsor-section">
        <SponsorshipDeck/>
      </div>


      {/* Footer Section */}
      <Footer />
    </div>
  );
}

export default Contact;
