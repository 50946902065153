import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import logo1 from '../../images/homepage/bunchful-media/bunchful-news.webp';
import logo2 from '../../images/homepage/bunchful-media/bunchful-podcast.webp';
import logo3 from '../../images/homepage/bunchful-media/gifting-salon.webp';
import './mediaCard.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import { db } from '../../config/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods

function MediaCard() {
  const [showPodcastModal, setShowPodcastModal] = useState(false);
  const [showGiftingModal, setShowGiftingModal] = useState(false);
  const [email, setEmail] = useState(''); // State to capture email input

  const handlePodcastClose = () => setShowPodcastModal(false);
  const handlePodcastShow = () => setShowPodcastModal(true);

  const handleGiftingClose = () => setShowGiftingModal(false);
  const handleGiftingShow = () => setShowGiftingModal(true);

  // Handle email capture submission for both Podcast and Gifting Salon
  const handleSubmit = async (e, formType) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, formType), { email });
      alert('Email submitted successfully!');
      setEmail(''); // Clear email input field after submission
      if (formType === 'podcastEmails') handlePodcastClose();
      if (formType === 'giftingSalonEmails') handleGiftingClose();
    } catch (error) {
      console.error('Error submitting email:', error);
    }
  };

  const cardData = [
    {
      id: 'news',
      imgSrc: logo1,
      alt: 'Bunchful News Logo', // Add alt text here
      heading: 'BUNCHFUL NEWS',
      text1: 'Read Bunchful Stories In The News',
      text2: 'The BE BUNCHFUL Technology Platform provides an opportunity for organizations and individuals to spotlight their philanthropic activities, along with its accompanying BE BUNCHFUL Arise event, an initiative of the BUNCHFUL Corporate Gifting Platform.',
      buttonText: 'Read Now',
      onClick: () => window.open('https://bunchful.news/', '_blank'),
      bgClass: 'bg-news',
    },
    {
      id: 'podcast',
      imgSrc: logo2,
      alt: 'Bunchful Podcast Logo', // Add alt text here
      heading: 'THE BUNCHFUL PODCAST',
      text1: 'Travel Across Time to Peek Into the History, Culture and Future of Giving.',
      text2: 'Listen to real and mythological stories about the history and culture of giving around the world.',
      buttonText: 'Listen Here',
      onClick: handlePodcastShow,
      bgClass: 'bg-podcast',
    },
    {
      id: 'gifting-salon',
      imgSrc: logo3,
      alt: 'Bunchful Gifting Salon Logo', // Add alt text here
      heading: 'BUNCHFUL GIFTING SALON',
      text1: 'Unlock the Secrets of International Gift-Giving with Expert Guidance on Cultural Norms, Traditions and Etiquette.',
      text2: 'Master the Nuances of corporate and personal gift-giving in global settings.',
      buttonText: 'Subscribe',
      onClick: handleGiftingShow,
      bgClass: 'bg-gifting',
    },
  ];
  

  return (
    <Container fluid className="p-0">
      <Row className="no-gutters">
        {cardData.map((card) => (
          <Col xs={12} key={card.id} className="p-0">
            <Card id={card.id} className={`w-100 mb-0 media-card ${card.bgClass}`} style={{ borderRadius: "0px", border: "0", boxShadow: "none" }}>
              <Row noGutters>
                <Col xs={12} md={4} className="d-flex align-items-center">
                  <Card.Img src={card.imgSrc} alt={card.alt} className="media-card-img" /> {/* Add alt prop here */}
                </Col>
                <Col xs={12} md={8}>
                  <Card.Body>
                    <Card.Title>{card.heading}</Card.Title>
                    <Card.Text className="text1">{card.text1}</Card.Text>
                    <Card.Text className="text2">{card.text2}</Card.Text>
                    <Button variant="primary" className="btn-custom" onClick={card.onClick}>
                      {card.buttonText}
                    </Button>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      {/* Podcast Modal */}
      <Modal show={showPodcastModal} onHide={handlePodcastClose} centered className="custom-modal">
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="w-100 text-center">Bunchful Podcast</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Join the mailing list for important updates when the podcast launches.</p>
          <Form className='custom-form' onSubmit={(e) => handleSubmit(e, 'podcastEmails')}>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-2">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Gifting Salon Modal */}
      <Modal show={showGiftingModal} onHide={handleGiftingClose} centered className="custom-modal-2">
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">Bunchful Gifting Salon</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Join the mailing list for important updates when the gifting salon launches.</p>
          <Form className='custom-form-2' onSubmit={(e) => handleSubmit(e, 'giftingSalonEmails')}>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-2">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default MediaCard;
