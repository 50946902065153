import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './QuoteCarousel.css';  // Import your custom CSS

// Quotes array containing quote text and author
const quotes = [
  {
    text: "One of the central purposes of life is to give, to give with great abundance and love, to give knowing that what we give will come back to us, manifold.",
    author: 'RAQUEL MILLER',
  },
  {
    text: "Only by giving are you able to receive more than you already have.",
    author: 'JIM ROHN',
  },
  {
    text: "When we give cheerfully and accept gratefully, everyone is blessed.",
    author: 'MAYA ANGELOU',
  },
  {
    text: "We make a living by what we get, but we make a life by what we give.",
    author: 'WINSTON CHURCHILL',
  },
  {
    text: "For so many centuries, the exchange of gifts has held us together. It has made it possible to bridge the abyss where language struggles.",
    author: 'BARRY LOPEZ',
  },
  {
    text: "The wise man does not lay up his own treasures. The more he gives to others, the more he has for his own.",
    author: 'LAO TZU',
  },
  {
    text: "The simple gift of giving becomes an elaborate rich aftertaste of a natural blissful feeling, lingering endlessly in my lifetime.",
    author: 'WES ADAMSON',
  },
  {
    text: "It's not what you have, or even what you've accomplished. It's whom you've lifted up, whom you've made better. It's about what you've given back.",
    author: 'DENZEL WASHINGTON',
  },
  {
    text: "No person was ever honored for what he received. Honor has been the reward for what he gave.",
    author: 'CALVIN COOLIDGE',
  },
  {
    text: "Some persons give with such beauty that you remember it as long as you live.",
    author: 'THOMAS À KEMPIS',
  },
  {
    text: "There can be no greater gift than that of giving one's time and energy to helping others without expecting anything in return.",
    author: 'NELSON MANDELA',
  },
  {
    text: "Before giving, the mind of the giver is happy; while giving, the mind of the giver is made peaceful, and having given, the mind of the giver is uplifted.",
    author: 'BUDDHA',
  },
];

// QuoteCarousel component
function QuoteCarousel() {
  return (
    <Carousel 
      indicators={true} 
      controls={true} 
      interval={5000} 
      style={{ boxShadow: "none", borderRadius: "0px" }}
    >
      {quotes.map((quote, index) => (
        <Carousel.Item key={index}>
          <div className="carousel-item-blue">
            <div className="quote-content">
              <p className="quote-text">{`"${quote.text}"`}</p>
              <p className="quote-author">{quote.author}</p>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default QuoteCarousel;
