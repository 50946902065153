import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer/footer";
import Header from "../../Components/Header/header";
import Banner from "../../Components/Banner/banner";
import bannerImage from "../Media/backgroundMedia.svg";
import BlogPage from "../../Components/BlogPage/BlogPage";

const Blog = () => {

    return (
        <div className="blog-page">

            <div>
                <Helmet>
                    <title>Blog Posts - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div>
                <Header/>
            </div>

            <div>
                <Banner
                    homeLink="Home"
                    breadcrumb="Blog"
                    title="Blog Posts"
                    backgroundImage={bannerImage}
                />
            </div>

            <div>
                <BlogPage/>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    );

}

export default Blog;