import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import world_summit from "../../images/event-picture-1.webp";
import awards from "../../images/event-picture-2.webp";
import ball from "../../images/event-picture-3.webp";
import concert from "../../images/event-picture-4.webp";
import "./eventCard.css";

// Events component to display a list of events
function Events() {
  const cardData = [
    {
      imgSrc: world_summit,
      alt: 'World Summit Event Image',
      header: 'BUNCHFUL SUMMIT',
      text: 'Bringing Together Experts & Innovators',
      paragraph: 'With a focus on delivery of the UN Sustainable Development Goals (SDGs), our engaging panels tackle questions around local and international needs, as we explore ways in which giving can help achieve the goals.',
      buttonText: 'Learn More',
      link: '/world-summit',
      title: 'Discover more about the Bunchful Future of Philanthropy World Summit',
      description: 'To learn more about our World Summit, <a href="/world-summit">click here</a>.',
    },
    {
      imgSrc: awards,
      alt: 'Bunchful Awards Event Image',
      header: 'BUNCHFUL AWARDS',
      text: 'Recognizing and Honoring Those Who Give',
      paragraph: 'Our annual Bunchful Awards recognizes exemplary acts of philanthropy by those who give back to the community. Apply for an award or nominate someone who gives and help continue to inspire great acts of kindness.',
      buttonText: 'Learn More',
      link: '/awards',
      title: 'Find out how to participate in the Bunchful Awards',
      description: 'To participate in the Bunchful Awards, <a href="/awards">click here</a>.',
    },
    {
      imgSrc: ball,
      alt: 'Bunchful Ball Event Image',
      header: 'BUNCHFUL BALL',
      text: 'Premiering in Abu Dhabi, Jan 2026',
      paragraph: 'The Bunchful ball is for you to celebrate you. Take this opportunity to honor your own good deeds.',
      buttonText: 'Learn More',
      link: '/bunchful-ball',
      title: 'Join us for the premier Bunchful Ball in Abu Dhabi',
      description: 'Join us for the Bunchful Ball in Abu Dhabi, <a href="/bunchful-ball">click here</a>.',
    },
    {
      imgSrc: concert,
      alt: "It's a Bunchful World Concert Image",
      header: "IT'S A BUNCHFUL WORLD CONCERT",
      text: 'Be at the Concert Making a World of Difference',
      paragraph: "Let's continue to inspire great acts of philanthropy. It's a Bunchful World Concert in 2027.",
      buttonText: 'Learn More',
      link: '/world-concert',
      title: 'Get ready for the It’s a Bunchful World Concert in 2027',
      description: 'To get ready for the It’s a Bunchful World Concert, <a href="/world-concert">click here</a>.',
    },
  ];  
  
  return (
    <Container className='events'>
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <div className='introducing'>INTRODUCING</div>
          <div className='title'>Bunchful Events</div>
        </Col>
        <Col xs={12} md={6} className="mb-4">
          <div className='desc' style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            Giving is more than just a single act of writing a check. It is the continuous action of caring and supporting those who need it most. 
            At Bunchful events, we celebrate those who are giving back to their neighbors, the wider community, and the world.
          </div>
        </Col>
      </Row>

      <Row>
        {cardData.map((card, index) => (
          <Col xs={12} md={6} lg={6} key={index} className="col mb-4">
            <Card className="card">
              <Card.Img className="custom-img" src={card.imgSrc} alt={card.alt} />
              <Card.Body>
                <Card.Title className="card-title">{card.header}</Card.Title>
                <Card.Text className="card-text">{card.text}</Card.Text>
                <p className="card-paragraph">{card.paragraph}</p>
                <a href={card.link} style={{ textDecoration: 'none' }} title={card.title}>
                  <Button className="btn" variant="primary w-auto" aria-label={`Learn more about ${card.header}`}>
                    {card.buttonText}
                  </Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Events;
