import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './careers.css';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Form from "./applicationForm";
import { Helmet } from 'react-helmet';

const jobListings = [
  { 
    title: 'Project Manager', 
    department: 'Leadership & Operations', 
    description: <body style={{backgroundColor: "white"}}>
      <p>We're on the lookout for a dynamic and highly organized Project Manager to lead the execution of high-impact projects. In this role, you'll collaborate with cross-functional teams to drive roadmaps, streamline operations, and accelerate delivery within Bunchful’s innovative and mission-driven ecosystem.</p>
      
      <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

      <h4>Strategic Planning & Execution</h4>
      <ul>
          <li>Manage project timelines, milestones, and deliverables across multiple initiatives.</li>
          <li>Ensure efficient resource allocation while maintaining lean, scalable solutions.</li>
          <li>Collaborate with leadership to prioritize projects that align with Bunchful’s mission.</li>
      </ul>

      <h4>Process Automation & Optimization</h4>
      <ul>
          <li>Utilize No-Code & Low-Code tools (WeWeb, Replit, Bolt.new) to streamline workflows.</li>
          <li>Identify automation opportunities with LangChain and AI-driven systems.</li>
      </ul>

      <h4>Collaboration with Cross-Functional Teams</h4>
      <ul>
          <li>Work with technology, marketing, and operations teams to ensure smooth execution.</li>
          <li>Support digital transformation initiatives using automation-first strategies.</li>
      </ul>

      <h4>Qualifications</h4>
      <ul>
          <li>Experience in project management, operations, or leadership roles.</li>
          <li>Familiarity with agile methodologies and automation tools.</li>
          <li>Strong communication and problem-solving skills.</li>
          <li>Passion for working in a fast-paced, mission-driven organization.</li>
      </ul>
    </body>
  },
  { 
    title: 'Executive Assistant (EA)', 
    department: 'Leadership & Operations', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We're looking for a proactive and highly organized Executive Assistant to provide operational and strategic support to Bunchful’s leadership. This role requires a high level of discretion, initiative, and problem-solving to streamline executive-level tasks.</p>

        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Executive Support & Operations</h4>
        <ul>
            <li>Manage schedules, meetings, and high-priority communications for executives.</li>
            <li>Support executive decision-making with research, reports, and presentations.</li>
        </ul>

        <h4>Process Optimization & Automation</h4>
        <ul>
            <li>Implement AI-powered scheduling and workflow automation tools.</li>
            <li>Optimize administrative processes using No-Code solutions.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in executive support, operations, or administration.</li>
            <li>Excellent organizational and communication skills.</li>
            <li>Tech-savvy with experience in automation tools (Zapier, AI scheduling assistants).</li>
        </ul>
      </body>
  },
  { 
    title: 'Operations Specialist', 
    department: 'Leadership & Operations', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are looking for a detail-oriented Operations Specialist to streamline processes, enhance efficiency, and automate business workflows. You will work closely with various teams to optimize performance and drive impact.</p>

        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Process Automation & Efficiency</h4>
        <ul>
            <li>Identify inefficiencies and implement AI-driven automation solutions.</li>
            <li>Utilize automation tools (LangChain, AI-driven workflows) to enhance productivity.</li>
        </ul>

        <h4>Cross-Departmental Coordination</h4>
        <ul>
            <li>Support operations for marketing, finance, and HR teams.</li>
            <li>Ensure streamlined and efficient project execution across departments.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in operations, business process improvement, or workflow automation.</li>
            <li>Familiarity with AI-powered automation tools.</li>
        </ul>
      </body>
  },
  { 
    title: 'Finance Analyst', 
    department: 'Finance, Fundraising & Sponsorships', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are seeking a Finance Analyst to develop financial models, revenue forecasts, and scalable fundraising strategies. You will leverage AI-powered analytics and automation to optimize financial operations.</p>

        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Financial Modeling & Analysis</h4>
        <ul>
            <li>Develop lean financial models and forecasts to guide decision-making.</li>
            <li>Utilize AI-driven financial tools for analytics and reporting.</li>
        </ul>

        <h4>Fundraising & Sponsorship Strategy</h4>
        <ul>
            <li>Support donor engagement through data-driven sponsorship analysis.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Background in finance, economics, or data analytics.</li>
            <li>Experience with AI-powered financial tools is a plus.</li>
        </ul>
      </body>
  },
  { 
    title: 'Grant Writer', 
    department: 'Finance, Fundraising & Sponsorships', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are seeking a skilled Grant Writer to automate and scale grant proposal workflows. This role will focus on securing funding for Bunchful’s initiatives through AI-enhanced proposal generation.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Grant Writing & Proposal Automation</h4>
        <ul>
            <li>Develop compelling grant proposals using AI-driven tools.</li>
            <li>Automate workflows to streamline the grant application process.</li>
        </ul>

        <h4>Funding Strategy & Research</h4>
        <ul>
            <li>Identify and pursue grant opportunities aligned with Bunchful’s mission.</li>
            <li>Utilize AI-powered research tools to enhance proposal accuracy.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in grant writing or fundraising.</li>
            <li>Familiarity with AI-powered writing tools.</li>
            <li>Strong research and proposal development skills.</li>
        </ul>
      </body>
  },
  { 
    title: 'Marketing Specialist', 
    department: 'Marketing, Branding & Community Engagement', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We're looking for a creative and tech-savvy Marketing Specialist to execute AI-enhanced growth marketing strategies. This role involves leveraging automation tools to optimize engagement, A/B testing, and campaign analytics.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>AI-Powered Marketing & Automation</h4>
        <ul>
            <li>Utilize AI-enhanced marketing platforms to optimize campaigns.</li>
            <li>Implement automation strategies to streamline content distribution.</li>
        </ul>

        <h4>Engagement & Content Strategy</h4>
        <ul>
            <li>Develop high-impact campaigns for brand awareness and audience growth.</li>
            <li>Utilize AI-driven content tools like MidJourney, ElevenLabs, and Synthesia.</li>
        </ul>

        <h4>Analytics & Performance Optimization</h4>
        <ul>
            <li>Analyze marketing performance using AI-enhanced data tools.</li>
            <li>Optimize strategies based on real-time engagement insights.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in digital marketing, content creation, or social media.</li>
            <li>Familiarity with AI-powered marketing tools.</li>
            <li>Strong analytical skills and data-driven decision-making ability.</li>
        </ul>
      </body>
  },
  { 
    title: 'Social Media Strategist', 
    department: 'Marketing, Branding & Community Engagement', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are looking for a strategic and tech-savvy Social Media Specialist to expand Bunchful's presence across LinkedIn, Facebook, Instagram, X, Threads, and YouTube. This role will focus on AI-driven content creation, audience engagement, and automation.</p>

        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Social Media Growth & Strategy</h4>
        <ul>
            <li>Develop engagement strategies that leverage AI-powered analytics.</li>
            <li>Optimize content for audience reach and engagement.</li>
        </ul>

        <h4>AI-Driven Content Optimization</h4>
        <ul>
            <li>Use automation tools to streamline content scheduling and analysis.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in social media marketing and content strategy.</li>
            <li>Familiarity with AI-enhanced analytics and automation tools.</li>
        </ul>
      </body>
  },
  { 
    title: 'Content Writer & Editor', 
    department: 'Marketing, Branding & Community Engagement', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are looking for a talented Content Writer & Editor to create SEO-optimized content that enhances Bunchful’s thought leadership. This role will focus on producing high-quality blogs, newsletters, and research reports.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Content Creation & Optimization</h4>
        <ul>
            <li>Write compelling and research-driven content that aligns with Bunchful’s mission.</li>
            <li>Leverage AI-assisted writing tools like OpenAI API and Hugging Face.</li>
        </ul>

        <h4>SEO & Analytics</h4>
        <ul>
            <li>Optimize content for search engine rankings and audience engagement.</li>
            <li>Use AI-driven analytics to refine content strategy.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Strong writing and editing skills.</li>
            <li>Experience with AI-powered content tools.</li>
            <li>Familiarity with SEO best practices.</li>
        </ul>
      </body>
  },
  { 
    title: 'Full Stack Developer', 
    department: 'Technology, Automation & Rapid Deployment', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are seeking an innovative Full Stack Developer to build scalable applications and automation-driven solutions. In this role, you’ll work with cutting-edge technologies, including Next.js, GraphQL, and FastAPI, to develop high-impact digital tools that enhance Bunchful’s operations.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Application Development</h4>
        <ul>
            <li>Develop and optimize full-stack applications with React.js, Next.js, and FastAPI.</li>
            <li>Leverage GraphQL and WebSockets for real-time data interactions.</li>
            <li>Implement best practices for scalable, secure, and maintainable code.</li>
        </ul>

        <h4>Automation & Intelligent Systems</h4>
        <ul>
            <li>Integrate AI-enhanced tools using OpenAI API, LangChain, and AutoGPT.</li>
            <li>Develop automation scripts to streamline business workflows.</li>
        </ul>

        <h4>Data Management & Security</h4>
        <ul>
            <li>Implement structured databases using MySQL, Pinecone, and ChromaDB.</li>
            <li>Ensure data security through advanced authentication and encryption techniques.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Proficiency in JavaScript, TypeScript, and Python.</li>
            <li>Experience with Next.js, FastAPI, and WebSockets.</li>
            <li>Knowledge of cloud services (AWS, Google Cloud) is a plus.</li>
            <li>Passion for automation, AI-driven development, and impact-driven projects.</li>
        </ul>
      </body>
  },
  { 
    title: 'Data Science Specialist', 
    department: 'Technology, Automation & Rapid Deployment', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are looking for a Data Science Specialist to design automation workflows and develop AI-driven insights. This role will focus on predictive analytics and structured data modeling for donor engagement and sponsorship impact tracking.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Data Modeling & AI Development</h4>
        <ul>
            <li>Develop predictive analytics using AI-powered tools.</li>
            <li>Implement structured data models with ChromaDB, Pinecone, and Google BigQuery.</li>
        </ul>

        <h4>Intelligent Systems Integration</h4>
        <ul>
            <li>Work with LangChain and AutoGPT to enhance automation workflows.</li>
            <li>Apply machine learning techniques to optimize data-driven decision-making.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in data science, machine learning, or AI development.</li>
            <li>Proficiency in Python and data analytics frameworks.</li>
            <li>Knowledge of AI-driven database systems.</li>
        </ul>
      </body>
  },
  { 
    title: 'Automation Specialist', 
    department: 'Technology, Automation & Rapid Deployment', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are seeking an Automation Specialist to develop intelligent workflows that optimize efficiency across Bunchful’s operations. In this role, you’ll work with AI-driven tools to streamline processes and scale impact.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Workflow Automation</h4>
        <ul>
            <li>Design and implement automation workflows using LangChain, LangGraph, and AutoGPT.</li>
            <li>Identify inefficiencies and apply AI-driven solutions to optimize operations.</li>
        </ul>

        <h4>Process Optimization</h4>
        <ul>
            <li>Leverage No-Code & Low-Code platforms (Zapier, WeWeb) to integrate automation solutions.</li>
            <li>Develop automation strategies for marketing, finance, and HR teams.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience with workflow automation tools.</li>
            <li>Familiarity with AI-driven process optimization.</li>
            <li>Strong analytical and problem-solving skills.</li>
        </ul>
      </body>
  },
  { 
    title: 'Cybersecurity Specialist', 
    department: 'Technology, Automation & Rapid Deployment', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are looking for a Cybersecurity Specialist to implement AI-driven security frameworks, monitor threats, and enhance risk management strategies. This role is crucial for safeguarding Bunchful’s infrastructure against evolving cyber threats.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Threat Detection & Risk Management</h4>
        <ul>
            <li>Implement AI-powered security monitoring and quantum encryption.</li>
            <li>Develop risk management frameworks to mitigate cyber threats.</li>
        </ul>

        <h4>Infrastructure Security</h4>
        <ul>
            <li>Monitor security incidents using AI-powered SOC solutions.</li>
            <li>Ensure compliance with best practices and regulatory requirements.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in cybersecurity, network security, or risk assessment.</li>
            <li>Familiarity with AI-powered threat detection tools.</li>
            <li>Strong analytical and problem-solving skills.</li>
        </ul>
      </body>
  },
  { 
    title: 'Podcast Producer & Host', 
    department: 'Media, Journalism & Storytelling', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We're looking for an engaging Podcast Producer & Host to lead discussions on technology, automation, and philanthropy. This role involves crafting high-impact episodes that inspire and inform audiences.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Content Development</h4>
        <ul>
            <li>Produce engaging podcast episodes featuring thought leaders and industry experts.</li>
            <li>Utilize AI-enhanced audio tools like ElevenLabs and RunwayML.</li>
        </ul>

        <h4>Audience Growth & Engagement</h4>
        <ul>
            <li>Develop marketing strategies to expand podcast reach.</li>
            <li>Leverage automation for audience analytics and content distribution.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in podcast production, journalism, or media.</li>
            <li>Strong communication and storytelling skills.</li>
            <li>Passion for technology, social impact, and innovation.</li>
        </ul>
      </body>
  },
  { 
    title: 'Video Content Creator & Editor', 
    department: 'Media, Journalism & Storytelling', 
    description: 
      <body style={{backgroundColor: "white"}}>
        <p>We are looking for a creative and AI-driven Video Content Creator & Editor to produce high-impact storytelling content that amplifies Bunchful’s mission. You’ll leverage cutting-edge AI tools and video production techniques to create engaging digital content for multiple platforms.</p>
        
        <h3 style={{paddingBottom: "2%"}}>Responsibilities</h3>

        <h4>Video Production & Editing</h4>
        <ul>
            <li>Create, edit, and optimize videos for social media, marketing campaigns, and events.</li>
            <li>Utilize AI-enhanced tools like RunwayML, MidJourney, and Synthesia to streamline content creation.</li>
            <li>Develop compelling storytelling content that aligns with Bunchful’s branding and mission.</li>
        </ul>

        <h4>Content Strategy & Optimization</h4>
        <ul>
            <li>Collaborate with the marketing team to develop video strategies for audience engagement.</li>
            <li>Use AI-powered analytics to track video performance and optimize content accordingly.</li>
        </ul>

        <h4>Qualifications</h4>
        <ul>
            <li>Experience in video production, editing, and motion graphics.</li>
            <li>Familiarity with AI-powered video tools and platforms.</li>
            <li>Strong storytelling and creative direction skills.</li>
            <li>Ability to work in a fast-paced, remote environment.</li>
        </ul>
      </body>
  },
];

const Careers = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleDescription = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="careers">
      <Helmet>
        <title>Careers - Bunchful | The Generosity Business | Official Site</title>
      </Helmet>

      <Header />

      <div className="separator" style={{height: "1px", backgroundColor: "black", margin:"0px 0", width: "100%"}}></div>

      <div className='section-1'>
        <Container fluid>
          <Row>
            <h2 className='heading' style={{fontWeight: "400"}}>Our Culture</h2>
            <p className='text'>
              Bunchful is a forward-thinking technology startup dedicated to driving positive social impact through innovative solutions aligned with the United Nations Sustainable Development Goals (SDGs) and philanthropic initiatives. We believe in the power of technology to transform lives and communities.
            </p>
          </Row>
        </Container>
      </div>

      <div className='section-2'>
        <Container fluid>
          <Row>
            <Col xs={12} md={6}>
              <h2 className='heading' style={{fontWeight: "400"}} >Application Process</h2>
              <p className='text'>
                Our application process is crafted to get to know you better and learn about your passion for innovation. In this process, we aim to understand what drives you, how you think, and to provide answers to your questions.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <div className="application-steps">
                <div className="step">
                  <div className="step-number">1</div>
                  <p>Submit Resume</p>
                </div>
                <div className="step">
                  <div className="step-number">2</div>
                  <p>Set up Interview with Team Lead</p>
                </div>
                <div className="step">
                  <div className="step-number">3</div>
                  <p>Interview with Team Lead</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="careers-openings">
        <Container>
          <h2>Open Positions</h2>
          <Row>
            {jobListings.map((job, index) => (
              <Col key={index} xs={12} md={12} lg={12} className="mb-4">
                <Card className="job-listing">
                  <Card.Body>
                    <div className="job-summary">
                      <Card.Title className="job-title">{job.title}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted job-department">{job.department}</Card.Subtitle>
                      <span 
                        className="apply-link" 
                        onClick={() => toggleDescription(index)}
                      >
                        {activeIndex === index ? '▼ Hide' : '▶ Apply'}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <div className="job-description">
                        <Card.Text>{job.description}</Card.Text>
                        <Form />
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default Careers;
