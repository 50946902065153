import React, { useState } from 'react';
import { Container, Row, Col, Button, Carousel, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Footer from "../../Components/Footer/footer";
import Header from "../../Components/Header/header";
import Banner from "../../Components/Banner/banner";
import bannerImage from "./background.png"
import exampleImage from "../../images/homepage/initiatives-page/page-end.webp";
import "./gambiaSchoolProject.css";
import BOI from "../../images/homepage/initiatives-page/bridge-of-innovations.webp";
import 'bootstrap/dist/css/bootstrap.min.css';
import YOGH from "../../images/homepage/initiatives-page/yogh-group.webp";
import partnerships from "../../images/homepage/initiatives-page/partnerships.webp";
import vision from "../../images/homepage/initiatives-page/vision.webp";
import logo1 from "../../images/homepage/initiatives-page/holistic_logo.webp";
import logo2 from "../../images/homepage/initiatives-page/inclusive_logo.webp";
import logo3 from "../../images/homepage/initiatives-page/partnership_logo.webp";
import slide1 from "../../images/homepage/initiatives-page/slide-1.webp";
import slide2 from "../../images/homepage/initiatives-page/slide-2.webp";
import slide3 from "../../images/homepage/initiatives-page/slide-3.webp";
import slide4 from "../../images/homepage/initiatives-page/slide-4.webp";
import slide5 from "../../images/homepage/initiatives-page/slide-5.webp";
import slide6 from "../../images/homepage/initiatives-page/slide-6.webp";
import slide7 from "../../images/homepage/initiatives-page/slide-7.webp";
import slide8 from "../../images/homepage/initiatives-page/slide-8.webp";
import slide9 from "../../images/homepage/initiatives-page/slide-9.webp";
import slide10 from "../../images/homepage/initiatives-page/slide-10.webp";
import slide11 from "../../images/homepage/initiatives-page/slide-11.webp";
import slide12 from "../../images/homepage/initiatives-page/slide-12.webp";
import slide13 from "../../images/homepage/initiatives-page/slide-13.webp";
import slide14 from "../../images/homepage/initiatives-page/slide-14.webp";
import slide15 from "../../images/homepage/initiatives-page/slide-15.webp";
import slide16 from "../../images/homepage/initiatives-page/slide-16.webp";
import slide17 from "../../images/homepage/initiatives-page/slide-17.webp";
import slide18 from "../../images/homepage/initiatives-page/slide-18.webp";
import slide19 from "../../images/homepage/initiatives-page/slide-19.webp";

const GambiaSchoolProject = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <div className='gambiaSchoolProject'>

            <div>
                <Helmet>
                    <title>Gambia School Project - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div>
                <Header/>
            </div>

            <div>
                <Banner
                    homeLink="Home"
                    breadcrumb="Gambia School Project"
                    title="Global Initiatives"
                    backgroundImage={bannerImage}
                />
            </div>

            <div className='section-1'>
                <Container fluid>
                    <Row className="g-4">
                    <Col md={4} className="pe-0">
                        <div className="mb-4">
                        <h1 className="text-primary large-heading">
                            Bunchful Initiative: Gambia School Project
                        </h1>
                        </div>
                        <div className="mb-4">
                        <Button variant="primary" 
                            className="btn-one w-auto"
                            onClick={handleShow}
                            >
                            EMBED PAGE ON YOUR WEBSITE
                        </Button>
                        </div>
                    </Col>
                    <Col md={8} className="pe-0">
                        <div className="mb-4">
                        <p className="text-dark larger-text">
                            Help us Rebuild a Small School and Surrounding Village in The Gambia, applying all 17 SDG Principles.
                        </p>
                        </div>
                        <div>
                        <p className="text-secondary regular-text">
                            Bunchful Enterprise, in partnership with Bridge of Innovation, is pioneering the Gambia School Project, a groundbreaking initiative we are approaching as a real-world experiment to implement all 17 United Nations Sustainable Development Goals (SDGs). This project not only aims to rebuild and enhance educational infrastructure of a small school in a small village in The Gambia, but it also serves as a holistic model for global sustainable development, integrating every aspect of the SDGs from quality education to partnerships for achieving these goals.
                        </p>
                        </div>
                    </Col>
                    </Row>
                </Container>
            </div>

            <div style={{
                borderTop: '1px solid gray',
                width: '100%',
                margin: '20px 0'
            }}></div>


            <div className='section-2'>
                <Container fluid>
                    <Row className="row-1 text-center justify-content-center">
                        <Col>
                            <div className="col-1 text-center justify-content-center">
                                Primary Partner
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center my-3">
                        <Col xs={12} sm={8} md={6}>
                            <img 
                                src= {BOI}
                                alt='Bridge of Innovations Logo'
                                className="img-fluid"
                            />
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col xs={12} sm={8} md={8}>
                            <div className="p-3 text-center">
                                <p className='p1'>Bridge of Innovation fosters global progress by funding tech startups focused on sustainable solutions.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className='yogh'>
                    <Row className="row-2 text-center justify-content-center">
                        <Col>
                            <div className="col-2 text-center justify-content-center">
                                Additional Partners
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center my-3">
                        <Col xs={12} sm={8} md={6}>
                            <img 
                                src= {YOGH}
                                alt='Yogh Group Logo'
                                className="yogh_img img-fluid"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-3 d-flex justify-content-center align-items-center" style={{ height: '100vh', padding: '0' }}>
                <div className="video-wrapper" style={{ width: '80%', maxWidth: '800px', aspectRatio: '16/9' }}>
                    <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/pUprM2CtraQ"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video"
                    ></iframe>
                </div>
            </div>


            <div className='section-4'>   
                <Carousel>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide1}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide2}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide3}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide4}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide5}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide6}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide7}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide8}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide9}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide10}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide11}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide12}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide13}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide14}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide15}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide16}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide17}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide18}
                        alt=''
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide19}
                        alt=''
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

            <div className='section-5'>
                <Container>
                    <Row>
                        <Col className='col-1' xs={12} sm={12} md={7}>
                            <img
                            src={vision}
                            className='vision_img img-fluid'
                            alt="vision"
                            />
                        </Col>
                        <Col>
                            <h1 className='title'>Our Vision</h1>

                            <div className='vision-item'>
                            <img src={logo1} className='logo' alt="Logo 1" />
                            <div>
                                <h2 className='sub_title'>Holistic Development</h2>
                                <p className='p'>We'll be rebuilding the school using a sustainable model.</p>
                            </div>
                            </div>

                            <div className='vision-item'>
                            <img src={logo2} className='logo' alt="Logo 2" />
                            <div>
                                <h2 className='sub_title'>Inclusive Community Engagement</h2>
                                <p className='p'>Ensure inclusivity and sustainability is deeply embedded.</p>
                            </div>
                            </div>

                            <div className='vision-item'>
                            <img src={logo3} className='logo' alt="Logo 3" />
                            <div>
                                <h2 className='sub_title'>Strategic Partnerships</h2>
                                <p className='p'>Collaborating across diverse sectors to integrate all 17 SDGs, leveraging unique strengths for comprehensive development.</p>
                            </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className='section-6'>
                <Container>
                    <Row noGutters>
                        <Col className='col-1' xs={12} sm={12} md={6} >
                            <img 
                                src= {partnerships}
                                className="partnerships_img img-fluid"
                                alt="Partnerships"
                            />
                        </Col>
                        <Col>
                            <h2 className='title'> Partnership Opportunities </h2>
                            <p className='text'> Join us in this innovative initiative that transcends traditional development projects. 
                                Partners will have a chance to engage deeply with a full spectrum of sustainability goals, supported by Bunchful Atlas technology for tracking and showcasing collaborative impacts. 
                                This project is an opportunity to demonstrate how integrated efforts can lead to substantial, multi-dimensional progress.
                                <br/>
                                <br/>
                                Together, let's demonstrate the power of holistic sustainable development in The Gambia and beyond.</p>

                                <Button variant="primary"
                                    className="btn-two w-auto"
                                    onClick={handleShow}
                                    >
                                    EMBED PAGE ON YOUR WEBSITE
                                </Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                        Curious about this amazing initiative and would like to get involved?
                        </p>
                        <div className='button-container'>
                        <Button  onClick={handleShow} className='apply-button' >
                            Download Project Overview
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>


            <div>
                <Footer/>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                <Modal.Title>Embed Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <pre>
                    <code>
                    {`<iframe src="https://bunchful.com/gambia-school-project" height="700" width="500" title="Iframe Example"></iframe>`}
                    </code>
                </pre>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default GambiaSchoolProject;
