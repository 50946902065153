import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './testimonials.css';
import { reviews } from './reviewsData';

const Testimonials = () => {
  return (
    <section className="testimonial-container">
      <Title />
      <Slider />
    </section>
  );
};

const Title = () => (
  <div className="title">
    <h2>TESTIMONIALS</h2>
    <p>What Our Clients Say</p>
  </div>
);

const Slider = () => {
  return (
    <div className="slider-container">
      <Splide
        options={{
          perPage: 1,
          autoplay: true,
          speed: 1000,
          rewind: true,
          rewindByDrag: true,
        }}
      >
        {reviews.map((review) => (
          <ReviewSlide key={review.id} review={review} />
        ))}
      </Splide>
    </div>
  );
};

const ReviewSlide = ({ review }) => (
  <SplideSlide>
    <div className="content">
      <p className="text">{review.text}</p>
    </div>
    <img className="review-img" src={review.image} alt={review.name} />
    <div className="info">
      <p className="user">{review.name}</p>
      <p className="title">{review.title}</p>
    </div>
  </SplideSlide>
);

export default Testimonials;
