// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Home/background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.annual-theme .section-1 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    padding-top: 3%;
    padding-bottom: 3%;
}

.annual-theme .section-1 .top_image {
    max-width: 80%; /* Adjust the max width of the image */
    height: auto; /* Maintain the aspect ratio */
    margin-left: auto;
    margin-right: auto; /* Center the image */
    display: block; /* Ensures it's treated as a block-level element for centering */
}


.annual-theme .section-1 .annual {
    margin-top: 5%;
    color: #191919;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 10%;
}

.annual-theme .section-1 .partnerships {
    color: #191919;
    font-family: "Inter-Regular", Helvetica;
    font-size: 3rem;
    font-weight: 400;
    margin-right: 20%;
}

.annual-theme .section-1 .for-2024 {
    color: #414141;
    font-family: "Noto Sans-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    margin-right: 10%;
  
}

@media (max-width: 767.98px) {
    .annual-theme .section-1 .annual,
    .annual-theme .section-1 .partnerships,
    .annual-theme .section-1 .for-2024 {
        text-align: center;
        margin-right: 0%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/AnnualTheme/annualTheme.css"],"names":[],"mappings":"AAAA;IACI,yDAA8C;IAC9C,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc,EAAE,sCAAsC;IACtD,YAAY,EAAE,8BAA8B;IAC5C,iBAAiB;IACjB,kBAAkB,EAAE,qBAAqB;IACzC,cAAc,EAAE,gEAAgE;AACpF;;;AAGA;IACI,cAAc;IACd,cAAc;IACd,2CAA2C;IAC3C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,uCAAuC;IACvC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,2CAA2C;IAC3C,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;;AAErB;;AAEA;IACI;;;QAGI,kBAAkB;QAClB,gBAAgB;IACpB;AACJ","sourcesContent":[".annual-theme .section-1 {\n    background-image: url(../Home/background.webp);\n    padding-top: 3%;\n    padding-bottom: 3%;\n}\n\n.annual-theme .section-1 .top_image {\n    max-width: 80%; /* Adjust the max width of the image */\n    height: auto; /* Maintain the aspect ratio */\n    margin-left: auto;\n    margin-right: auto; /* Center the image */\n    display: block; /* Ensures it's treated as a block-level element for centering */\n}\n\n\n.annual-theme .section-1 .annual {\n    margin-top: 5%;\n    color: #191919;\n    font-family: \"Noto Sans-Regular\", Helvetica;\n    font-size: 1rem;\n    font-weight: 400;\n    letter-spacing: 0;\n    line-height: 24px;\n    margin-right: 10%;\n}\n\n.annual-theme .section-1 .partnerships {\n    color: #191919;\n    font-family: \"Inter-Regular\", Helvetica;\n    font-size: 3rem;\n    font-weight: 400;\n    margin-right: 20%;\n}\n\n.annual-theme .section-1 .for-2024 {\n    color: #414141;\n    font-family: \"Noto Sans-Regular\", Helvetica;\n    font-size: 1.25rem;\n    font-weight: 400;\n    margin-right: 10%;\n  \n}\n\n@media (max-width: 767.98px) {\n    .annual-theme .section-1 .annual,\n    .annual-theme .section-1 .partnerships,\n    .annual-theme .section-1 .for-2024 {\n        text-align: center;\n        margin-right: 0%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
