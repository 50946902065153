// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tech-showcase .section-1 {
    padding-top: 5%;
    padding-bottom: 5%;
}

.tech-showcase .section-1 .heading {
    text-align: center;
    font-size: 2.5rem;
    padding-left: 10%;
    padding-right: 10%;
    font-weight: 600;
    color: #1f005e;
}

.tech-showcase .section-1 .text {
    text-align: center;
    font-size: 1.5rem;
    padding-left: 5%;
    padding-right: 5%;
}

.tech-showcase .section-1 .video-wrapper {
    width: 90%;             
    margin: 0 auto;         
    position: relative;     
    padding-bottom: 56.25%; 
    height: 0;              
}
  
.tech-showcase .section-1 .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;            
    height: 100%;          
}

.tech-showcase .section-2 .card-text-sm {
    font-size: 1.2rem;
}


.tech-showcase .section-2 .button-wrapper {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    margin-top: 5%;
}

.tech-showcase .section-2 .custom-btn {
    padding: 2%;
    width: auto;
    background-color: #332b77; 
    border-color: #332b77;
}

.tech-showcase .section-2 .custom-btn:hover {
    background-color: #f6edff;
    color: #332b77;
}`, "",{"version":3,"sources":["webpack://./src/Pages/TechShowcase/techShowcase.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,aAAa;IACb,uBAAuB,EAAE,oCAAoC;IAC7D,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".tech-showcase .section-1 {\n    padding-top: 5%;\n    padding-bottom: 5%;\n}\n\n.tech-showcase .section-1 .heading {\n    text-align: center;\n    font-size: 2.5rem;\n    padding-left: 10%;\n    padding-right: 10%;\n    font-weight: 600;\n    color: #1f005e;\n}\n\n.tech-showcase .section-1 .text {\n    text-align: center;\n    font-size: 1.5rem;\n    padding-left: 5%;\n    padding-right: 5%;\n}\n\n.tech-showcase .section-1 .video-wrapper {\n    width: 90%;             \n    margin: 0 auto;         \n    position: relative;     \n    padding-bottom: 56.25%; \n    height: 0;              \n}\n  \n.tech-showcase .section-1 .video-wrapper iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;            \n    height: 100%;          \n}\n\n.tech-showcase .section-2 .card-text-sm {\n    font-size: 1.2rem;\n}\n\n\n.tech-showcase .section-2 .button-wrapper {\n    display: flex;\n    justify-content: center; /* Centers the button horizontally */\n    margin-top: 5%;\n}\n\n.tech-showcase .section-2 .custom-btn {\n    padding: 2%;\n    width: auto;\n    background-color: #332b77; \n    border-color: #332b77;\n}\n\n.tech-showcase .section-2 .custom-btn:hover {\n    background-color: #f6edff;\n    color: #332b77;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
