import React from "react";
import './applicationForm.css';

export default class ApplicationForm extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        position: '',
        resume: null,
        question1: false, // Have you read all details?
        question2: false, // Still interested in unpaid position?
        startDate: '',
        howManyHours: '',
        workAuth: false,
        follows: false,
        posts: false,
    };

    handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        this.setState({ [name]: type === 'checkbox' ? checked : value });
    };

    handleFileChange = (e) => {
        this.setState({ resume: e.target.files[0] });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('firstName', this.state.firstName);
        formData.append('lastName', this.state.lastName);
        formData.append('email', this.state.email);
        formData.append('phoneNumber', this.state.phoneNumber);
        formData.append('position', this.state.position);
        formData.append('startDate', this.state.startDate);
        formData.append('howManyHours', this.state.howManyHours);
        formData.append('question1', this.state.question1);
        formData.append('question2', this.state.question2);
        formData.append('workAuth', this.state.workAuth);
        formData.append('follows', this.state.follows);
        formData.append('posts', this.state.posts);
        if (this.state.resume) {
            formData.append('resume', this.state.resume);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/send-application`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                alert('Application submitted successfully!');
                this.setState({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    position: '',
                    resume: null,
                    question1: false,
                    question2: false,
                    startDate: '',
                    howManyHours: '',
                    workAuth: false,
                    follows: false,
                    posts: false,
                });
            } else {
                alert('Failed to send application.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting application.');
        }
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div>
                    First Name: <input name="firstName" value={this.state.firstName} onChange={this.handleChange} required />
                </div>
                <div>
                    Last Name: <input name="lastName" value={this.state.lastName} onChange={this.handleChange} required />
                </div>
                <div>
                    Email: <input name="email" type="email" value={this.state.email} onChange={this.handleChange} required />
                </div>
                <div>
                    Phone Number: <input name="phoneNumber" type="tel" value={this.state.phoneNumber} onChange={this.handleChange} required />
                </div>

                {/* Checkbox Inputs */}
                <div>
                    <label>
                        <input type="checkbox" name="question1" checked={this.state.question1} onChange={this.handleChange} />
                        Have you read all the details?
                    </label>
                </div>

                <div>
                    <label>
                        <input type="checkbox" name="question2" checked={this.state.question2} onChange={this.handleChange} />
                        Are you still interested in this unpaid position?
                    </label>
                </div>

                <div>
                    Expected Start and End Dates: <input name="startDate" value={this.state.startDate} onChange={this.handleChange} required />
                </div>

                <div>
                    For which position are you applying?: <input name="position" value={this.state.position} onChange={this.handleChange} required />
                </div>

                <div>
                    How many hours per week will you work?: <input name="howManyHours" value={this.state.howManyHours} onChange={this.handleChange} required />
                </div>

                <div>
                    <label>
                        <input type="checkbox" name="workAuth" checked={this.state.workAuth} onChange={this.handleChange} />
                        Do you require any work authorization?
                    </label>
                </div>

                <div>
                    <label>
                        <input type="checkbox" name="follows" checked={this.state.follows} onChange={this.handleChange} />
                        Did you follow our company page “Bunchful Enterprise” on LinkedIn and Medium?
                    </label>
                </div>

                <div>
                    <label>
                        <input type="checkbox" name="posts" checked={this.state.posts} onChange={this.handleChange} />
                        Have you read our latest posts and engaged?
                    </label>
                </div>

                <div>
                    Upload Your Resume: <input name="resume" type="file" onChange={this.handleFileChange} accept=".pdf,.doc,.docx" required />
                </div>

                <button type="submit">Submit Application</button>
            </form>
        );
    }
}
