// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";  // Import Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAqTbJONrusGazI_9SyC4u9XZYopUdafPE",
  authDomain: "bunchful-main.firebaseapp.com",
  databaseURL: "https://bunchful-main-default-rtdb.firebaseio.com",
  projectId: "bunchful-main",
  storageBucket: "bunchful-main.appspot.com",  // This is required for Storage
  messagingSenderId: "654197688473",
  appId: "1:654197688473:web:2822838b6fa8dab1b19008"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Storage
export const storage = getStorage(app);  // Initialize Firebase Storage and export it
