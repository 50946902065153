import React from 'react';
import './technologyPage.css'; // Ensure the CSS file name matches
import Header from '../../Components/Header/header';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import TechnologyCard from '../../Components/Tech/technologyCard'; // Import the TechnologyCard component
import Footer from "../../Components/Footer/footer";
import SponsorshipDeck from "../../Components/SponsorshipDeck/sponsorship";
import Banner from "../../Components/Banner/banner";
import bannerImage from "./backgroundTech.svg";
import exampleImage from "../../images/homepage/technology-page/page-end.webp";
import sdgImage from "../../images/homepage/technology-page/sdg-17-goals.webp";


const Technology = () => {
  return(
    <div className="tech">

      <div>

        <Helmet>
          <title>Bunchful Technologies - Bunchful | The Generosity Business | Official Site</title>
        </Helmet>
        
      </div>

      <Header /> 

      <div>
        <Banner
          homeLink="Home"
          breadcrumb="Bunchful Technologies"
          title="Bunchful Technologies"
          backgroundImage={bannerImage}
        />
      </div>

      <div className="section-1">
        <Container>
          <Col>

            <Row>
              <p className='bunchful-merges'>
                Bunchful merges technology with philanthropy and aligns all forms of giving with specific UN Sustainable
                Development Goals, helping businesses and individuals effectively target their philanthropy. Our suite of technologies connects
                givers to help foster a community aimed at meaningful impact. Bunchful isn't just creating solutions; it's driving a movement for
                greater generosity aligned with global goals and toward a more generous and impactful society.
              </p>

            </Row>

            <Row className='image-container'>
              <img 
                src={sdgImage}
                className="SDG-goals-png img-fluid"
                alt="UN Sustainable Development Goals"
              /> 
            </Row>
          
          </Col>
        </Container>
      </div>

      <div className="technology-section">
        <TechnologyCard/>
      </div>

      <div className="sponsorship-section">
        <SponsorshipDeck/>
      </div>

      <div className='banner-2-section'>
        <Container>
          <Row className="row-1 position-relative">
            <Col xs={12} md={6}>
              <p className='heading'>Let Bunchful amplify and spotlight your philanthropy.</p>
              <div className='button-container'>
                <Button className='apply-button' href='https://bunchfulatlas.com/'>
                  Apply Here
                </Button>
              </div>
            </Col>

            <Col xs={12} md={6} className="position-relative">
              <img src={exampleImage} alt="Overlapping" className="overlap-image" />
            </Col>
          </Row>
        </Container>
      </div>
    

      <Footer />
    </div>
  )
}

export default Technology;
