import React, { useState } from "react";
import { Container, Row, Col, ListGroup, Image} from 'react-bootstrap';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import "./videoGallery.css";
import { Helmet } from "react-helmet";

const VideoGallery = () => {

    const videos = [
        { link: "https://www.youtube.com/embed/xmQs-qbxaP4", thumbnail: "https://img.youtube.com/vi/xmQs-qbxaP4/hqdefault.jpg", title: "MEGHNA LAKHANI" },
        { link: "https://www.youtube.com/embed/SbzQewSWwCA", thumbnail: "https://img.youtube.com/vi/SbzQewSWwCA/hqdefault.jpg", title: "JESSICA SOPHIA WONG" },
        { link: "https://www.youtube.com/embed/zpuSeV2zR4c", thumbnail: "https://img.youtube.com/vi/zpuSeV2zR4c/hqdefault.jpg", title: "MEGAN HERSHISER"},
        { link: "https://www.youtube.com/embed/6mMesu02Bas", thumbnail: "https://img.youtube.com/vi/6mMesu02Bas/hqdefault.jpg", title: "ROBBIE TOMBOKKY"},
        { link: "https://www.youtube.com/embed/5FPLViDfIhY", thumbnail: "https://img.youtube.com/vi/5FPLViDfIhY/hqdefault.jpg", title: "NIZAM UDDIN"},
        { link: "https://www.youtube.com/embed/saVlJCF2Njs", thumbnail: "https://img.youtube.com/vi/saVlJCF2Njs/hqdefault.jpg", title: "KAY NIKIFOROVA"},
        { link: "https://www.youtube.com/embed/RJam0YZ2H6s", thumbnail: "https://img.youtube.com/vi/RJam0YZ2H6s/hqdefault.jpg", title: "TIFFANY LEE"},
        { link: "https://www.youtube.com/embed/3yuPcydufxE", thumbnail: "https://img.youtube.com/vi/3yuPcydufxE/hqdefault.jpg", title: "GIANNA PALLESCHI"},
        { link: "https://www.youtube.com/embed/uPu__jUKyJA", thumbnail: "https://img.youtube.com/vi/uPu__jUKyJA/hqdefault.jpg", title: "MARK CUNNINGHAM"},
    ];

    const videos2 = [
        'https://www.youtube.com/embed/zZO_UYPP4nU',
        'https://www.youtube.com/embed/5FPLViDfIhY',
        'https://www.youtube.com/embed/r2k9VwQY08g',
        'https://www.youtube.com/embed/y9MA-03pzX4',  
        'https://www.youtube.com/embed/1yYg8SZ3PkA'  
    ];

    const videos3 = [
        'https://www.youtube.com/embed/saVlJCF2Njs',
        'https://www.youtube.com/embed/zpuSeV2zR4c',
        'https://www.youtube.com/embed/xmQs-qbxaP4',
        'https://www.youtube.com/embed/vnBPOwEHUdM', 
        'https://www.youtube.com/embed/6mMesu02Bas',
        'https://www.youtube.com/embed/k16DuatxcMo',
        'https://www.youtube.com/embed/RJam0YZ2H6s',
        'https://www.youtube.com/embed/uPu__jUKyJA',
        'https://www.youtube.com/embed/3yuPcydufxE', 
        'https://www.youtube.com/embed/SbzQewSWwCA'   
    ];
    
    const [currentVideoLink, setCurrentVideoLink] = useState(videos[0].link);
    

    return (
        <div className="video-gallery">

            <div>
                <Helmet>
                    <title>Video Gallery - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div>
                <Header/>
            </div>

            <div className="section-1">
                <Container className="video-container" fluid>
                    <div className="title">
                        Our video gallery
                    </div>

                    <Row className="justify-content-center">
                        {/* Main Video Area */}
                        <Col md={9}>
                            <div className="embed-responsive embed-responsive-16by9" style={{ height: "500px" }}>
                                <iframe
                                title="Main Video Player"
                                className="embed-responsive-item"
                                src={currentVideoLink}
                                allowFullScreen
                                style={{ width: "100%", height: "100%" }}
                                />
                            </div>
                        </Col>

                        {/* Scrollable Video List */}
                        <Col md={3} style={{ maxHeight: "500px", overflowY: "auto", paddingLeft: 0 }}>
                            <div className="playlist-header">
                                Playlist: {videos.length} videos
                            </div>
                            <ListGroup>
                                {videos.map((video, index) => (
                                <ListGroup.Item
                                key={index}
                                action
                                onClick={() => setCurrentVideoLink(video.link)}
                            >
                                <Row>
                                    {/* Smaller thumbnail */}
                                    <Col xs={4}>
                                        <Image
                                            src={video.thumbnail}
                                            rounded
                                            style={{ width: "100%" }} // Smaller size
                                        />
                                    </Col>

                                    {/* Video title on the right */}
                                    <Col xs={8} className="d-flex align-items-center">
                                        <p style={{ marginBottom: "0", fontSize: "14px" }}>{video.title}</p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-2">
                <div className="title">
                    2022 Speakers
                </div>
                <Container>
                    <Row>
                        {videos2.map((video, index) => (
                        <Col xs={12} sm={6} md={4} key={index} className="mb-4">
                            <div className="video-wrapper">
                            <iframe
                                src={video}
                                title={`Video ${index + 1}`}
                                allowFullScreen
                            ></iframe>
                            </div>
                        </Col>
                        ))}
                    </Row>
                </Container>
            </div>

            <div className="section-3">
                <div className="title">
                    2021 Speakers
                </div>

                <Container>
                    <Row>
                        {videos3.map((video, index) => (
                        <Col xs={12} sm={6} md={4} key={index} className="mb-4">
                            <div className="video-wrapper">
                            <iframe
                                src={video}
                                title={`Video ${index + 1}`}
                                allowFullScreen
                            ></iframe>
                            </div>
                        </Col>
                        ))}
                    </Row>
                </Container>

            </div>

            <div>
                <Footer/>
            </div>
        </div>
    );
}
export default VideoGallery;