import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './sponsorship.css'; // Import your custom CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const logos = [
  'sponsor-logo-1',
  'sponsor-logo-2',
  'sponsor-logo-3',
  'sponsor-logo-4',
  'sponsor-logo-5',
  'sponsor-logo-6',
];

function SponsorshipDeck() {
  return (
    <div className='section-1'>
      <Container className='text-center'>
        <h2 className="mb-4">Bunchful Enterprise Sponsors</h2>
        <Row className="justify-content-center">
          {logos.map((logoClass, index) => (
            <Col key={index} xs={6} md={2} className="d-flex justify-content-center align-items-center mb-4">
              <div className={`sponsor-logo ${logoClass}`} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default SponsorshipDeck;
