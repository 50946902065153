import React from "react";
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { Helmet } from "react-helmet"; // Import react-helmet
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Banner from "../../Components/Banner/banner";
import bannerImage from "../About/backgroundAbout.svg";
import exampleImage from "../../images/homepage/about-page/un-sdgs/page-end.webp";
import "./unSDGs.css";
import img1 from "../../images/homepage/about-page/un-sdgs/1.webp";
import img2 from "../../images/homepage/about-page/un-sdgs/2.webp";
import img3 from "../../images/homepage/about-page/un-sdgs/3.webp";
import img4 from "../../images/homepage/about-page/un-sdgs/4.webp";
import img5 from "../../images/homepage/about-page/un-sdgs/5.webp";
import img6 from "../../images/homepage/about-page/un-sdgs/6.webp";
import img7 from "../../images/homepage/about-page/un-sdgs/7.webp";
import img8 from "../../images/homepage/about-page/un-sdgs/8.webp";
import img9 from "../../images/homepage/about-page/un-sdgs/9.webp";
import img10 from "../../images/homepage/about-page/un-sdgs/10.webp";
import img11 from "../../images/homepage/about-page/un-sdgs/11.webp";
import img12 from "../../images/homepage/about-page/un-sdgs/12.webp";
import img13 from "../../images/homepage/about-page/un-sdgs/13.webp";
import img14 from "../../images/homepage/about-page/un-sdgs/14.webp";
import img15 from "../../images/homepage/about-page/un-sdgs/15.webp";
import img16 from "../../images/homepage/about-page/un-sdgs/16.webp";
import img17 from "../../images/homepage/about-page/un-sdgs/17.webp";

const unSDGs = () => {
    const images = [
        img1, img2, img3, img4, img5, img6, img7, img8, img9,
        img10, img11, img12, img13, img14, img15, img16, img17
    ];

    return(
        <div className="un-sdgs">

            <div>
                <Helmet>
                    <title>UN SDGs - Bunchful | The Generosity Business | Official Site</title>
                    <link rel="preload" as="image" href={bannerImage} />
                </Helmet>
            </div>
            <div>
                <Header/>
            </div>

            <div className="banner">
                <Banner
                    homeLink="Home"
                    breadcrumb="UN SDGs"
                    title="UN SDGs"
                    backgroundImage={bannerImage}
                />
            </div>

            <div className="section-1">
                <Container>
                    <Row>
                    {images.map((img, idx) => (
                        <Col key={idx} xs={12} sm={6} md={4} className="mb-4">
                            <Card className="cards">
                                <Card.Img 
                                    variant="top" 
                                    src={img} 
                                    alt={`Image ${idx + 1}`} 
                                    width="100%" 
                                    height="auto" 
                                    sizes="(max-width: 768px) 100vw, (max-width: 992px) 50vw, 33vw" // Adjusts sizes for different viewports
                                    srcSet={`${img} 1000w, ${img} 500w`} // Use different resolutions if available
                                />
                            </Card>
                        </Col>
                    ))}
                    </Row>
                </Container>
            </div>

            <div className='banner-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                            Help drive achievement of the SDGs.
                        </p>
                        <div className='button-container'>
                        <Button className='apply-button' href='/contact'>
                            Contact Us
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default unSDGs;
