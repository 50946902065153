import React, { useState } from 'react';
import './sponsor.css';
import { Container, Row, Col, Button, Card, Modal, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Footer from "../../Components/Footer/footer";
import Header from "../../Components/Header/header";
import QuoteCarousel from "../../Components/QuoteCarousel/QuoteCarousel";
import SponsorshipDeck from "../../Components/SponsorshipDeck/sponsorship";
import Banner from "../../Components/Banner/banner";
import bannerImage from "./backgroundSponsor.svg";
import exampleImage from "../../images/homepage/sponsor-page/page-end.webp";
import sponsorTop from "../../images/homepage/sponsor-page/image-1.webp";

import logo_1 from "./logo_1.webp";
import logo_2 from "./logo_2.webp";
import logo_3 from "./logo_3.webp";
import logo_4 from "./logo_4.webp";
import logo_5 from "./logo_5.webp";
import logo_6 from "./logo_6.webp";

import { db } from '../../config/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods

const Sponsors = () => {

    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await addDoc(collection(db, 'sponsorshipDeckEmails'), {
            email,
          });
          alert('Email submitted successfully!');
          setEmail('');
          handleClose(); // Close the modal after submission
        } catch (error) {
          console.error('Error submitting application:', error);
        }
    };

    return (
        <div className="sponsor">

            <div>
                <Helmet>
                    <title>Sponsor - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <Header/>

            <div>
                <Banner
                    homeLink="Home"
                    breadcrumb="Call For Sponsors"
                    title="Call for Sponsors"
                    longText="Unlock endless possibilities and power your brand through social good. We'll shine the spotlight on you."
                    backgroundImage={bannerImage}
                />
            </div>

            <div className = "section-1">
                <Container fluid>
                    <Row className="align-items-center position-relative">
                        <Col md={6}>
                            <div className="bunchful-social mb-4">
                                CALL FOR SPONSORS
                            </div>

                            <div className="heading mb-4">
                                <div className="maximize-impact">
                                    Sponsorship: making the impossible <span style={{ color: '#f3506f' }}>possible</span>
                                </div>
                            </div>

                            <p className="bunchful-magnifies mb-4">
                                Simply put, without our generous sponsors, these important programs
                                and events would not be possible. Our sponsors help us develop a
                                community that is reaching millions of people around the world,
                                furthering our belief in the power of community and the impact of
                                collective efforts. Our mission is to orchestrate events and initiatives
                                that not only inspire but actively drive significant change toward our
                                collective human growth and we aim to create a platform where
                                innovation meets generosity, fostering a network that thrives on
                                making the impossible possible.
                                <br></br> <br></br>
                                Please note that sponsorship will not be accepted from those
                                nominated for a Bunchful Award, during the period of their award
                                consideration.
                            </p>

                            <div className="d-flex gap-3">
                                <Button variant="outline-primary" className="link-1" onClick={handleShow}>
                                    Download Sponsorship Deck
                                </Button>
                            </div>
                        </Col>
                        <Col md={6} >
                            <img src={sponsorTop} className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-2">
                <Container>
                    <Row>
                        <Col md={4}>
                            <Card style={{ backgroundColor: '#fff4f6' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_1}
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">
                                        Further enhance your brand image
                                    </Card.Title>
                                    <Card.Text className="card-text-sm">
                                        Bunchful Enterprises firmly believes that the key to genuine success lies in the act of giving back. That's precisely why we extend an invitation to businesses like yours to sponsor our philanthropic events. By partnering with us, you'll have the opportunity to reap remarkable benefits that go beyond the ordinary. Together, we can amplify your impact as you align your business with a worthy cause, showcasing your unwavering dedication to making a positive difference in the world, reaching a broader audience and leaving a lasting impression on both existing and potential customers.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ backgroundColor: '#ecfaff' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_2}
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">
                                        Unleash brand recognition
                                    </Card.Title>
                                    <Card.Text className="card-text-sm">
                                        Imagine your logo prominently displayed alongside our philanthropic event, attracting the attention of a diverse audience. Through our extensive marketing efforts and media coverage, your brand gains valuable exposure, both locally and beyond. Maximize your visibility and let your brand's reputation soar to new heights.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ backgroundColor: '#fffdf1'}}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_3}
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">
                                        Join us those doing social good
                                    </Card.Title>
                                    <Card.Text className="card-text-sm">
                                        Experience the incredible power of sponsorship. Together, we can create a meaningful legacy, enhance your brand's reputation, and make a lasting impact on the community. Act now and unlock endless possibilities for your business through the transformative force of philanthropy and the Bunchful brand, an emerging leader in the giving space.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Card style={{ backgroundColor: '#ebfffc' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_4}
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">
                                        Foster deeper customer loyalty
                                    </Card.Title>
                                    <Card.Text className="card-text-sm">
                                        Customers appreciate businesses that prioritize social responsibility. Sponsoring a philanthropic event showcases your genuine care for the community, creating a deep sense of trust and loyalty among your customer base. It's a meaningful way to build long-lasting relationships and distinguish your brand from competitors.
                                    </Card.Text>
                                </Card.Body>                              
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ backgroundColor: '#fff7f1' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_5}
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">
                                        Connect with influential networks
                                    </Card.Title>
                                    <Card.Text className="card-text-sm">
                                        Our philanthropic event brings together community leaders, influencers, and like-minded businesses who share your passion for making a difference. By sponsoring this event, you gain exclusive access to a network of influential individuals, opening doors to valuable partnerships, collaborations, and business opportunities.
                                    </Card.Text>
                                </Card.Body>                     
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ backgroundColor: '#f2ebfb' }}>
                                <Card.Body className="card-body">
                                    <img
                                        src={logo_6}
                                        className="card-logo"
                                    />
                                    <Card.Title className="card-title-sm">
                                        Inspire new levels of employee engagement
                                    </Card.Title>
                                    <Card.Text className="card-text-sm">
                                        Philanthropy is not just about external impact—it also ignites a sense of purpose within your own organization. Your employees will take pride in being associated with a socially responsible brand, boosting morale, and fostering a positive company culture. Sponsoring our philanthropic event helps create a shared mission that drives your team to greater heights.
                                    </Card.Text>
                                </Card.Body>     
                            </Card>
                        </Col>
                    </Row>
                    <Button className='btn' onClick={handleShow} >Download Sponsorship Deck</Button>
                </Container>
            </div>

            <div>
                <QuoteCarousel/>
            </div>

            <div>
                <SponsorshipDeck/>
            </div>

            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                            Become a Sponsor. Together, we can create a brighter future.
                        </p>
                        <div className='button-container'>
                        <Button  className='apply-button' onClick={handleShow}>
                            Download Sponsorship Deck
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>

            <Footer />

            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Body className="custom-modal-body">
                <h5>Please write your email to download the Sponsor Deck</h5>
                <Form className="custom-form" onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                    <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </Form.Group>
                    <Button type="submit" className="custom-submit-button">
                    Submit
                    </Button>
                </Form>
                </Modal.Body>
            </Modal>
        </div>

    )
};

export default Sponsors;