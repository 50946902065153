import React, { useState } from 'react';
import { Container, Row, Col, Navbar, Table, Nav, Button, Form, Modal } from 'react-bootstrap';
import "./worldSummit.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import SponsorshipDeck from "../../Components/SponsorshipDeck/sponsorship";
import Banner from "../../Components/Banner/banner";
import bannerImage from "./download.svg";
import exampleImage from "../../images/homepage/events-page/world-summit/page-end.webp";
import sdgImage from "../../images/homepage/events-page/world-summit/17-sdgs.webp";
import woman from "../../images/homepage/events-page/world-summit/woman.webp";
import bullet_point from "./bullet_point.webp";
import logo_1 from "./logo_1.webp";
import logo_2 from "./logo_2.webp";
import logo_3 from "./logo_3.webp";
import logo_4 from "./logo_4.webp";
import logo_5 from "./logo_5.webp";
import logo_6 from "./logo_6.webp";
import logo_7 from "./logo_7.webp";
import logo_8 from "./logo_8.webp";
import { db } from '../../config/firebase'; // Import Firestore
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods


const WorldSummit = () => {

    const [activeGrid, setActiveGrid] = useState('summit'); // State to track active grid

    const [showForm, setShowForm] = useState(false);
    const [firstName, setFirstName] = useState(''); // State for first name
    const [lastName, setLastName] = useState(''); // State for last name
    const [email, setEmail] = useState(''); // State for email

    const handleShowForm = () => setShowForm(true);
    const handleCloseForm = () => setShowForm(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await addDoc(collection(db, 'waitlistForWorldSummit'), {
            firstName,
            lastName,
            email,
          });
          alert('Application submitted successfully!');
          setFirstName(''); // Clear form fields
          setLastName('');
          setEmail('');
          handleCloseForm(); // Close the modal after submission
        } catch (error) {
          console.error('Error submitting application:', error);
        }
    };

    return (

        <div className="world-summit">

            <div>
                <Helmet>
                    <title>Summit - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div className='top_header'>
                <Header/>
            </div>
        

            <Navbar collapseOnSelect expand="lg" className="custom_navbar">
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto justify-content-center w-100">
                            <Nav.Link className="links" href="#global-audience">Global Audience</Nav.Link>
                            <Nav.Link className="links" href="#what-to-expect">What To Expect</Nav.Link>
                            <Nav.Link className="links" href="#agenda">Agenda</Nav.Link>
                            <Nav.Link className="links" href="/video-gallery">Video Gallery</Nav.Link>
                            <Nav.Link className="links" href="/speakers">Speakers</Nav.Link>
                            <Nav.Link className="links" href="/tech-showcase">Tech</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className="banner">
                <Banner
                    homeLink="Home"
                    breadcrumb="Summit"
                    title="Summit"
                    longText="The Bunchful Summit is a key global event that gathers innovators and philanthropists to discuss and shape the future of giving. This summit highlights new technologies and methods impacting philanthropy, fostering discussions on driving progress toward the Sustainable Development Goals (SDGs). 
                    It serves as a platform to inspire actionable change and enhance the impact of global philanthropic efforts."
                    backgroundImage={bannerImage}
                    date="Date: September 16 - 17, 2025"
                />
            </div>

            <div className="section-1">
                <div
                    style={{
                        backgroundColor: "#eeedfa", // Static background color
                        padding: '20px', // Optional: Add padding if needed
                        borderRadius: '20px', // Optional: Add border radius if needed
                        margin: '0 auto', // Center the banner horizontally
                        marginLeft: "5%",
                        marginRight: "5%",
                        marginTop: "2%",
                        marginBottom: "5%",
                        justifyContent: "center",
                        display: 'flex', // Use flexbox for centering
                        flexDirection: 'column', // Align items in a column
                        alignItems: 'center' // Center items horizontally
                    }}
                >
                    <Container>
                        <Row>
                            <Col>
                                <p className="text" style={{ color: "black", fontSize: "1.7rem", paddingTop: "2%", textAlign: "center" }}>
                                    The 17 Sustainable Development Goals(SDGs) to transform our world
                                </p>
                                
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        src={sdgImage}
                                        style={{ width: "70%", height: "auto"}} // Ensure it fits nicely
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>


            <div className="section-2" id='global-audience'>
                <Container className="fluid">
                    <Row>
                        <Col  xs={12} md={6}>
                            <img src={woman} className="img-fluid" />
                        </Col>

                        <Col  xs={12} md={6}>
                            <h1 className='heading'>
                                Welcoming a worldwide audience and perspectives
                            </h1>

                            <ul className="bullets list-unstyled">

                                <li className="d-flex align-items-start">
                                    <img src={bullet_point} alt="bullet" style={{ width: '30px', marginRight: '10px', marginTop: "1%" }} />
                                    Welcoming of leading world speakers, as well as local advocates and philanthropists
                                </li>

                                <li className="d-flex align-items-start">
                                    <img src={bullet_point} alt="bullet" style={{ width: '30px', marginRight: '10px', marginTop: "1%"  }} />
                                    Investor meet and greet, plus presentations
                                </li>

                                <li className="d-flex align-items-start">
                                    <img src={bullet_point} alt="bullet" style={{ width: '30px', marginRight: '10px', marginTop: "1%"  }} />
                                    Collaborations in creating a vision for the world we want to see
                                </li>

                                <li className="d-flex align-items-start">
                                    <img src={bullet_point} alt="bullet" style={{ width: '30px', marginRight: '10px', marginTop: "1%"  }} />
                                    Showcase of new and emerging Phil Tech
                                </li>

                                <li className="d-flex align-items-start">
                                    <img src={bullet_point} alt="bullet" style={{ width: '30px', marginRight: '10px', marginTop: "1%"  }} />
                                    Round table talks and problem solving to make the UN SDGs into a greater probability in our lifetime
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-3" id="what-to-expect">

                <h1 className="heading text-center">
                    What to Expect
                </h1>

                <Container className="container fluid">
                    <Row>
                        <Col xs={12} md={6}>
                            <img src={logo_1} alt="logo" style={{ width: '50px', display: 'block'}} /> 
                            <h2 className="headings">
                                Inspiring speakers
                            </h2>

                            <p>
                                We are delighted to welcome esteemed speakers who have made significant contributions globally, 
                                leading at the forefront of philanthropy. Their captivating presentations and innovative approaches will 
                                enrich our event with expertise and global perspectives. Equally thrilling is hosting local advocates and 
                                philanthropists dedicated to positive change. Together, their influential voices will inspire collaboration and collective action.
                            </p>
                        </Col>

                        <Col xs={12} md={6}>
                            <img src={logo_2} alt="logo" style={{ width: '50px', display: 'block'}} />
                            <h2 className="headings">
                                Impact investors
                            </h2>

                            <p>
                                Through presentations by impact and philanthropic investors, we will delve into cutting-edge strategies, trends, 
                                and solutions, inspiring innovative approaches to global challenges. Our aim is to foster partnerships that can drive 
                                positive change and maximize the impact of philanthropic endeavors. Attendees will gain valuable insights, learn from successful case studies, 
                                and be inspired to develop innovative approaches to address pressing global challenges.
                            </p>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} md={6}>
                            <img src={logo_3} alt="logo" style={{ width: '50px', display: 'block'}} />
                            <h2 className="headings">
                                Panel discussions
                            </h2>

                            <p>
                                Series of engaging panel discussions to explore UN SDGs' societal impact, fostering diverse viewpoints and inspiring action. 
                                Panels feature esteemed individuals from academia, industry, nonprofits, and government. They delve into pressing challenges, 
                                examining how the UN SDGs can address them. Thought-provoking questions encourage panelists to share expertise and innovative ideas, 
                                promoting insightful conversations and meaningful change.
                            </p>
                        </Col>
                            
                        <Col xs={12} md={6}>
                            <img src={logo_4} alt="logo" style={{ width: '50px', display: 'block'}} />
                            <h2 className="headings">
                                Emerging tech
                            </h2>

                            <p>
                                Witness an exciting showcase of cutting-edge PhilTech solutions. 
                                From innovative fundraising platforms to impact measurement tools, 
                                these technologies are revolutionizing philanthropy and reshaping how we 
                                tackle social and environmental challenges. Come explore their potential to enhance the 
                                effectiveness and efficiency of philanthropic endeavors.
                            </p> 
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} md={6}>
                            <img src={logo_5} alt="logo" style={{ width: '50px', display: 'block'}} />
                            <h2 className="headings">
                                Networking
                            </h2>

                            <p>
                                With ample networking opportunities to foster meaningful connections and structured sessions and informal gatherings, 
                                you can share ideas, collaborate and form valuable partnerships. We aim to create an environment that encourages interaction, 
                                knowledge exchange, and the development of mutually beneficial relationships to broaden perspectives, tap into new opportunities, 
                                and collectively address challenges. Join us to nurture connections, ignite creativity, and build the foundations for transformative change.
                            </p>
                        
                        </Col>

                        <Col xs={12} md={6}>
                            <img src={logo_6} alt="logo" style={{ width: '50px', display: 'block'}} />
                            <h2 className="headings">
                                Round table talks
                            </h2>

                            <p>
                                Join our transformative initiative as we strive to turn the UN SDGs into a reality within our lifetime. 
                                Through inclusive round table talks and problem-solving sessions, stakeholders from diverse sectors will engage in open dialogue, 
                                sharing insights and expertise to overcome challenges. 
                                Together, we will identify practical strategies, innovative solutions, and transformative actions, 
                                generating actionable plans that inspire a global movement towards a sustainable future for all.
                            </p>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} md={6}>
                            <img src={logo_7} alt="logo" style={{ width: '50px', display: 'block'}} />
                            <h2 className="headings">
                                Gift Vendors
                            </h2>

                            <p>
                                As a brand that is all about giving, we will present a unique opportunity to meet a curated group of small gift manufacturers 
                                who not only create wonderful products but also give back to their communities.  
                                They will showcase their sustainable initiatives and you will have the chance to explore
                                a dedicated space where they will showcase their products, from handmade crafts to eco-friendly
                                items. Each gift embodies a commitment to social impact and community development. 
                                Our goal to shine the spotlight on these vendors who use their business as a way to give back.
                            </p> 
                        </Col>

                        <Col xs={12} md={6}>
                            <img src={logo_8} alt="logo" style={{ width: '50px', display: 'block'}} />
                            <h2 className="headings">
                                After party
                            </h2>

                            <p>
                                Join us for an unforgettable after-party as we conclude our summit event. 
                                This exclusive gathering promises an evening of celebration, connection, and 
                                inspiration, providing the perfect opportunity to unwind and reflect on the 
                                accomplishments of the summit. Engage with summit participants, including speakers, 
                                panelists, experts, and passionate individuals dedicated to driving change, 
                                while mingling, forging new connections, and engaging in meaningful conversations 
                                with like-minded individuals who share a common vision of a sustainable future.
                            </p>
                        
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-4" id='agenda'>
                <h1 className="heading text-center" style={{ color: '#1b0148' }}>Programming Agenda For Summit & Awards</h1>
                <Container className='schedule-container'>
                    {/* Button section */}
                    <Row className="text-center mb-4">
                        <Col>
                            <button
                                className={`text-button ${activeGrid === 'summit' ? 'active' : ''}`}
                                onClick={() => setActiveGrid('summit')}
                                style={{
                                    color: activeGrid === 'summit' ? '#f35070' : '#1b0148',
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '1.2rem',
                                }}
                            >
                                Summit
                            </button>
                        </Col>
                        <Col>
                            <button
                                className={`text-button ${activeGrid === 'awards' ? 'active' : ''}`}
                                onClick={() => setActiveGrid('awards')}
                                style={{
                                    color: activeGrid === 'awards' ? '#f35070' : '#1b0148',
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '1.2rem',
                                }}
                            >
                                Awards
                            </button>
                        </Col>
                    </Row>

                    {/* Conditionally Render Table */}
                    {activeGrid === 'summit' && (
                        <Row>
                            <Col>
                                <Table bordered style={{ borderColor: '#1b0148', borderWidth: '2px' }}>
                                    <thead style={{ backgroundColor: '#1b0148', color: '#1b0148', borderColor: '#1b0148', borderWidth: '2px' }}>
                                        <tr>
                                            <th style={{ color: 'white', backgroundColor: '#1b0148' }}>Time</th>
                                            <th style={{ color: 'white', backgroundColor: '#1b0148' }}>Agenda</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ color: '#1b0148', borderColor: '#1b0148', borderWidth: '2px' }}>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>9:00 AM - 9:30 AM</td>
                                            <td style={{ color: '#1b0148' }}>Registration</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>9:30 AM - 9:45 AM</td>
                                            <td style={{ color: '#1b0148' }}>Welcome and Opening Address</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>9:45 AM - 10:40 AM</td>
                                            <td style={{ color: '#1b0148' }}>
                                                Speaker Presentations
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>10:40 AM - 11:00 AM</td>
                                            <td style={{ color: '#1b0148' }}>Panel Discussion</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>11:00 AM - 11:20 AM</td>
                                            <td style={{ color: '#1b0148' }}>Networking Break and Refreshments</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>11:20 AM - 12:00 PM</td>
                                            <td style={{ color: '#1b0148' }}>
                                                Speaker Presentations
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>12:00 PM - 12:20 PM</td>
                                            <td style={{ color: '#1b0148' }}>Panel Discussion</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>12:20 PM - 1:20 PM</td>
                                            <td style={{ color: '#1b0148' }}>Lunch</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>1:20 PM - 2:30 PM</td>
                                            <td style={{ color: '#1b0148' }}>Round Table Talk and Workshopping</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>2:30 PM - 2:50 PM</td>
                                            <td style={{ color: '#1b0148' }}>Networking Break and Refreshments</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>2:50 PM - 4:20 PM</td>
                                            <td style={{ color: '#1b0148' }}>
                                                Speaker Presentations
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>4:20 PM - 4:50 PM</td>
                                            <td style={{ color: '#1b0148' }}>Panel Discussion</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>4:50 PM - 5:00 PM</td>
                                            <td style={{ color: '#1b0148' }}>Closing Address</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>5:00 PM - 6:00 PM</td>
                                            <td style={{ color: '#1b0148' }}>Open Networking</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}

                    {activeGrid === 'awards' && (
                        <Row>
                            <Col>
                                <Table bordered style={{ borderColor: '#1b0148', borderWidth: '2px' }}>
                                    <thead style={{ backgroundColor: '#1b0148', color: '#1b0148', borderColor: '#1b0148', borderWidth: '2px' }}>
                                        <tr>
                                            <th style={{ color: 'white', backgroundColor: '#1b0148' }}>Time</th>
                                            <th style={{ color: 'white', backgroundColor: '#1b0148' }}>Agenda</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ color: '#1b0148', borderColor: '#1b0148', borderWidth: '2px' }}>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>5:00 PM - 6:00 PM</td>
                                            <td style={{ color: '#1b0148' }}>Private Media Reception</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>5:00 PM - 6:00 PM</td>
                                            <td style={{ color: '#1b0148' }}>Open Networking</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>6:00 PM - 9:00 PM</td>
                                            <td style={{ color: '#1b0148' }}>Opening Address and Welcome</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ color: '#1b0148' }}>Introduction to First Set of Nominees, Sharing of Stories</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ color: '#1b0148' }}>Dinner Service</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ color: '#1b0148' }}>Introduction of Bunchful Philanthropic Presenters</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ color: '#1b0148' }}>Speaker Presentations (Meal Service Continues)</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ color: '#1b0148' }}>Introduction of Second Set of Nominees, Sharing of Stories and Winners Announced, Meal Service Continues</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ color: '#1b0148' }}>Break and Open Networking</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ color: '#1b0148' }}>Introduction of Third Set of Nominees, Sharing of Stories, including Presentation of ZHAG Award</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#1b0148' }}>9:00 PM</td>
                                            <td style={{ color: '#1b0148' }}>Program Ends</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>

            <div>
                <SponsorshipDeck/>
            </div>

            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                            Come add your voice to the discussion.
                        </p>
                        <div className='button-container'>
                        <Button  className='apply-button' onClick={handleShowForm} >
                            Join Us
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Footer/>
            </div>

            <Modal
                show={showForm}
                onHide={handleCloseForm}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)'}}  // for the backdrop transparency
            >
                <Modal.Header closeButton style={{ backgroundColor: '#5353f3', border: 'none', borderRadius: "0px", justifyContent: 'center'}} closeVariant="white">
                    <Modal.Title style={{color: "white", textAlign:"center"}}>
                        Join the waitlist For Bunchful World Summit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#5353f3', border: 'none'}}>
                    <p style={{textAlign: "center", color: "white"}}>
                        Be first to know when tickets become available.
                    </p>
                    <Form style={{boxShadow: "none", backgroundColor: "#5050f3"}} onSubmit={handleSubmit}>
                        <Form.Group controlId="formFirstName">
                        <Form.Label style={{color: "white"}}>First Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter your first name" 
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)} // Set first name
                        />
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                        <Form.Label style={{color: "white"}}>Last Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter your last name" 
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)} // Set last name
                        />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                        <Form.Label style={{color: "white"}}>Email</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="Enter your email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} // Set email
                        />
                        </Form.Group>
                        <Button style={{borderRadius: "0px", backgroundColor: "#332b78", border: "0"}}variant="primary" type="submit" className="submit-button">
                        Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>

    );
}
export default WorldSummit;
