import React, { useState } from 'react';
import { Button, Card, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import atlas from "../../images/homepage/bunchful-technology/bunchful-atlas.webp";
import mecard from "../../images/homepage/bunchful-technology/bunchful-mecard.webp";
import badge from "../../images/homepage/bunchful-technology/bunchful-badge.webp";
import "./technologyCard.css";
import { db } from '../../config/firebase';
import { collection, addDoc } from 'firebase/firestore';

function CardGrid() {
  const [showBadgeForm, setShowBadgeForm] = useState(false);

  const handleShowBadgeForm = () => setShowBadgeForm(true);
  const handleCloseBadgeForm = () => setShowBadgeForm(false);

  return (
    <Container className="py-5">
      <Header />
      <CardRows handleShowBadgeForm={handleShowBadgeForm} />
      <BadgeModal showBadgeForm={showBadgeForm} handleCloseBadgeForm={handleCloseBadgeForm} />
    </Container>
  );
}

function Header() {
  return (
    <>
      <h2 className="heading text-center">INTRODUCING</h2>
      <p className='desc text-center'>Bunchful Technologies</p>
    </>
  );
}

function CardRows({ handleShowBadgeForm }) {
  return (
    <Row className="g-4">
      <CardItem
        id="atlas"
        imgSrc={atlas}
        alt="Bunchful Atlas Logo"
        title="BUNCHFULATLAS.COM"
        subtitle="Atlas Business Directory"
        description="Exclusive business directory, curated specifically for businesses dedicated to making a difference."
        buttonText="Apply Now"
        link="https://bunchfulatlas.com"
      />
      <CardItem
        id="mecard"
        imgSrc={mecard}
        alt="Bunchful MeCard Logo"
        title="BUNCHFUL.ME"
        subtitle="Bunchful MeCard"
        description="Showcase your personal philanthropy with the digital Bunchful MeCard."
        buttonText="Get Started Now"
        link="https://bunchful.me"
      />
      <CardItem
        id="badge"
        imgSrc={badge}
        alt="Bunchful Badge Logo"
        title="DIGITAL PRESENCE"
        subtitle="Bunchful Badge"
        description="Elevate your impact with the Bunchful Badge! Whether you're a giver, a doer, or a champion for change, wear your kindness proudly and inspire others to act."
        buttonText="Get Started Now"
        isButtonModal
        handleShowBadgeForm={handleShowBadgeForm}
      />
    </Row>
  );
}

function CardItem({ id, imgSrc, title, subtitle, description, buttonText, link, isButtonModal, handleShowBadgeForm, alt }) {
  return (
    <Col md={12} id={id}>
      <Card className="d-flex flex-md-row flex-column" style={{ border: 'none', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: isButtonModal ? '#f2e9fd' : (id === 'mecard' ? '#ffede8' : '#f2f2ff'), height: '100%' }}>
        <div className="d-flex align-items-center justify-content-center" style={{ padding: '20px', flex: '1 1 50%' }}>
          <Card.Img
            className="card-img-top"
            variant="top"
            src={imgSrc}
            alt={alt} // Pass alt prop here
            style={{
              maxWidth: '80%',
              height: 'auto',
              maxHeight: isButtonModal ? '600px' : (id === 'mecard' ? '300px' : '150px'),
              objectFit: 'contain',
            }}
          />
        </div>
        <Card.Body className="d-flex flex-column justify-content-center" style={{ flex: '1 1 50%', textAlign: 'left' }}>
          <Card.Title style={{ fontSize: '1rem' }}>{title}</Card.Title>
          <Card.Text style={{ fontSize: '2.4rem' }}>{subtitle}</Card.Text>
          <Card.Text style={{ fontSize: '1.2rem' }}>{description}</Card.Text>
          {isButtonModal ? (
            <Button className="custom-btn" variant="primary" onClick={handleShowBadgeForm}>
              {buttonText}
            </Button>
          ) : (
            <Button className="custom-btn" as="a" href={link} target="_blank" variant="primary">
              {buttonText}
            </Button>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}


function BadgeModal({ showBadgeForm, handleCloseBadgeForm }) {
  return (
    <Modal
      show={showBadgeForm}
      onHide={handleCloseBadgeForm}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}  // for the backdrop transparency
    >
      <Modal.Header closeButton style={{ backgroundColor: '#f6edff', border: 'none' }}>
        <Modal.Title>Bunchful Badge</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#f6edff', border: 'none' }}>
        <BadgeForm handleCloseBadgeForm={handleCloseBadgeForm} />
      </Modal.Body>
    </Modal>
  );
}

function BadgeForm({ handleCloseBadgeForm }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    companyDescription: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'badgeApplications'), formData);
      console.log("Document written with ID: ", docRef.id);
      handleCloseBadgeForm();  // Close modal after submission
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Form onSubmit={handleSubmit} style={{ backgroundColor: '#f6edff', border: 'none', boxShadow: "none" }}>
      <Row>
        <Col md={6}>
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your first name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your last name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group controlId="companyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your company name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="email" className="mt-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="companyDescription" className="mt-3">
        <Form.Label>Company Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Describe your company"
          name="companyDescription"
          value={formData.companyDescription}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="message" className="mt-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter your message"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
      </Form.Group>
      <Button variant="primary" type="submit" className="custom-btn mt-3">
        Submit
      </Button>
    </Form>
  );
}

export default CardGrid;
