import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import topImage from "../../images/homepage/about-page/un-sdgs/5.webp";
import "./annualTheme.css";

const annualTheme = () => {

    return (
        <div className="annual-theme">

            <Helmet>
                <title>Annual Theme - Bunchful | The Generosity Business | Official Site</title>
            </Helmet>

            <div>
                <Header/>
            </div>

            <div className="section-1">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={6} >
                        <img src={topImage} className="top_image img-fluid" />
                        </Col>
                        <Col md={6}>
                        <div className="annual mb-4">ANNUAL THEME</div>
                        <div className="heading mb-4">
                            <div className="partnerships">
                                Gender Equality: 2025
                            </div>
                        </div>
                        <p className="for-2024 mb-4">
                            For 2025, Bunchful has selected "Gender Equality," represented by Sustainable Development Goal 5 (SDG 5), as its theme of the year. This theme not only aligns with our annual tradition of adopting a different SDG but also resonates with our permanent organizational focus on fostering inclusivity and empowerment. SDG 5 highlights the critical need for equal opportunities, rights, and representation for all genders, advocating for the elimination of discrimination and systemic barriers worldwide.
                            <br/>
                            <br/>
                            By choosing this goal, Bunchful aims to enhance and spotlight initiatives that harness collective action for sustainable development. Each year, while we highlight a specific SDG, our enduring commitment remains to promote partnerships that facilitate a sustainable and inclusive future for all.
                        </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    );

} 
export default annualTheme;