// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallery .section-1 {
    padding-top: 10%;
}

.cards {
    cursor: pointer; /* Changes cursor to indicate that the card is clickable */
}

.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%; /* Optional: Adjust height as per your needs */
    width: 100%;
}

.slide {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the image is resized appropriately */
    display: block; /* Centering fix */
    margin: 0 auto; /* Ensures image is centered horizontally */
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 2rem;
}

.arrow-left {
    left: 10px;
}

.arrow-right {
    right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ImageGallery/imageGallery.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe,EAAE,0DAA0D;AAC/E;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY,EAAE,8CAA8C;IAC5D,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB,EAAE,+CAA+C;IACpE,cAAc,EAAE,kBAAkB;IAClC,cAAc,EAAE,2CAA2C;AAC/D;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf","sourcesContent":[".image-gallery .section-1 {\n    padding-top: 10%;\n}\n\n.cards {\n    cursor: pointer; /* Changes cursor to indicate that the card is clickable */\n}\n\n.carousel {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    height: 100%; /* Optional: Adjust height as per your needs */\n    width: 100%;\n}\n\n.slide {\n    max-width: 100%;\n    max-height: 100%;\n    object-fit: contain; /* Ensures the image is resized appropriately */\n    display: block; /* Centering fix */\n    margin: 0 auto; /* Ensures image is centered horizontally */\n}\n\n.arrow {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    font-size: 2rem;\n}\n\n.arrow-left {\n    left: 10px;\n}\n\n.arrow-right {\n    right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
