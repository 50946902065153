import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Home/homePage";
import Technology from "./Pages/Technology/technologyPage";
import Aboutpage from "./Pages/About/aboutPage";
import Contact from "./Pages/Contact/contact";
import Careers from "./Pages/Careers/careers";
import Sponsors from "./Pages/Sponsor/sponsor";
import Media from "./Pages/Media/media";
import Initiatives from "./Pages/Initiatives/gambiaSchoolProject";
import GrandmaCoolie from "./Pages/GrandmotherMsCoolie/grandmaCoolie";
import UN from "./Pages/UNsdgs/unSDGs";
import NC from "./Pages/NominatingCommittee/nominatingCommittee";
import AT from "./Pages/AnnualTheme/annualTheme";
import IG from "./Pages/ImageGallery/imageGallery";
import VG from "./Pages/VideoGallery/videoGallery";
import WC from "./Pages/WorldConcert/worldConcert";
import BunchfulBall from "./Pages/BunchfulBall/bunchfulBall";
import WorldSummit from "./Pages/WorldSummit/worldSummit";
import Awards from "./Pages/Awards/awards";
import TechShowcase from "./Pages/TechShowcase/techShowcase";
import Speakers from "./Pages/Speakers/speakers";
import Awardees from "./Pages/Awardees/awardees";
import Performances from "./Pages/Performances/performances";
import PrivacyPolicy from "./Pages/PrivacyPolicy/privacyPolicy";
import NotFound from "./Pages/NotFound/notFound";
import Chatbot from "./Components/ChatBot/Chatbot";
import Blog from "./Pages/Blog/blog";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/technologies" element={<Technology />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/sponsor" element={<Sponsors />} />
          <Route path="/media" element={<Media />} />
          <Route path="/gambia-school-project" element={<Initiatives />} />
          <Route path="/grandmother-ms-coolie" element={<GrandmaCoolie />} />
          <Route path="/un-sdgs" element={<UN/>} />
          <Route path="/nominating-committee" element={<NC/>} />
          <Route path="/annual-theme" element={<AT/>} />
          <Route path="/image-gallery" element={<IG/>} />
          <Route path="/video-gallery" element={<VG/>} />
          <Route path="/world-concert" element = {<WC/>}/>
          <Route path="/bunchful-ball" element = {<BunchfulBall/>}/>
          <Route path="/world-summit" element = {<WorldSummit/>}/>
          <Route path="/awards" element = {<Awards/>}/>
          <Route path="/tech-showcase" element = {<TechShowcase/>}/>
          <Route path="/speakers" element = {<Speakers/>}/>
          <Route path="/awardees" element = {<Awardees/>}/>
          <Route path="/performances" element = {<Performances/>}/>
          <Route path="/privacy-policy" element = {<PrivacyPolicy/>}/>
          <Route path="/blog" element = {<Blog/>}/>

          <Route path="*" element = {<NotFound/>}/>
        </Routes>

        <Chatbot />
      </div>
    </Router>
  );
}

export default App;
