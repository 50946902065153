import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { db, storage } from '../../config/firebase'; // Adjust the path as necessary
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Banner from "../../Components/Banner/banner";
import bannerImage from "../About/backgroundAbout.svg";
import "./techShowcase.css";

const TechShowcase = () => {
    const [showSectionModal, setShowSectionModal] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        bio: '',
        phone: '',
        email: '',
        resume: null,
    });

    const handleShowSectionModal = () => {
        setShowSectionModal(true);
    };
    
    const handleCloseSectionModal = () => {
        setShowSectionModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: name === 'resume' ? e.target.files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Upload resume to Firebase Storage
        if (formData.resume) {
            const resumeRef = ref(storage, `techShowcaseResumes/${formData.resume.name}`);
            await uploadBytes(resumeRef, formData.resume);
        }

        // Add form data to Firestore
        await addDoc(collection(db, 'techShowcase'), {
            firstName: formData.firstName,
            lastName: formData.lastName,
            bio: formData.bio,
            phone: formData.phone,
            email: formData.email,
            resumeUrl: `techShowcaseResumes/${formData.resume.name}`, // Save the resume path
        });

        // Reset form after submission
        setFormData({
            firstName: '',
            lastName: '',
            bio: '',
            phone: '',
            email: '',
            resume: null,
        });
        handleCloseSectionModal(); // Close the popup
    };

    return (
        <div className="tech-showcase">
            <div>
                <Helmet>
                    <title>Tech Showcase - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <Header />

            <div className="banner">
                <Banner
                    homeLink="Home"
                    breadcrumb="Tech Showcase"
                    title="Tech Showcase"
                    backgroundImage={bannerImage}
                />
            </div>

            <div className="section-1">
                <h2 className="heading">
                    Join Us at the Bunchful Future of Philanthropy Summit
                </h2>
                <p className="text">
                    We're excited to invite leaders in tech and philanthropy to the Bunchful Future of Philanthropy Summit. Share your innovations, connect with peers, and amplify your impact at this pivotal event.
                </p>
                <div className="video-wrapper">
                    <iframe
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/-GtQ_mahGcw"                
                    />
                </div>
            </div>

            <div className="section-2" style={{backgroundColor: "#f6edff", paddingTop: "3%", paddingBottom: "3%"}}>
                <p className="text" style={{fontSize: "2rem", textAlign: "center", paddingLeft: "5%", paddingRight: "5%", color: "#1d005e", fontWeight: "600"}}>
                    Apply Now to Showcase Your Impact or Philanthropy Tech at the Bunchful Future of Philanthropy World Summit. Limited Space. Approval Required in Order to be Featured
                </p>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={6} className="mb-4" style={{paddingLeft: "5%", paddingRight: "5%"}}>
                            <Card style={{ backgroundColor: '#f3506f', color:"white" }}>
                                <Card.Body className="card-body">
                                    <Card.Title className="card-title-sm" style={{textAlign: "center", fontSize: "2.5rem"}}>
                                        We're Seeking:
                                    </Card.Title>
                                    <Card.Text className="card-text-sm">
                                        <ul className="checkmark-list" style={{ textAlign: "left", listStyleType: "disc", paddingLeft: "0" }}>
                                            <li>Tech innovators in social impact</li>
                                            <li>Non-profit leaders utilizing tech</li>
                                            <li>Academics and entrepreneurs in philanthropy tech</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={12} md={6} style={{paddingLeft: "5%", paddingRight: "5%"}}>
                            <Card style={{ backgroundColor: '#4f50f2', color:"white"}}>
                                <Card.Body className="card-body">
                                    <Card.Title className="card-title-sm" style={{textAlign: "center", fontSize: "2.5rem"}}>
                                        Topics of Interest:
                                    </Card.Title>
                                    <Card.Text className="card-text-sm">
                                        <ul className="checkmark-list" style={{ textAlign: "left", listStyleType: "disc", paddingLeft: "0" }}>
                                            <li>Digital giving advancements</li>
                                            <li>Volunteer and donor management innovations</li>
                                            <li>AI, blockchain, and more in philanthropy</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <div className="button-wrapper" style={{ textAlign: "center", marginTop: "2rem" }}>
                        <Button className="custom-btn" onClick={handleShowSectionModal}>Apply Now</Button>
                    </div>
                </Container>
            </div>
            <Footer />

            <Modal show={showSectionModal} onHide={handleCloseSectionModal} centered>
                <Modal.Header style={{backgroundColor: "#5050f3", color: "white"}} closeButton>
                    <div style={{textAlign: "center", width: "100%"}}>
                        <Modal.Title>
                            Apply Now
                        </Modal.Title>
                        <p style={{marginTop: "5px", color: "white"}}>
                            Submit your presentation proposal and bio via our online form.
                        </p>
                    </div>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: "#5050f3"}}>
                    <Form style={{boxShadow: "none", backgroundColor: "#5050f3"}} onSubmit={handleSubmit}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label style={{color: "white", fontWeight: "500"}}>Enter your first name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Your first name" 
                                name="firstName" 
                                value={formData.firstName} 
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                            <Form.Label style={{color: "white", fontWeight: "500"}}>Enter your last name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Your last name" 
                                name="lastName" 
                                value={formData.lastName} 
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formYourBio">
                            <Form.Label style={{color: "white", fontWeight: "500"}}>Write your Bio</Form.Label>
                            <Form.Control 
                                type="text"
                                name="bio" 
                                value={formData.bio} 
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPhoneNumber">
                            <Form.Label style={{color: "white", fontWeight: "500"}}>Give Your Phone Number</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Phone" 
                                name="phone" 
                                value={formData.phone} 
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label style={{color: "white", fontWeight: "500"}}>Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Give your email" 
                                name="email" 
                                value={formData.email} 
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formResume">
                            <Form.Label style={{color: "white", fontWeight: "500"}}>Upload Your Resume</Form.Label>
                            <Form.Control 
                                type="file" 
                                name="resume" 
                                onChange={handleChange} 
                            />
                        </Form.Group>
                        <Button 
                            style={{color: "black", borderRadius: "0px", backgroundColor: "white", borderRadius: "5px"}}
                            variant="primary" 
                            type="submit" 
                            className="submit-button"
                        >
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default TechShowcase;
