import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../images/general/header-logo.webp"; // Update path to assets directory
import './header.css'; // Import custom CSS
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => setShowDropdown(true);
  const handleMouseLeave = () => setShowDropdown(false);

  return (
    <Navbar collapseOnSelect expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand href="/" className="logo">
          <img src={Logo} alt="Logo" width="180" height="auto" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* About Dropdown */}
            <NavDropdown
              title={<Nav.Link as={Link} to="/about" className="nav-item-link">About <FontAwesomeIcon icon={faAngleDown} className="dropdown-arrow" /></Nav.Link>}
              id="about-nav-dropdown"
              show={showDropdown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <NavDropdown.Item as={Link} to="/grandmother-ms-coolie">Grandmother Ms. Coolie</NavDropdown.Item>
              <NavDropdown.Item as={HashLink} to="/about#team">Team</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/un-sdgs">UN SDGs</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/nominating-committee">Nominating Committee</NavDropdown.Item>
            </NavDropdown>

            {/* Technologies Dropdown */}
            <NavDropdown
              title={<Nav.Link as={Link} to="/technologies" className="nav-item-link">Technologies <FontAwesomeIcon icon={faAngleDown} className="dropdown-arrow" /></Nav.Link>}
              id="technologies-nav-dropdown"
              show={showDropdown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <NavDropdown.Item as={HashLink} smooth to="/technologies#atlas">Bunchful Atlas</NavDropdown.Item>
              <NavDropdown.Item as={HashLink} smooth to="/technologies#mecard">Bunchful MeCard</NavDropdown.Item>
              <NavDropdown.Item as={HashLink} smooth to="/technologies#badge">Bunchful Badge</NavDropdown.Item>
            </NavDropdown>

            {/* Events Dropdown */}
            <NavDropdown
                title={
                  <span className="nav-item-link">
                    Events <FontAwesomeIcon icon={faAngleDown} className="dropdown-arrow" />
                  </span>
                }
                id="events-nav-dropdown"
                show={showDropdown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {/* Direct "Events" Link as First Dropdown Item */}
            <NavDropdown.Item
              href="https://events.bunchful.com/events/bunchful-philanthropy-world-summit-and-awards/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Buy Tickets
            </NavDropdown.Item>

            <NavDropdown.Divider /> {/* Optional: Adds a line separator */}

            {/* Other Dropdown Items */}
            <NavDropdown.Item as={Link} to="/annual-theme">Annual Theme</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/world-summit">Summit</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/awards">Awards</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/bunchful-ball">Bunchful Ball</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/world-concert">World Concert</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/image-gallery">Image Gallery</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/video-gallery">Video Gallery</NavDropdown.Item>
          </NavDropdown>


            {/* Media Dropdown */}
            <NavDropdown
              title={<Nav.Link as={Link} to="/media" className="nav-item-link">Media <FontAwesomeIcon icon={faAngleDown} className="dropdown-arrow" /></Nav.Link>}
              id="media-nav-dropdown"
              show={showDropdown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <NavDropdown.Item as={Link} to="/blog">Blog</NavDropdown.Item>
              <NavDropdown.Item as={HashLink} to="/media#news">News</NavDropdown.Item>
              <NavDropdown.Item as={HashLink} to="/media#podcast">Podcast</NavDropdown.Item>
              <NavDropdown.Item as={HashLink} to="/media#gifting-salon">Gifting Salon</NavDropdown.Item>
            </NavDropdown>

            {/* Initiatives Dropdown */}
            <NavDropdown
              title={<Nav.Link as={Link} to="/gambia-school-project" className="nav-item-link">Initiatives <FontAwesomeIcon icon={faAngleDown} className="dropdown-arrow" /></Nav.Link>}
              id="initiatives-nav-dropdown"
              show={showDropdown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <NavDropdown.Item as={Link} to="/gambia-school-project">Gambia School Project</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={Link} to="/sponsor" className="nav-item-link">Sponsor</Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-item-link">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
