import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Logo from '../../images/general/footer-logo.webp';
import sdg17 from '../../images/general/17-partnership.webp';
import { 
    FaFacebook, FaInstagram, FaTwitter, 
    FaYoutube, FaLinkedin, FaPhone, FaEnvelope, 
    FaMapMarkerAlt 
} from 'react-icons/fa';
import './footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';
import { db } from '../../config/firebase';
import { collection, addDoc } from 'firebase/firestore';

const Footer = () => {
    const [email, setEmail] = useState('');
    const { pathname } = useLocation(); // Get the current path

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on path change
    }, [pathname]); // Run this effect when `pathname` changes

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, "newsletter"), { email });
            alert('Thank you for subscribing to our newsletter!');
            setEmail('');
        } catch (error) {
            console.error("Error adding document: ", error);
            alert('There was an error, please try again.');
        }
    };

    return (
        <footer className="footer">
            <Container fluid>
                {/* SDG 17 Section */}
                <Row className="mb-4">
                    <Col xs={12} md={4}>
                        <h3 className='sdg-17'>SDG 17 is the main goal for Bunchful Enterprise</h3>
                        <div className="footer-images mb-3">
                            <img src={sdg17} alt="SDG 17" className="footer-img large-logo" />
                            <img src={Logo} alt="Bunchful Logo" className="footer-img small-logo" />
                        </div>
                        <p>
                            Bunchful magnifies all your philanthropy and giving on one platform,
                            increasing your social reach, elevating your business, and boosting
                            your bottom line.
                        </p>
                    </Col>

                    {/* Newsletter Section */}
                    <Col xs={12} md={4}>
                        <h3 className='join-our'>Join our newsletter!</h3>
                        <Form className="newsletter-form" onSubmit={handleSubmit}>
                            <Form.Control 
                                type="email" 
                                placeholder="Email" 
                                className="mb-2" 
                                value={email} 
                                onChange={handleEmailChange} 
                                required
                            />
                            <Button style={{color: "white"}} type="submit" className="btn-custom w-100">Send</Button>
                        </Form>
                    </Col>

                    {/* Social Media Section */}
                    <Col xs={12} md={4}>
                        <h3 className='follow-us'>Follow us on</h3>
                        <div className="social-icons mb-4">
                            <a href="https://www.facebook.com/bunchful" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook">
                                <FaFacebook />
                            </a>
                            <a href="https://www.youtube.com/@bunchful" target="_blank" rel="noopener noreferrer" aria-label="Follow us on YouTube">
                                <FaYoutube />
                            </a>
                            <a href="https://www.instagram.com/bunchful/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram">
                                <FaInstagram />
                            </a>
                            <a href="https://x.com/bebunchful" target="_blank" rel="noopener noreferrer" aria-label="Follow us on X">
                                <FaTwitter />
                            </a>
                            <a href="https://www.linkedin.com/company/bunchful/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on LinkedIn">
                                <FaLinkedin />
                            </a>
                        </div>
                    </Col>
                </Row>

                {/* Quick Links, Events, and Contact Info Section */}
                <Row className="mb-4">
                    <Col xs={12} md={4}>
                        <div className="quick-links">
                            <h4 style={{ paddingBottom: "3%" }}>Quick Links</h4>
                            <ul className="list-unstyled">
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/nominating-committee">Nominating Committee</Link></li>
                                <li><Link to="https://bunchful.news/">News</Link></li>
                                <li><Link to="/sponsor">Sponsors</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                            </ul>
                        </div>
                    </Col>

                    <Col xs={12} md={4}>
                        <div className="bunchful-events">
                            <h4 style={{ paddingBottom: "3%" }}>Bunchful Events</h4>
                            <ul className="list-unstyled">
                                <li><Link to="/world-summit">Summit</Link></li>
                                <li><Link to="/awards">Awards</Link></li>
                                <li><Link to="/bunchful-ball">Ball</Link></li>
                                <li><Link to="/world-concert">Concert</Link></li>
                            </ul>
                        </div>
                    </Col>

                    <Col xs={12} md={4}>
                        <div className="contact-info">
                            <h4>Contact Info</h4>
                            <div className='stuff'>
                                <p>
                                    <a href="tel:+17187505676" style={{ textDecoration: "none", color: "inherit" }}>
                                        <FaPhone /> +1 718 750 5676
                                    </a>
                                </p>
                                <p>
                                    <a href="mailto:hello@bunchful.com" style={{ textDecoration: "none", color: "inherit" }}>
                                        <FaEnvelope /> hello@bunchful.com
                                    </a>
                                </p>
                                <p>
                                    <a href="https://www.google.com/maps/search/?api=1&query=New+York,+NY" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                                        <FaMapMarkerAlt /> New York, NY
                                    </a>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* Footer Copyright and Message */}
                <p style={{ fontSize: "1rem", textAlign: "center" }}>
                    Do you know what it means to <em>'be bunchful'</em>? <br />
                    To <em>'be bunchful'</em> is to share your gifts and abundance with others.
                </p>

                <div className="separator" style={{ height: "1px", backgroundColor: "white", margin: "20px 0px", width: "100%" }}></div>

                <p style={{ textAlign: "center" }}>
                    Copyright © 2025 Bunchful Inc. All Rights Reserved.
                    <Link to="/privacy-policy" style={{ marginLeft: "5px", textDecoration: "none", color: "inherit" }}>
                        Terms, Conditions and Privacy Policy
                    </Link>
                </p>
            </Container>
        </footer>
    );
};

export default Footer;