import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Banner from "../../Components/Banner/banner";
import bannerImage from "../About/backgroundAbout.svg";
import { Helmet } from "react-helmet";
import "./imageGallery.css";
import img1 from "../../images/homepage/events-page/image-gallery/1.webp";
import img2 from "../../images/homepage/events-page/image-gallery/2.webp";
import img3 from "../../images/homepage/events-page/image-gallery/3.webp";
import img4 from "../../images/homepage/events-page/image-gallery/4.webp";
import img5 from "../../images/homepage/events-page/image-gallery/5.webp";
import img6 from "../../images/homepage/events-page/image-gallery/6.webp";
import img7 from "../../images/homepage/events-page/image-gallery/7.webp";
import img8 from "../../images/homepage/events-page/image-gallery/8.webp";
import img9 from "../../images/homepage/events-page/image-gallery/9.webp";
import img10 from "../../images/homepage/events-page/image-gallery/10.webp";
import img11 from "../../images/homepage/events-page/image-gallery/11.webp";
import img12 from "../../images/homepage/events-page/image-gallery/12.webp";
import img13 from "../../images/homepage/events-page/image-gallery/13.webp";
import img14 from "../../images/homepage/events-page/image-gallery/14.webp";
import img15 from "../../images/homepage/events-page/image-gallery/15.webp";
import img16 from "../../images/homepage/events-page/image-gallery/16.webp";
import img17 from "../../images/homepage/events-page/image-gallery/17.webp";
import img18 from "../../images/homepage/events-page/image-gallery/18.webp";
import img19 from "../../images/homepage/events-page/image-gallery/19.webp";
import img20 from "../../images/homepage/events-page/image-gallery/20.webp";
import img21 from "../../images/homepage/events-page/image-gallery/21.webp";
import img22 from "../../images/homepage/events-page/image-gallery/22.webp";
import img23 from "../../images/homepage/events-page/image-gallery/23.webp";
import img24 from "../../images/homepage/events-page/image-gallery/24.webp";
import img25 from "../../images/homepage/events-page/image-gallery/25.webp";
import img26 from "../../images/homepage/events-page/image-gallery/26.webp";
import img27 from "../../images/homepage/events-page/image-gallery/27.webp";
import img28 from "../../images/homepage/events-page/image-gallery/28.webp";
import img29 from "../../images/homepage/events-page/image-gallery/29.webp";
import img30 from "../../images/homepage/events-page/image-gallery/30.webp";
import img31 from "../../images/homepage/events-page/image-gallery/31.webp";
import img32 from "../../images/homepage/events-page/image-gallery/32.webp";
import img33 from "../../images/homepage/events-page/image-gallery/33.webp";
import img34 from "../../images/homepage/events-page/image-gallery/34.webp";
import img35 from "../../images/homepage/events-page/image-gallery/35.webp";
import img36 from "../../images/homepage/events-page/image-gallery/36.webp";
import img37 from "../../images/homepage/events-page/image-gallery/37.webp";
import img38 from "../../images/homepage/events-page/image-gallery/38.webp";

const images = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9,
    img10, img11, img12, img13, img14, img15, img16, img17,
    img18, img19, img20, img21, img22, img23, img24, img25,
    img26, img27, img28, img29, img30, img31, img32, img33,
    img34, img35, img36, img37, img38
];

const ImageGallery = () => {
    const [show, setShow] = useState(false);
    const [slide, setSlide] = useState(0);

    const handleShow = (index) => {
        setSlide(index);
        setShow(true);
    };

    const handleClose = () => setShow(false);
    
    const nextSlide = () => {
        setSlide(slide === images.length - 1 ? 0 : slide + 1);
    };

    const prevSlide = () => {
        setSlide(slide === 0 ? images.length - 1 : slide - 1);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "ArrowRight") {
                nextSlide();
            } else if (event.key === "ArrowLeft") {
                prevSlide();
            }
        };

        if (show) {
            window.addEventListener("keydown", handleKeyDown);
        }

        // Cleanup
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [show, slide]); // Rerun effect when show or slide changes

    return (
        <div className="image-gallery">
            <Helmet>
                <title>Image Gallery - Bunchful | The Generosity Business | Official Site</title>
            </Helmet>

            <Header />


            <Banner
                homeLink="Home"
                breadcrumb="Image Gallery"
                title="Gallery"
                backgroundImage={bannerImage}
            />

            <div className="section-1">
                <Container>
                    <Row>
                        {images.map((img, idx) => (
                            <Col key={idx} xs={12} sm={6} md={4} className="mb-4">
                                <Card style={{border: "0"}} className="cards" onClick={() => handleShow(idx)}>
                                    <Card.Img variant="top" src={img} alt={`Image ${idx + 1}`} />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>

            <Footer />

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <div className="carousel">
                    <BsArrowLeftCircleFill onClick={prevSlide} className="arrow arrow-left" />
                        <img src={images[slide]} alt={`Image ${slide + 1}`} className="slide" />
                    <BsArrowRightCircleFill onClick={nextSlide} className="arrow arrow-right" />
                </div>
            </Modal>


        </div>
    );
};

export default ImageGallery;
