import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import './BlogPage.css';

const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts`);
        const data = await response.json();

        console.log('Fetched data:', data); // <-- Debug: see what you're getting
        setBlogPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  // Function to decode HTML entities and remove images
  const decodeHtmlAndRemoveImages = (html) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    let decodedHtml = textArea.value;
    // Remove <img> tags
    decodedHtml = decodedHtml.replace(/<img[^>]*>/g, '');
    return decodedHtml;
  };

  const handleShow = (post) => {
    setSelectedPost(post);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedPost(null);
  };

  // Truncate text
  const truncateText = (htmlString, maxLength) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const text = tempDiv.innerText || tempDiv.textContent;
    if (!text || text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  return (
    <div className='blog-post-cards'>
      <Container>
        <Row className="my-4">
          {loading ? (
            <Col>
              <p>Loading posts...</p>
            </Col>
          ) : blogPosts.length > 0 ? (
            blogPosts.map((post, index) => (
              <Col key={index} md={4} className="mb-4">
                <Card className="h-100">
                  <Card.Body className="d-flex flex-column">
                    <Card.Title>
                      {decodeHtmlAndRemoveImages(post.title)}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {post.date || post.pubDate || ''}
                    </Card.Subtitle>
                    <Card.Text>
                      {truncateText(post.content, 100)}
                    </Card.Text>
                    <Button onClick={() => handleShow(post)} variant="primary" className="mt-auto">
                      Read More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col>
              <p>No posts available.</p>
            </Col>
          )}
        </Row>
      </Container>

      {/* Modal for displaying full post */}
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedPost ? decodeHtmlAndRemoveImages(selectedPost.title) : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPost && (
            <div
              dangerouslySetInnerHTML={{
                __html: decodeHtmlAndRemoveImages(selectedPost.content),
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedPost && (
            <a
              href={selectedPost.link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              Read on Medium
            </a>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BlogPage;
