// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial-container {
    text-align: center;
    padding: 20px;
    background-color: #fff5f7;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .title h2 {
    font-size: 1rem;
    color: #333;
  }
  
  .title p {
    font-size: 1.5rem;
    color: #333;
  }
  
  .slider-container {
    position: relative;
  }
  
  .splide__arrow--prev {
    left: -35px;
  }
  
  .splide__arrow--next {
    right: -35px;
  }
  
  .content {
    font-size: 16px;
    font-style: italic;
    color: #666;
    margin-bottom: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .review-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 20px auto;
  }
  
  .info {
    margin-top: 10px;
  }
  
  .info .user {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    background-color: transparent;
    margin-bottom: 5px;
  }
  
  .title {
    font-size: 14px;
    color: #666;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Testimonials/testimonials.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,6BAA6B;IAC7B,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":[".testimonial-container {\n    text-align: center;\n    padding: 20px;\n    background-color: #fff5f7;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .title h2 {\n    font-size: 1rem;\n    color: #333;\n  }\n  \n  .title p {\n    font-size: 1.5rem;\n    color: #333;\n  }\n  \n  .slider-container {\n    position: relative;\n  }\n  \n  .splide__arrow--prev {\n    left: -35px;\n  }\n  \n  .splide__arrow--next {\n    right: -35px;\n  }\n  \n  .content {\n    font-size: 16px;\n    font-style: italic;\n    color: #666;\n    margin-bottom: 20px;\n    max-width: 600px;\n    margin: 0 auto;\n  }\n  \n  .review-img {\n    border-radius: 50%;\n    width: 150px;\n    height: 150px;\n    object-fit: cover;\n    margin: 20px auto;\n  }\n  \n  .info {\n    margin-top: 10px;\n  }\n  \n  .info .user {\n    font-size: 18px;\n    font-weight: bold;\n    color: #333;\n    background-color: transparent;\n    margin-bottom: 5px;\n  }\n  \n  .title {\n    font-size: 14px;\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
