import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form} from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Banner from "../../Components/Banner/banner";
import bannerImage from "../Sponsor/backgroundSponsor.svg";
import exampleImage from "../../images/homepage/about-page/nominating-committee/page-end.webp";
import imgSrc from "../../images/homepage/about-page/nominating-committee/page-top.webp";
import "./nominatingCommittee.css";
import lV from "../../images/homepage/about-page/nominating-committee/levar-jackson.webp";
import tM from "../../images/homepage/about-page/nominating-committee/taghrid-sayed-mohamed.webp";
import dR from "../../images/homepage/about-page/nominating-committee/david-valera-reynoso.webp";
import kM from "../../images/homepage/about-page/nominating-committee/kelly-meerbot.webp";
import bS from "../../images/homepage/about-page/nominating-committee/brooke-saucier.webp";
import bC from "../../images/homepage/about-page/nominating-committee/bill-campbell.webp";
import eH from "../../images/homepage/about-page/nominating-committee/evelyn-haughton.webp";

import { db, storage } from '../../config/firebase'; // Adjust the path as necessary
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';

const NominatingCommittee = () => {

    // State variables
    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        bio: '',
        phone: '',
        email: '',
        resume: null,
    });

    const handleShowModal = (card) => {
      setSelectedCard(card);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
      setSelectedCard(null);
    };

    const handleShow = () => setShowPopup(true);
    const handleClose = () => setShowPopup(false);

    // Form input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // File upload handler
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prev) => ({ ...prev, resume: file }));
    };

    // Form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Upload resume to Firebase Storage
        if (formData.resume) {
            const resumeRef = ref(storage, `committeeResumes/${formData.resume.name}`);
            await uploadBytes(resumeRef, formData.resume);
        }

        // Add form data to Firestore
        await addDoc(collection(db, 'committeeApplications'), {
            firstName: formData.firstName,
            lastName: formData.lastName,
            bio: formData.bio,
            phone: formData.phone,
            email: formData.email,
            resumeUrl: `committeeResumes/${formData.resume.name}`, // Save the resume path
        });

        // Reset form after submission
        setFormData({
            firstName: '',
            lastName: '',
            bio: '',
            phone: '',
            email: '',
            resume: null,
        });
        handleClose(); // Close the popup
    };

    const cardData = [
        {
          name: "LEVAR JACKSON",
          description: "Bunchful Attaché to the Nominating Committee for The Bunchful Awards.",
          detailedDescription: "Levar Jackson is a Brooklyn native and tenured hospitality industry expert with a specialty in sophisticated hotel brands, producing over 50 industry events over the years.",
          image: lV 
        },
        {
          name: "TAGHRID EL SAYED MOHAMED",
          description: "Taghrid is the Deputy Chief of Party of the American University in",
          detailedDescription: "Taghrid is the Deputy Chief of Party of the American University in Cairo, and a certified life coach with over than 16 years of experience in project and operations management across a variety of industries in Egypt and the MENA region. She started her career in the field of international relations by managing cooperation between Asian countries and Egypt on economic and social development projects. She excelled in coordinating multilateral relationships between international organizations and donors to facilitate grants and loans for Egypt, as well as exchanging expertise in technical fields. Her work experience also includes managing complex projects, customer experience, and operations, as she led the the customer care team in Majid Al Futtaim shopping malls in Egypt and Beirut with a total of 40 agents. Her political and economic background enabled her to manage different CSR initiatives that had positive socioeconomic and environmental impact on each targeted community. She is an expert in coordinating protocols between government entities, non-government and privates sector, as well as leading projects to support communities in different disciplines. Taghrid is currently managing the establishment of 10 sustainable university centers for career development within eight public universities in different Egyptian Governorates. She Initiates partnerships with the private sector, NGOs and other entities to establish strong sustainable relationships with Universities Career centers to provide internships, job opportunities, job shadowing for students and graduates, and minimize the gap between the graduates' skills and the market needs.",
          image: tM
        },
        {
          name: "DAVID VALERA REYNOSO",
          description: "High-level strategic advisor to some of America's top CEO's",
          detailedDescription: "David Valera's dedication to international politics is demonstrated throughout his diverse background. Currently, he is the President and CEO of GCP, a global consulting firm, and 4KZ a management consulting firm, in which David represents various multibillion-dollar service sector firms as they develop key partnerships and joint ventures to expand their global business enterprise into new emerging world markets. David works closely with his clients to negotiate and cultivate multi-year service contracts and outsourcing opportunities. One of the David's goals is to educate the world about Kazakhstan. This has acquired him global recognition. He is a respected business advocate and strategic consultant within his peer group and has used his unique platform to collaborate with various leaders, fortune 500 CEOs and top lobbyists.",
          image: dR
        },
        {
          name: "KELLY MEERBOT",
          description: "Kelly is a respected thought leader, author, facilitator, speaker,",
          detailedDescription: "Kelly Meerbott is a renowned successful leader, transformational business coach, leadership and team developer, speaker, and facilitator. She has been transforming America's top CEOs', personal and professional lives and hand-crafted profitable business strategies for thousands of corporations worldwide. With her holistic approach and background in sales, marketing, and business-building insights, Kelly's coaching and training sessions are the secret weapons to every seeker of fulfillment and success. Rather than simply looking at one aspect of you or your company, Kelly combines every facet of your life and business to create a wholly fulfilling experience for you.",
          image: kM
        },
        {
          name: "BROOKE SAUCIER",
          description: "Brooke is a serial networker with an innate sense of making",
          detailedDescription: "Brooke is a serial networker with an innate sense of making strategically successful introductions, constantly striving to connect people, organizations, and ideas. Since founding Knektar LLC, it has become Brooke's goal to create avenues for professionals to meet one another for mutually beneficial purposes. A true Curator of Opportunities, Brooke enjoys facilitating various connections–related to business development, strategic partnership or investment opportunity–for colleagues, clients, and friends.",
          image: bS
        },
        {
          name: "BILL CAMPBELL",
          description: "Bill is a non-profits professional that has a long history in building",
          detailedDescription: "Bill is a non-profits professional that has a long history in building corporate partnerships at some of the top causes in the United States including The American Red Cross and Habitat for Humanity. He has taken his knowledge and applied in the tech space by launching a startup focused on matching gifts called Amply. He has recently joined the team at Fluxx Software to help nonprofits and foundations better measure their impact when investing in community programs through grantmaking. He lives in Atlanta with his wife and 3 children and is always on the hunt for a cup of coffee with good company.",
          image: bC
        },
        {
          name: "EVELYN HAUGHTON",
          description: "Evelyn has served as a Teacher, Registered Nurse and Family Nurse Practitioner",
          detailedDescription: "Evelyn has served as a Teacher, Registered Nurse and Family Nurse Practitioner for over forty years. She recently retired from Montefiore Health Care System as a Patient Service Manager. Her career started in Jamaica, W.I., as a teacher in elementary and high school, teaching English, History and Social Studies before migrating to the USA, and entering the medical profession, working as a Public Health Nurse in New York City's Child Health Program and catering extensively to the underserved population. Evelyn provided skilled nursing care and health maintenance and research coordination in other areas of the health system, such as Family Health Centers, Visiting Nurse Service, Nursing Homes, HIV pediatric research, Foster Care, etc. She pursued advanced nursing education as a Family Nurse Practitioner and continued her role as a primary care provider and health educator. As a retired professional, Evelyn is looking forward to utilize her knowledge and experience to nurture and support nurses and other individuals to be change agents and beacons of hope for health and wellness promotion. She is guided by this quote: “To thine own self be true, and it follows as the night follows the day that thou cannot be false to any man.”",
          image: eH
        }
      ];

    return (
        <div className="nC">

            <div>
                <Helmet>
                    <title>Nominating Committee - Bunchful | The Generosity Business | Official Site</title>
                </Helmet>
            </div>

            <div>
                <Header/>
            </div>

            <div className="banner">
                <Banner
                    homeLink="Home"
                    breadcrumb="Nominating Committee"
                    title="Nominating Committee"
                    backgroundImage={bannerImage}
                />
            </div>

            <div className="section-1">

                <div
                    style={{
                        backgroundColor: "#eeedfa", // Static background color
                        padding: '20px', // Optional: Add padding if needed
                        borderRadius: '20px', // Optional: Add border radius if needed
                        margin: '0 auto', // Center the banner horizontally
                        marginLeft: "5%",
                        marginRight: "5%",
                        marginTop: "5%",
                        marginBottom: "5%"
                    }}
                >
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={7}>
                                <p className="text" style={{color: "black", fontSize: "2rem", paddingTop: "8%"}}>
                                    We ensure that members of the Bunchful Awards Nominating Committee are diverse and inclusive, representing cultures from around the globe.
                                </p>  
                            </Col>

                            <Col xs={12} sm={12} md={5}>
                                <div style={{ overflow: "hidden" }}>
                                    <img
                                        src={imgSrc}
                                        alt='People writing things'
                                        className="banner-image"
                                        style={{ width: "100%", height: "auto", borderRadius: "5px" }} // Ensure it fits nicely
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>

            <div className="section-2">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="heading">
                                Nominating Committee
                            </h1>
                            <p className="text">
                                The Nominating Committee is an independent body comprised of a diverse group of individuals from a range of backgrounds and cultures. The committee is responsible for creating the criteria by which Awardees are chosen, all except for the ZHAG Award, which is given at Bunchful's sole discretion and was named after a Bunchful Ambassador and humanitarian, Zayeed Hakim.
                                <br/> <br/>
                                To help ensure a fair and equitable approach to determining awardees, we are accepting applications for consideration for the Nominating Committee on a rolling basis. 
                            </p>
                            <Button className= "btn" variant="primary" onClick={handleShow}>Submit a Letter of Interest Here</Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-3">
                <Container>
                    <div className="heading">Meet Our Nominating Committee</div>
                    <Row>
                        
                        {cardData.map((card, index) => (
                        <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                            
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={card.image} alt={card.name} />
                                <Card.Body  className="text-center" >
                                    <Card.Title>{card.name}</Card.Title>
                                    <Card.Text>
                                    <strong>{card.title}</strong>
                                    <br />
                                    <em>{card.location}</em>
                                    <br />
                                    {card.description}
                                    </Card.Text>
                                    <Button className="btn" variant="primary" onClick={() => handleShowModal(card)}>
                                        More Info
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            
            <div className='banner-2-section'>
                <Container>
                    <Row className="row-1 position-relative">
                    <Col xs={12} md={6}>
                        <p className='heading'>
                            Become a Committee Member
                        </p>
                        <div className='button-container'>
                        <Button onClick={handleShow} className='apply-button' >
                            Join Committee
                        </Button>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="position-relative">
                        <img src={exampleImage} alt="Overlapping" className="overlap-image" />
                    </Col>
                    </Row>
                </Container>
            </div>
            

            <div>
                <Footer/>
            </div>

            {selectedCard && (
                <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedCard.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedCard.location && <p><strong>Location:</strong> {selectedCard.location}</p>}
                    <p>{selectedCard.detailedDescription || selectedCard.description}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button style= {{backgroundColor: "#5050f3", width: "auto"}}  variant="primary" onClick={handleCloseModal}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            )}


            <Modal show={showPopup} onHide={handleClose} dialogClassName="popup-modal">
                <Modal.Header style={{backgroundColor: "#f2e9fd", color: "black", textAlign: "center"}} closeButton>
                <Modal.Title style={{textAlign: "center"}}>Become a Committee Member</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: "#f2e9fd"}}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formFirstName">
                                <Form.Label>Enter your first name</Form.Label>
                                <Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Your first name" required />
                            </Form.Group>
                            <Form.Group controlId="formLastName">
                                <Form.Label>Enter your last name</Form.Label>
                                <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Your last name" required />
                            </Form.Group>
                            <Form.Group controlId="formYourBio">
                                <Form.Label>Write your Bio</Form.Label>
                                <Form.Control type="text" name="bio" value={formData.bio} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formPhoneNumber">
                                <Form.Label>Give Your Phone Number</Form.Label>
                                <Form.Control type="text" name="phone" value={formData.phone} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Give your email" required />
                            </Form.Group>
                            <Form.Group controlId="formResume">
                                <Form.Label>Upload Your Resume</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange} required />
                            </Form.Group>
                            <Button variant="primary" type="submit">Submit</Button>
                        </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default NominatingCommittee;